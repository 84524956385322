import React from "react";

interface Item {
  title?: string;
  contents: Array<{
    label: string;
    value?: string | number;
    formatter?: (val: string | number) => string | number;
  }>;
}

interface DatafieldContentProps {
  items: Item[];
}

export default function DatafieldContent({ items }: DatafieldContentProps) {
  function formatValue(
    value?: string | number,
    formatter?: (val: string | number) => string | number
  ) {
    if (!value) return "--";
    if (!formatter) return value;
    return formatter(value);
  }
  return (
    <div className={`user-details ${true ? "grid-cols-2" : ""}`}>
      {items.map((item, index) => (
        <div
          key={index}
          className={`user-detail-label ${
            index < items.length - 1 ? "user-detail-right-border" : ""
          }`}
        >
          {item.title && <h4 className="user-detail-title">{item.title}</h4>}
          <div>
            {item.contents.map((detail, index) => (
              <p
                className={index === item.contents.length - 1 ? "mb-0" : "mb-2"}
                key={index}
              >
                <span className="user-detail-name">{detail.label}</span>
                <span className="user-detail-value">
                  {formatValue(detail.value, detail.formatter)}
                </span>
              </p>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}
