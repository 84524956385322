import React from "react";
import DataTable from "react-data-table-component";
import {
  downloadExcelFromBase64,
  formatDate,
  formatDateFromNumber,
  getErrorMsgFromResponse,
} from "../../../utils";
import {NotifierBgColor, PayrollDetails, RoutePaths} from "../../../models";
import dispatch from "../../../middleware";
import {useDispatch} from "react-redux";
import {
  downloadPayroll,
  downloadProcessedPayroll,
  freezePayroll,
  listPayrolls,
} from "../../../actions/payroll";
import {showNotifier} from "../../../actions/ui";
import Authorized from "../../../authorization/authorized";
import {AccessType, Resource} from "../../../authorization/authorization.enum";
import { logEventInFirebase } from "../../../analytics/firebase.analytics";
import AnalyticsEvent from "../../../analytics/events";
import { Link } from "react-router-dom";

function renderPrevPayrollUploads(
  payrolls: PayrollDetails[],
  handleClickButton: (
    documentId: string,
    employerAdminId: string,
    startDate: string,
    endDate: string,
    type: string
  ) => void
) {
  const columns = [
    {
      minWidth: "16%",
      name: "Date (when uploaded)",
      selector: "uploadedAt",
      sortable: true,
    },
    {
      minWidth: "16%",
      name: "Upload By",
      selector: "uploadedBy",
      sortable: true,
    },
    {
      minWidth: "16%",
      name: "Payroll Start Date",
      selector: "startDate",
      sortable: false,
    },
    {
      minWidth: "16%",
      name: "Payroll End Date",
      selector: "endDate",
      sortable: false,
    },
    {
      minWidth: "16%",
      name: "Download",
      cell: (row: any) => (
        <button
          type="button"
          className="btn btn-link p-0"
          onClick={() =>
            handleClickButton(
              row.processedPayrollId,
              row.employerAdminId,
              row.startDate,
              row.endDate,
              "downloadProcessedPayroll"
            )
          }
        >
          <i className="fa fa-cloud-download" />
        </button>
      ),
      ignoreRowClick: true,
      button: true,
    },
  ];
  const tableData = payrolls.map((payroll, index) => {
    return {
      uploadedAt: formatDate(new Date(payroll.uploadedAt)),
      startDate: formatDateFromNumber(payroll.startDate),
      endDate: formatDateFromNumber(payroll.endDate),
      originalPayrollId: payroll.originalPayrollId,
      processedPayrollId: payroll.processedPayrollId,
      freezedDate: payroll.freezedDate,
      uploadedBy: payroll.uploadedBy,
      employerAdminId: payroll.employerAdminId,
    };
  });
  return (
    <div>
      <DataTable
        className="table-wrapper"
        columns={columns}
        data={tableData}
        pagination
        paginationPerPage={5}
        subHeaderAlign={"left"}
        title={
          <div className="d-flex  align-items-center justify-content-between">
            <p className="table-title fw-600 fs-18 m-0">
              Payroll Upload History
            </p>
          </div>
        }
      />
    </div>
  );
}

interface OldPayrollProps {
  payrolls: PayrollDetails[]
}

export default function OldPayroll({ payrolls }: OldPayrollProps) {
  const storeDispatch = useDispatch();

  payrolls.sort((a, b) => (a.uploadedAt < b.uploadedAt ? 1 : -1));

  async function handleClickButton(
    documentId: string,
    employerAdminId: string,
    startDate: string,
    endDate: string,
    type: string
  ) {
    try {
      if (type === "downloadPayroll") {
        const response = await dispatch(
          storeDispatch,
          downloadPayroll(documentId, employerAdminId, startDate, endDate)
        );
        downloadExcelFromBase64(response.b64File, documentId);
      } else if (type === "freezePayroll") {
        await dispatch(
          storeDispatch,
          freezePayroll(documentId, startDate, endDate)
        );
        await dispatch(
          storeDispatch,
          showNotifier(NotifierBgColor.SUCCESS, "Payroll Freezed!")
        );
        await dispatch(storeDispatch, listPayrolls());
      } else if (type === "downloadProcessedPayroll") {
        const response = await dispatch(
          storeDispatch,
          downloadProcessedPayroll(documentId, employerAdminId)
        );
        downloadExcelFromBase64(response.b64File, `${documentId} - processed`);
      }
    } catch (e) {
      await dispatch(
        storeDispatch,
        showNotifier(NotifierBgColor.ERROR, getErrorMsgFromResponse(e))
      );
    }
  }
  function renderNotAccessible() {
    return (
        <div className={"d-flex justify-content-center align-items-center"}>
          You aren't authorized to access this page
        </div>
    )
  }

  return (
    <div>
      <Authorized resourceName={Resource.EMPLOYER_PORTAL_PAYROLL} requiredAccessType={AccessType.WRITE}>
        <div className="d-flex justify-content-end">
          <div className="d-flex mt-3 mt-md-0">
            <Link
              to={`${RoutePaths.DOCUMENT_UPLOAD}?document=PAYROLL`}
              className="btn outline-btn b-color-primary  w-unset fs-12 b-color-secondary secondary-color mb-3"
              onClick={() => {
                logEventInFirebase(AnalyticsEvent.UPLOAD_PAYROLL_CLICKED);
              }}
            >
              Upload
            </Link>
          </div>
        </div>
      </Authorized>
      {renderPrevPayrollUploads(payrolls, handleClickButton)}
    </div>
  );
}
