import React from "react";
import { CurrencyInrIcon } from "../library/icon";
import { NumberInput } from "../library/number-input";
import { useForm } from "../../hooks/use-form";
import DatafieldContent from "./datafieldContent";
import { MAX_MONTHLY_SALARY } from "../../constants";

// Ref: https://stackoverflow.com/a/7295864 & https://stackoverflow.com/a/31203399
function handleKeyDownToAllowOnlyNumericKeys(
  e: React.KeyboardEvent<HTMLInputElement>
) {
  const keyCode = e.which ?? e.keyCode;
  if (keyCode > 31 && (keyCode < 48 || keyCode > 57)) {
    e.preventDefault();
    e.stopPropagation();
    return;
  }
}

interface EditFormAndDataFieldsProps {
  formId: string;
  dataFields: Array<{
    label: string;
    key: string;
    value: string | number;
    formatter?: (val: string | number) => string | number;
  }>;
  isEditable: boolean;
  isEditButtonClicked: boolean;
  onFormSubmit: (body: Record<string, string | number>) => void;
}

export default function EditFormAndDataFields({
  formId,
  dataFields,
  isEditable,
  isEditButtonClicked,
  onFormSubmit,
}: EditFormAndDataFieldsProps) {
  const initialValues = dataFields.reduce((accum, current) => {
    accum[current.key] = current.value;
    return accum;
  }, {} as Record<string, string | number>);

  const form = useForm({
    initialValues,
    validationRules: {
      currentMonthlySalary: (val) => {
        if (!val) return `Monthly Salary is required`;
        if (!Number.isInteger(val)) return 'Monthly Salary cannot have decimals';
        if (val <= 0)
          return `Monthly Salary cannot be negative or equal to zero`;
        if(val > MAX_MONTHLY_SALARY) return `Monthly Salary cannot be greater than ${MAX_MONTHLY_SALARY}`;
      },
    },
    validateInputOn: "blur",
  });

  return (
    <>
      {isEditable && (
        <form
          id={formId}
          style={{
            display: "flex",
            alignItems: "flex-start",
            gap: "1rem",
            marginBottom: "1rem",
          }}
          noValidate
          onSubmit={form.onSubmit(onFormSubmit)}
        >
          {dataFields.map((field) => (
            <div style={{ flexBasis: dataFields.length > 1 ? "50%" : "100%" }} key={field.key}>
              <NumberInput
                label={field.label}
                required
                disabled={!isEditButtonClicked}
                leftSection={
                  field.label.toLowerCase().includes("salary") && (
                    <CurrencyInrIcon size="md" />
                  )
                }
                {...form.getInputProps(field.key)}
                hasClearButton
                onClear={() => form.setFieldValue(field.key, "")}
                // INFO: On typing "656+" in <input number> elem on change returns Empty string which clears the input
                //       it's a bad UX, thus for now disabling typing anything other than numbers
                onKeyDown={handleKeyDownToAllowOnlyNumericKeys}
              />
            </div>
          ))}
        </form>
      )}
      <DatafieldContent
        items={[
          {
            title: "Submitted Detail",
            contents: dataFields,
          },
          ...(isEditable
            ? [
                {
                  title: "Updated Details",
                  contents: dataFields.map((field) => ({
                    ...field,
                    value: isEditButtonClicked ? form.values[field.key] : "",
                  })),
                },
              ]
            : []),
        ]}
      />
    </>
  );
}
