export enum Actions {
    SET_SCREEN_NAME = "SET_SCREEN_NAME",
    SHOW_NOTIFIER = "SHOW_NOTIFIER",
    HIDE_NOTIFIER = "HIDE_NOTIFIER",
}
export function setScreenName(screenName: string) {
    return {actionName: Actions.SET_SCREEN_NAME, body: {screenName}};
}
// TODO: Refactor the arguments as object only, Have backward compatibility issue 
export function showNotifier(
    bgColor: string,
    msg: string,
    type: string = "",
    config?: {
        autoClose?: boolean;
        title?: string;
        actionButtonLabel?: string;
        onActionButtonClick?: () => void;
    }
) {
    return { actionName: Actions.SHOW_NOTIFIER, body: { bgColor, msg, type, ...config } };
}
export function hideNotifier() {
    return {actionName: Actions.HIDE_NOTIFIER};
}
