import React, { Ref, forwardRef, ReactNode } from "react";
import { constructClassName } from "../helpers/construct-classname";
import { CircleNotchAnimateIcon } from "../icon";
import { UnstyledButton, UnstyledButtonProps } from "../unstyled-button";
import "./styles.css";

type IconButtonVariant = "primary" | "light";
type IconButtonSize = "sm" | "md" | "lg";
type IconButtonColor = "brand" | "danger" | "success";
type IconButtonSpacing = "regular" | "compact" | "no-padding";

export interface IconButtonProps extends UnstyledButtonProps {
  title: string;

  loading?: boolean;

  color?: IconButtonColor;

  variant?: IconButtonVariant;

  size?: IconButtonSize;

  spacing?: IconButtonSpacing;

  children: ReactNode;
}

function _IconButton(
  {
    loading = false,
    color,
    size = "md",
    variant = "primary",
    spacing = "regular",
    children,
    ...rest
  }: IconButtonProps,
  ref: Ref<HTMLButtonElement>
) {
  return (
    <UnstyledButton
      ref={ref}
      className={constructClassName("rf-icon-button-root", {
        [`size-${size}`]: true,
        [`variant-${variant}`]: true,
        [`color-${color}`]: !!color,
        loading: loading,
      })}
      {...rest}
    >
      <div className={constructClassName("rf-icon-button__inner", {
        [`spacing-${spacing}`]: true,
      })}>
        {loading ? <CircleNotchAnimateIcon size={size} /> : children}
      </div>
    </UnstyledButton>
  );
}

export type { IconButtonSize, IconButtonColor }

export const IconButton =
  forwardRef<HTMLButtonElement, IconButtonProps>(_IconButton);
