import React from "react";
import { Button } from "../library/button";
import { CheckCircleIcon, XCircleIcon, WarningIcon } from "../library/icon";

const typeToIconMap = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  failure: XCircleIcon,
};
const typeToColorMap = {
  success: "var(--clr-info-success-default)",
  warning: "var(--clr-info-alert-default)",
  failure: "var(--clr-info-error-default)",
};

interface TerminalStateContentProps {
  type: "success" | "failure" | "warning";
  message: string;
  onCtaClick: () => void;
}

export default function TerminalStateContent({
  type,
  message,
  onCtaClick,
}: TerminalStateContentProps) {
  const Icon = typeToIconMap[type];
  return (
    <div className="d-flex align-items-center justify-content-center flex-column">
      <Icon variant="fill" color={typeToColorMap[type]} size="el" />
      <h4 className="fs-16 fw-700 primary-color my-4">{message}</h4>
      <Button
        variant="primary"
        color="brand"
        spacing="full-width"
        onClick={onCtaClick}
        autoFocus
      >
        {type == "failure" ? "Ok" : "Done"}
      </Button>
    </div>
  );
}
