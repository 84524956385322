import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as _ from "lodash";
import {
  convertToSentenceCase,
  formatDate,
  getErrorMsgFromResponse,
  getParamFromUrl,
  maskString,
  navigateTo,
} from "../../../utils";
import dispatch, { getImage } from "../../../middleware";
import {
  getAgreementPresignedUrl,
  getAnEmployeeTransactions,
  getEmployeeDetails,
  getUserAgreementMetaDate,
  removeEmployee,
} from "../../../actions/employee";
import { NotifierBgColor, RoutePaths, Transaction } from "../../../models";
import FullScreenLoader from "../../../components/fullscreen-loader";
import DataTable, { IDataTableColumn } from "react-data-table-component";
import UserPlaceholder from "../../../images/profile-user.svg";
import { showNotifier } from "../../../actions/ui";
import ImageNotFound from "../../../images/img-not-found.png";
import OkycVerifiedImage from "../../../images/okyc-verified.png";
import {
  AgreementType,
  BankAccount,
  EmployeeProfileDetailsDto,
  UserAgreementMetaDataDto,
  UserPropertyPhoneNumber,
} from "../../../dto";
import DocumentPreview from "../../../components/document-preview";
import Authorized from "../../../authorization/authorized";
import {
  AccessType,
  Resource,
} from "../../../authorization/authorization.enum";
import Modal from "../../../components/modal";
import AnalyticsEvent from "../../../analytics/events";
import { logEventInFirebase } from "../../../analytics/firebase.analytics";
import { Button } from "../../../components/library/button";

interface AgreementColumnType extends AgreementType {
  onButtonClick: () => void;
}

const WORKER_TYPE_TO_FETCH_AGREEMENT = "EMPLOYEE";

const TransactionColumn = [
  {
    minWidth: "25%",
    name: "Date",
    selector: "date",
    cell: (row: any) => <span className="primary-color fs-12">{row.date}</span>,
  },
  {
    minWidth: "25%",
    name: "Time",
    selector: "time",
    cell: (row: any) => <span className="primary-color fs-12">{row.time}</span>,
  },
  {
    minWidth: "25%",
    name: "Amount Requested",
    selector: "amount",
    cell: (row: any) => (
      <span className="primary-color fs-12">{row.amount}</span>
    ),
  },
  {
    minWidth: "25%",
    name: "Status",
    selector: "status",
    cell: (row: any) => (
      <span className="primary-color fs-12">{row.status}</span>
    ),
  },
];

const AgreementColumn: IDataTableColumn<AgreementColumnType>[] = [
  {
    minWidth: "25%",
    name: "Agreement Type",
    selector: "agreementType",
    cell: (row) => (
      <span className="primary-color fs-12">{row.agreementType}</span>
    ),
  },
  {
    minWidth: "25%",
    name: "Signed At",
    selector: "signedAt",
    cell: (row) => <span className="primary-color fs-12">{row.signedAt}</span>,
  },
  {
    minWidth: "25%",
    name: "",
    cell: (row) => (
      <Button size="sm" onClick={row.onButtonClick}>
        View
      </Button>
    ),
  },
];

function formatTransactionData(transactions: Transaction[]) {
  const formattedData = transactions.map((transaction: Transaction) => {
    return {
      date: new Date(transaction.createdAt).toLocaleDateString("en-GB", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      }),
      time: new Date(transaction.createdAt).toLocaleTimeString(),
      amount: transaction.amount,
      status: transaction.status,
    };
  });
  return formattedData;
}

function renderUserData(
  label: string,
  val: string | number | null | undefined
) {
  if (!(val && String(val).trim() !== "")) val = "N/A";
  return (
    <div className="d-flex mb-3">
      <p className="fs-14 fw-300  primary-color m-0 w-50">{label}</p>
      <p className="fs-14 fw-600  primary-color m-0 w-50 break-word">{val}</p>
    </div>
  );
}

function formatPhoneNumber(phone: UserPropertyPhoneNumber) {
  if (!phone?.data?.number) return;
  const { countryCode, number } = phone.data;
  return countryCode + number;
}

function getPrimaryBankInfo(bankInfo?: BankAccount[] | null) {
  if (!Array.isArray(bankInfo)) return;
  const filteredBankInfo = bankInfo.filter(
    (eachBankInfo) => eachBankInfo.data.primary
  );
  if (filteredBankInfo.length < 1) return;
  return filteredBankInfo[0];
}

function maskStringIfAvailable(data?: string | null): string | undefined {
  if (!data) return;
  return maskString({
    inputString: data,
    numCharactersToShowStart: 0,
    numCharactersToShowEnd: 4,
  });
}

export default function EmployeeProfile() {
  const storeDispatch = useDispatch();
  const vendorId = getParamFromUrl("vendorId");
  const [transactions, updateTransactionList] = useState<Transaction[] | null>(
    null
  );
  const [employeeDetails, updateEmployeeDetails] =
    useState<EmployeeProfileDetailsDto | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isTerminateModalActive, updateTerminateModal] =
    useState<boolean>(false);

  const [selfieImage, updateSelfieImage] = useState<any>(null);
  const [aadharImage, updateAadharImage] = useState<string | null>(null);
  const [hrLetterImage, updateHrLetterImage] = useState<string | null>(null);
  const [rentalAgreementImage, updateRentalAgreementImage] = useState<
    string | null
  >(null);
  const [userAgreementMetaData, setUserAgreementMetaData] =
    useState<UserAgreementMetaDataDto | null>(null);
  const userDocumentsList = [
    {
      path: "selfie",
      documentType: "SELFIE",
      action: updateSelfieImage,
      status: "selfie.reviewMetadata.status",
      data: selfieImage,
      className: "col-6",
    },
    {
      documentType: "AADHAR",
      path: "address.reviewMetadata.verifiedBy",
      action: updateAadharImage,
      status: "address.reviewMetadata.status",
      data: aadharImage,
      className: "col-6",
    },
    {
      path: "hrLetter.document",
      documentType: "HR_LETTER",
      action: updateHrLetterImage,
      status: "hrLetter.reviewMetadata.status",
      data: hrLetterImage,
      className: "col-6",
    },
    {
      path: "rentalAgreement.document",
      documentType: "RENTAL_AGREEMENT",
      action: updateRentalAgreementImage,
      status: "rentalAgreement.reviewMetadata.status",
      data: rentalAgreementImage,
      className: "col-6",
    },
  ];
  async function terminateEmployee(employeeId: string | null) {
    updateTerminateModal(false);
    setLoading(true);
    if (employeeId) {
      try {
        await dispatch(storeDispatch, removeEmployee(employeeId));
        dispatch(
          storeDispatch,
          showNotifier(
            NotifierBgColor.SUCCESS,
            `Removed EmployeeID: ${employeeId}`
          )
        );
      } catch (e) {
        dispatch(
          storeDispatch,
          showNotifier(NotifierBgColor.ERROR, getErrorMsgFromResponse(e))
        );
      }
    }
    setLoading(false);
  }
  async function getDocImage(doc: any, employeeId: string) {
    const action = doc.action;
    try {
      const url = `/employer-admin/user-document?employeeId=${employeeId}&docType=${doc.documentType}`;
      const response = await getImage(url);
      if (response?.data) {
        let reader = new FileReader();
        reader.readAsDataURL(response.data);
        reader.onload = function () {
          action(reader.result);
        };
      } else {
        action(ImageNotFound);
      }
    } catch (e) {
      action(ImageNotFound);
    }
  }
  async function getPageDetails(empId: string) {
    try {
      setLoading(true);
      if (empId.trim() !== "") {
        const t = await dispatch(
          storeDispatch,
          getAnEmployeeTransactions(empId)
        );
        updateTransactionList(t.transactions);
        const employee = await dispatch(
          storeDispatch,
          getEmployeeDetails(empId)
        );
        updateEmployeeDetails(employee);
        if (employee && employee._id) {
          userDocumentsList.forEach(function (document) {
            const action = document.action;
            if (_.get(employee, document.path) === "UIDAI")
              action(OkycVerifiedImage);
            else if (
              document.documentType !== "AADHAR" &&
              _.get(employee, document.path)
            )
              getDocImage(document, empId);
          });
        }
      } else {
        navigateTo(RoutePaths.DASHBOARD);
      }
    } catch (e) {}
    setLoading(false);
  }

  async function fetchUserAgreementMetaData(empId: string) {
    try {
      const response = await dispatch(
        storeDispatch,
        getUserAgreementMetaDate(empId, WORKER_TYPE_TO_FETCH_AGREEMENT)
      );
      if (!response) return null;
      setUserAgreementMetaData(response);
    } catch (e) {
      await dispatch(
        storeDispatch,
        showNotifier(NotifierBgColor.ERROR, getErrorMsgFromResponse(e))
      );
    }
  }

  async function fetchAgreementPresignedUrl(agreementId: string) {
    setLoading(true);
    try {
      const { link } = await dispatch(
        storeDispatch,
        getAgreementPresignedUrl(agreementId)
      );

      if (!link) throw new Error("No result found for this agreement.");
      window.open(link, "_blank");
    } catch (e) {
      await dispatch(
        storeDispatch,
        showNotifier(
          NotifierBgColor.ERROR,
          getErrorMsgFromResponse(e, "No result found for this agreement.")
        )
      );
    } finally {
      setLoading(false);
    }
  }

  function formatUserAgreementData(
    userAgreementMetaData: UserAgreementMetaDataDto | null
  ) {
    if (!userAgreementMetaData?.agreements) return [];
    const formattedData = userAgreementMetaData.agreements.map((agreement) => {
      return {
        agreementId: agreement.agreementId,
        agreementType: convertToSentenceCase(agreement.agreementType),
        signedAt: agreement.signedAt,
        onButtonClick: handleAgreementDownloadClick(agreement.agreementId),
      };
    });
    return formattedData;
  }

  const handleAgreementDownloadClick = useCallback((agreementId: string) => {
    return () => fetchAgreementPresignedUrl(agreementId);
  }, []);

  useEffect(() => {
    const empId = getParamFromUrl("empId");
    if (!empId) return;
    getPageDetails(empId);
    fetchUserAgreementMetaData(empId);
  }, []);

  function getJoiningDate(dateStr?: string | null) {
    if (!dateStr) return;
    return formatDate(new Date(dateStr));
  }

  return (
    <React.Fragment>
      <FullScreenLoader active={isLoading} />
      <div className="d-flex justify-content-end mb-3">
        <Authorized
          resourceName={
            vendorId
              ? Resource.EMPLOYER_PORTAL_VENDORS_EMPLOYEES
              : Resource.EMPLOYER_PORTAL_EMPLOYEES
          }
          requiredAccessType={AccessType.WRITE}
        >
          <button
            className="btn outline-btn w-unset b-color-red color-red fs-12"
            onClick={() => {
              updateTerminateModal(true);
              logEventInFirebase(
                AnalyticsEvent.TERMINATE_SINGLE_EMPLOYEE_OPTION_CLICKED,
                { vendorId }
              );
            }}
          >
            Terminate
          </button>
        </Authorized>
      </div>
      <Authorized
        resourceName={
          vendorId
            ? Resource.EMPLOYER_PORTAL_VENDORS_EMPLOYEES
            : Resource.EMPLOYER_PORTAL_EMPLOYEES
        }
        requiredAccessType={AccessType.READ}
      >
        <>
          {employeeDetails && (
            <div className="table-wrapper mb-3">
              <div className="d-flex">
                <span className="circle-avatar mr-3">
                  <img
                    src={UserPlaceholder}
                    alt={employeeDetails?.name?.data}
                  />
                </span>
                <div>
                  <p className="fw-700 primary-color m-0 mt-3">
                    {employeeDetails?.name?.data}
                  </p>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-md-4">
                  <p className="light-gray fw-600">Personal Details</p>
                  {renderUserData(
                    "Employee ID :",
                    getParamFromUrl("employerEmployeeId")
                  )}
                  {renderUserData(
                    "Joining Date :",
                    getJoiningDate(getParamFromUrl("joiningDate"))
                  )}
                  {renderUserData(
                    "Mobile number :",
                    formatPhoneNumber(employeeDetails?.phone)
                  )}
                  {renderUserData(
                    "Email Address :",
                    employeeDetails?.email?.data
                  )}
                  {renderUserData("Gender :", employeeDetails?.gender)}
                  {renderUserData(
                    "PAN :",
                    maskStringIfAvailable(employeeDetails?.pan?.data?.pan)
                  )}
                  {renderUserData(
                    "Address :",
                    employeeDetails?.address?.data
                      ? Object.values(employeeDetails?.address?.data).toString()
                      : null
                  )}
                </div>
                <div className="col-md-4">
                  <p className="light-gray fw-600">Account Details</p>
                  {renderUserData(
                    "Account Number :",
                    maskStringIfAvailable(
                      getPrimaryBankInfo(employeeDetails.bankAccountInfo)?.data
                        .accountNumber
                    )
                  )}
                  {renderUserData(
                    "IFSC Code :",
                    getPrimaryBankInfo(employeeDetails.bankAccountInfo)?.data
                      .ifsc
                  )}
                </div>
                <div className="col-md-4">
                  <div className="row text-center">
                    {userDocumentsList.map((document) => {
                      return document.data ? (
                        <DocumentPreview
                          key={document.documentType}
                          className={document.className}
                          dataSrc={document.data}
                          docName={document.documentType}
                          verificationStatus={_.get(
                            employeeDetails,
                            document.status,
                            "NA"
                          )}
                        />
                      ) : undefined;
                    })}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      </Authorized>

      <Authorized
        resourceName={
          vendorId
            ? Resource.EMPLOYER_PORTAL_VENDORS_TRANSACTIONS
            : Resource.EMPLOYER_PORTAL_TRANSACTIONS
        }
        requiredAccessType={AccessType.READ}
      >
        <div className="row mb-3">
          <div className="col-sm-12">
            <DataTable
              title={
                <div className="d-flex  align-items-center justify-content-between">
                  <p className="table-title fw-600 fs-18 m-0">Agreement</p>
                </div>
              }
              columns={AgreementColumn}
              data={formatUserAgreementData(userAgreementMetaData)}
              className="table-wrapper"
            />
          </div>
        </div>
      </Authorized>
      <Authorized
        resourceName={
          vendorId
            ? Resource.EMPLOYER_PORTAL_VENDORS_TRANSACTIONS
            : Resource.EMPLOYER_PORTAL_TRANSACTIONS
        }
        requiredAccessType={AccessType.READ}
      >
        <div className="row">
          <div className="col-sm-12">
            <DataTable
              title={
                <div className="d-flex  align-items-center justify-content-between">
                  <p className="table-title fw-600 fs-18 m-0">Transactions</p>
                </div>
              }
              columns={TransactionColumn}
              data={formatTransactionData(transactions || [])}
              className="table-wrapper"
              highlightOnHover
            />
          </div>
        </div>
      </Authorized>
      <Modal active={isTerminateModalActive} cancellable={false}>
        <div className="modalWrapper">
          <button
            className="btn abs-top-right-btn light-gray"
            onClick={() => updateTerminateModal(false)}
          >
            x
          </button>
          <p className="primary-color fs-16 fw-700">Terminate Employee</p>
          <p className="fs-14 primary-color mb-4">
            Do you want to mark this employee as terminated?
          </p>
          <div className="d-flex">
            <button
              className="btn primary-button w-unset px-5 fs-14 mr-3 b-color-secondary"
              onClick={() =>
                terminateEmployee(getParamFromUrl("employerEmployeeId"))
              }
            >
              Yes
            </button>
            <button
              className="btn outline-btn fs-14 b-color-secondary secondary-color px-5"
              onClick={() => updateTerminateModal(false)}
            >
              No
            </button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
}
