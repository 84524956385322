import React, { useState } from "react";
import { Button } from "../library/button";
import Modal from "../modal";
import { useId } from "../../hooks/use-id";
import EditFormAndDataFields from "./editForm";
import TerminalStateContent from "./terminalStateContent";
import "./styles.css";

interface PromptModalProps {
  active: boolean;
  type: "approve" | "reject";
  title: string;
  isEditable?: boolean;
  dataFields: Array<{
    label: string;
    key: string;
    value: string | number;
    formatter?: (val: string | number) => string | number;
  }>;
  handleConfirm: (body?: Record<string, string | number>) => Promise<string>;
  hasExplicitRejectButton?: boolean;
  handleReject?: () => Promise<string>;
  onClose: () => void;
}
//INFO: This is specific to sodexo
export default function PromptModal({
  active,
  type,
  title,
  isEditable = false,
  dataFields,
  handleConfirm,
  hasExplicitRejectButton = false,
  handleReject,
  onClose,
}: PromptModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState<{
    type: "success" | "failure" | "warning";
    message: string;
  } | null>(null);
  const [isEditButtonShowing, setIsEditButtonShowing] = useState(isEditable);

  const formId = useId();
  //TODO: refactor state update
  async function handleClick(_type: "confirm" | "reject", body?: Record<string, string | number>) {
    try {
      setIsLoading(true);
      if (_type === "confirm") {
        const message = await handleConfirm(body);
        setStatusMessage({
          type: type === "approve" ? "success" : "failure",
          message,
        });
      } else if (hasExplicitRejectButton && !!handleReject) {
        const message = await handleReject();
        setStatusMessage({ type: "failure", message });
      }
    } catch (error) {
      setStatusMessage({ type: "warning", message: error?.message });
    } finally {
      setIsLoading(false);
    }
  }

  const isFormEditable = isEditable && !isEditButtonShowing;
  const primaryCtaLabel = type === "approve" ? "Yes, confirm" : "Yes, reject";

  const Buttons = (
    <div className="d-flex justify-content-end gap-3">
      <Button
        variant="secondary"
        color="brand"
        size="sm"
        onClick={onClose}
        disabled={isLoading}
      >
        No, cancel
      </Button>
      <Button
        variant="primary"
        size="sm"
        color={type !== "reject" ? "brand" : "danger"}
        onClick={isFormEditable ? undefined : () => handleClick("confirm")}
        autoFocus
        loading={isLoading}
        form={isFormEditable ? formId : undefined}
      >
        {primaryCtaLabel}
      </Button>
    </div>
  );

  return (
    <Modal active={active} cancellable={false}>
      <div className="p-4">
        {statusMessage ? (
          <TerminalStateContent {...statusMessage} onCtaClick={onClose} />
        ) : (
          <div>
            <div className="d-flex justify-content-between align-items-center mb-4">
              <p className="primary-color fs-18 fw-700 mb-0">{title}</p>
              {isEditButtonShowing && (
                <Button
                  variant="text"
                  size="sm"
                  style={{ minWidth: "unset" }}
                  onClick={() => setIsEditButtonShowing(false)}
                >
                  Edit
                </Button>
              )}
            </div>
            <EditFormAndDataFields
              formId={formId}
              dataFields={dataFields}
              isEditable={isEditable}
              isEditButtonClicked={isFormEditable}
              onFormSubmit={(body) => handleClick("confirm", body)}
            />
            <div className="mt-4">
              {!!hasExplicitRejectButton ? (
                <div className="d-flex justify-content-between">
                  <Button
                    variant="text"
                    size="sm"
                    color="danger"
                    style={{ minWidth: "unset" }}
                    onClick={() => handleClick("reject")}
                  >
                    Reject
                  </Button>
                  {Buttons}
                </div>
              ) : (
                Buttons
              )}
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
}
