import React, {
  ComponentPropsWithoutRef,
  CSSProperties,
  ReactNode,
} from "react";
import { Checkbox } from "../checkbox";
import CheckboxGroup from "../checkbox/checkbox-group";
import { constructClassName } from "../helpers/construct-classname";
import { mergeClassNames } from "../helpers/merge-classnames";
import { Tooltip } from "../tooltip";
import { SortOrder, TableSortButton } from "./sort-button";
import "./styles.css";

function CellLabel(props: { className?: string; children: ReactNode }) {
  const ref = React.useRef<HTMLDivElement | null>(null);
  const [hasOverflow, setHasOverflow] = React.useState(false);
  React.useEffect(() => {
    const element = ref.current;
    if (!element) return;
    const isOverflow = element.scrollWidth > element.clientWidth;
    setHasOverflow(isOverflow);
  }, []);
  if (hasOverflow) {
    return (
      <Tooltip label={props.children} position="top-start">
        <div className={props.className}>
          {props.children}
        </div>
      </Tooltip>
    );
  }
  return (
    <div ref={ref} className={props.className}>
      {props.children}
    </div>
  );
}

type TableColAlign = "left" | "center" | "right";

const TABLE_COL_ALIGN_MAP: Record<TableColAlign, CSSProperties["justifyContent"]> = {
  left: "flex-start",
  center: "center",
  right: "flex-end"
}

export interface TableColumn<T> {
  key: keyof T;
  label?: ReactNode;
  sortable?: boolean;
  sticky?: boolean;
  render?: (dataValue: T[keyof T], data: T) => ReactNode;
  align?: TableColAlign;
  minWidth?: CSSProperties["minWidth"];
  width?: CSSProperties["width"];
  maxWidth?: CSSProperties["maxWidth"];
  rowActionButtons?: ReactNode | ((data: T) => ReactNode);
  rowActionButtonsVisibleOn?: "none" | "always" | "hover";
  hidden?: boolean;
}

export interface TableProps<T> extends ComponentPropsWithoutRef<"table"> {
  loading?: boolean;
  columns: Array<TableColumn<T>>;
  data: T[];
  uniqueKey: keyof T;
  hasRowSelectCheckbox?: boolean;
  multipleFieldSortable?: boolean;
  header?: ReactNode;
  footer?: ReactNode;
  fallbackForNoData?: ReactNode;
  selectedItems?: string[];
  onSelectChange?: (newState: Array<keyof T>) => void;
  sortState?: { by: keyof T; order: SortOrder };
  onSortChange?: (state: { by: keyof T; order: SortOrder }) => void;
}

function renderHeader(header: ReactNode) {
  if (!header) return null;
  return <div className="rf-table__header">{header}</div>;
}

function renderFooter(footer: ReactNode) {
  if (!footer) return null;
  return <div className="rf-table__footer">{footer}</div>;
}

function renderHeaderRow<T extends Record<string, any>>(
  column: TableColumn<T>,
  sortState?: { by: keyof T; order: SortOrder },
  onSortChange?: (state: { by: keyof T; order: SortOrder }) => void
) {
  if (column.hidden) return null;
  const columnValue = column.label ?? column.key;
  return (
    <th key={column.key.toString()}>
      <div
        className="rf-table__table-header-cell"
        style={{
          minWidth: column.width,
          maxWidth: column.width,
          justifyContent: column.align && TABLE_COL_ALIGN_MAP[column.align],
        }}
      >
        <CellLabel className="rf-table__table-header-cell__label">
          {columnValue}
        </CellLabel>
        <div className="rf-table__table-header-cell__actions">
          {!!column.sortable && (
            <TableSortButton
              field={column.key}
              order={sortState?.by === column.key ? sortState.order : undefined}
              ariaLabel={`Sort by ${columnValue}`}
              onChange={onSortChange}
            />
          )}
        </div>
      </div>
    </th>
  );
}

function renderDataRow<T extends Record<string, any>>(
  columns: TableColumn<T>[],
  data: T
) {
  return columns.map(
    (column) =>
      !column.hidden && (
        <td key={column.key.toString()}>
          <div
            className="rf-table__table-data-cell"
            style={{
              minWidth: column.width,
              maxWidth: column.width,
              justifyContent: column.align && TABLE_COL_ALIGN_MAP[column.align],
            }}
          >
            <CellLabel className="rf-table__table-data-cell__label">
              {column.render
                ? column.render(data[column.key], data)
                : data[column.key]}
            </CellLabel>
            {!!column.rowActionButtons && (
              <div
                className={`rf-table__table-data-cell__actions visible-${column.rowActionButtonsVisibleOn}`}
              >
                {typeof column.rowActionButtons === "function"
                  ? column.rowActionButtons(data)
                  : column.rowActionButtons}
              </div>
            )}
          </div>
        </td>
      )
  );
}

// TODO: Later Refactor into small composable components

export function Table<T extends Record<string, any>>({
  loading = false,
  columns,
  data,
  uniqueKey,
  hasRowSelectCheckbox = false,
  header,
  footer,
  fallbackForNoData,
  className,
  selectedItems = [],
  onSelectChange,
  sortState,
  onSortChange,
  ...restProps
}: TableProps<T>) {

  function handleSelectAll() {
    if (selectedItems.length > 0) return onSelectChange?.([]);
    const _selectedItems = data.map((elem) => elem[uniqueKey]);
    onSelectChange?.(_selectedItems);
  }
  return (
    <div className="rf-table__container">
      {renderHeader(header)}
      <div
        className={constructClassName("rf-table__wrapper", {
          "has-footer": !!footer,
        })}
      >
        <table
          className={mergeClassNames("rf-table", className)}
          {...restProps}
        >
          <thead>
            <tr>
              {hasRowSelectCheckbox && (
                <th className="rf-table__table-cell__checkbox">
                  <Checkbox
                    size="sm"
                    value="SELECT_ALL"
                    aria-label="Select all rows"
                    checked={selectedItems.length > 0}
                    indeterminate={selectedItems.length !== data.length}
                    onChange={handleSelectAll}
                  />
                </th>
              )}
              {columns.map((column) =>
                renderHeaderRow(column, sortState, onSortChange)
              )}
            </tr>
          </thead>
          <tbody>
            <CheckboxGroup value={selectedItems} onChange={onSelectChange}>
              {data.map((eachData) => (
                <tr
                  key={eachData[uniqueKey]}
                  className={
                    selectedItems.includes(eachData[uniqueKey])
                      ? "selected"
                      : undefined
                  }
                >
                  {hasRowSelectCheckbox && (
                    <td className="rf-table__table-cell__checkbox">
                      <Checkbox
                        size="sm"
                        value={eachData[uniqueKey]}
                        aria-label={`Select row by ${uniqueKey.toString()}`}
                      />
                    </td>
                  )}
                  {renderDataRow(columns, eachData)}
                </tr>
              ))}
            </CheckboxGroup>
          </tbody>
        </table>
      </div>
      {renderFooter(footer)}
    </div>
  );
}
