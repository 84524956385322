import React, { ReactNode } from "react";
import { constructClassName } from "../helpers/construct-classname";
import "./styles.css";

export interface ContentProps {
  header?: ReactNode;
  children: ReactNode;
  footer?: ReactNode;
  fixedHeaderFooter?: boolean;
}

// TODO: Decide the naming later
export function Content({
  header,
  children,
  footer,
  fixedHeaderFooter = false,
}: ContentProps) {
  return (
    <div
      className={constructClassName("rf-content__root", {
        fixed: fixedHeaderFooter,
      })}
    >
      {!!header && (
        <header
          className={constructClassName("rf-content__header", {
            fixed: fixedHeaderFooter,
          })}
        >
          {header}
        </header>
      )}
      <main
        className={constructClassName("rf-content__main", {
          fixed: fixedHeaderFooter,
        })}
      >
        {children}
      </main>
      {!!footer && (
        <footer
          className={constructClassName("rf-content__footer", {
            fixed: fixedHeaderFooter,
          })}
        >
          {footer}
        </footer>
      )}
    </div>
  );
}
