import {
  AdvanceSalaryDto,
  AttendanceDetailDto,
  BulkEmployeeDocUploadHistoryDto,
  CreateEmployeeDto,
  EmployeeDetailsDto,
  EmployeePayoutDto,
  EmployerAdminDto,
  EmployerConfigDto,
  EmployerDetailsDto,
  EmployerMetadataDto,
  EmployerUploadedFileInfoDto,
  LeaveDetailsDto,
  PayrollDTO,
  PayrollInfoDto,
  PayrollMonthlySummaryDto,
  PayrollUploadDTO,
  ProfileDetailsDto,
  StreamingAccountInfoDto,
  StreamingAccountPayrollDetailsDto,
  TransactionDto,
  TransactionPendingApprovalDto,
  UpdateEmployeeDto,
  WorkerPendingApprovalDto,
} from "../dto";

export enum NotifierBgColor {
  ERROR = "red",
  WARNING = "orange",
  SUCCESS = "green",
}
export enum NotifierType {
  ERROR = "ERROR",
  WARNING = "WARNING",
  SUCCESS = "SUCCESS",
}
export enum RoutePaths {
  VENDORS = "/vendors",
  DASHBOARD = "/dashboard",
  ATTENDANCE = "/attendance",
  EMPLOYEES = "/employees",
  PAYROLL = "/payroll",
  PAYROLL_UPLOAD_SUMMARY = "/payroll/uploadSummary",
  PAYROLL_SUMMARY = "/payroll/summary",
  LEAVE = "/leave",
  PROFILE = "/profile",
  DOCUMENT_UPLOAD = "/documentUpload",
  EMPLOYEE_PROFILE = "/employeeProfile",
  EMPLOYERS_TRANSACTIONS = "/employerTransactions",
  SALARY_TOPUPS = "/salary-topups",
  SETTINGS = "/settings",
  USER_VERIFICATION = "/user-verification",
  EWA_REQUEST = "/ewa-request",
  MFA_REGISTER = "/mfa-register",
  OTP_VERIFICATION = "/otp-verification",
  ANALYTICS_DASHBOARD = "/analytics-dashboard",
}
export enum FieldName {
  BRANCH_LOCATION = "branchLocation",
  EMAIL = "email",
  JOINED_AT = "joinedAt",
  PASSWORD = "password",
  NAME = "name",
  ADDRESS = "address",
  RE_ENTER_PW = "reEnteredPw",
  FULL_NAME = "fullName",
  EMPLOYER_ID = "employer_id",
  MOBILE = "mobile",
  UPI_ID = "upi_id",
  START_DATE = "startDate",
  END_DATE = "endDate",
  PAN = "pan",
  SALARY = "currentMonthlySalary",
  EMPLOYEE_ID = "employerEmployeeId",
  MAX_WITHDRAWAL_PERCENTAGE = "maxWithdrawalSalaryPercent",
  MAX_WITHDRAW_PER_TRANSACTION = "maxWithdrawalPerTransaction",
  MAX_WITHDRAW_PER_DAY = "maxWithdrawalPerDay",
  SALARY_ACCOUNT_NUMBER = "salaryAccountNumber",
  DEPARTMENT = "departmentId",
  GRADE = "gradeId",
  BRANCH = "branchId",
  DESIGNATION = "designation",
  TRANSACTION_ALLOWED = "isTransactionAllowed",
}

export interface IValidation {
  status: boolean;
  msg: string;
  type: string;
}

// Input function props
export interface Text {
  label?: string;
  error?: string;
  value: string | number;
  disabled?: boolean;
  placeholder?: string;
  fieldName: string;
  onChange: (
    fieldName: string,
    value: string | number,
    valueType: string
  ) => void;
  type: string;
  valueType: string;
}

interface DropDownOption {
  value: string;
  label: string;
}

export interface DropDown {
  label?: string;
  error?: string;
  placeholder?: string;
  value: string | number;
  options: DropDownOption[];
  disabled?: boolean;
  fieldName: string;
  onChange: (fieldName: string, value: string | number) => void;
}

export enum DocumentStatus {
  VERIFIED = "VERIFIED",
  PENDING = "PENDING",
  INCORRECT = "INCORRECT",
}

export interface EmployeeAddEditForm {
  id?: string;
  fullName: string;
  joinedAt: string;
  email: string;
  employerEmployeeId: string;
  currentMonthlySalary: number | "";
  designation: string;
  mobile: string;
  maxWithdrawalSalaryPercent: string;
  maxWithdrawalPerTransaction: string | number;
  maxWithdrawalPerDay: string | number;
  salaryAccountNumber: string;
  isTransactionAllowed: boolean;
  departmentId: string;
  gradeId: string;
  branchId: string;
}

export enum WorkerType {
  EMPLOYEE = "employee",
  CONTRACTOR = "contractor",
}

export interface AdvanceSalaryRequest {
  id: string;
  type: "employee" | "contractor";
  amount: number;
}

export interface UpdateEmployeeDetails {
  id: string;
  fullName?: string;
  employerEmployeeId: string;
  currentMonthlySalary?: number;
  maxWithdrawalSalaryPercent?: number | null;
  maxWithdrawalPerTransaction?: number | null;
  maxWithdrawalPerDay?: number | null;
  joinedAt?: Date;
  email?: string;
  mobile?: string;
  salaryAccountNumber?: string;
  vendorId?: string;
}

export interface AdminRole {
  [roleName: string]: {
    role: {
      name: string;
      roleType: string;
      userType: string;
      _id: string;
      description: string;
    };
  };
}

export enum DocumentType {
  ATTENDANCE_SHEET_LIST = "ATTENDANCE_SHEET_LIST",
  LEAVES_SHEET_LIST = "LEAVES_SHEET_LIST",
  ATTENDANCE_SHEET = "ATTENDANCE_SHEET",
  PROCESSED_ATTENDANCE_SHEET = "PROCESSED_ATTENDANCE_SHEET",
  LEAVES_SHEET = "LEAVES_SHEET",
  PAYROLL = "PAYROLL",
  PROCESSED_PAYROLL = "PROCESSED_PAYROLL",
  PAYROLL_LIST = "PAYROLL_LIST",
  EMPLOYEE_TERMINATION_SHEET = "EMPLOYEE_TERMINATION_SHEET",
  PROCESSED_EMPLOYEE_TERMINATION_SHEET = "PROCESSED_EMPLOYEE_TERMINATION_SHEET",
  NEW_EMPLOYEE_SHEET = "NEW_EMPLOYEE_SHEET",
  PROCESSED_NEW_EMPLOYEE_SHEET = "PROCESSED_NEW_EMPLOYEE_SHEET",
  FINAL_REPORT = "FINAL_REPORT",
}

export enum EmployeePayoutTableTab {
  TOTAL = "TOTAL",
  FINALIZED = "FINALIZED",
  TRANSFERRED = "TRANSFERRED",
  PENDING = "PENDING",
  FAILED = "FAILED",
  SKIPPED = "SKIPPED",
}

export enum EmployeeEmploymentStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  IN_PROBATION = "IN_PROBATION",
}
export enum PendingApprovalEntityType {
  WORKER = "WORKER",
  TRANSACTION = "TRANSACTION",
}

export enum PendingApprovalStatus {
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}

export type PayrollUpload = PayrollUploadDTO;
export type PayrollDetails = PayrollDTO;
// TODO: Fix this ... export type = enum not working
// export type PayrollStatus = PayrollStatusDto;
export type PayrollInfo = PayrollInfoDto;
export type PayrollMonthlySummary = PayrollMonthlySummaryDto;
export type StreamingAccountInfo = StreamingAccountInfoDto;
export type StreamingAccountPayrollDetails = StreamingAccountPayrollDetailsDto;
export type EmployeePayout = EmployeePayoutDto;
export type EmployeeDetails = EmployeeDetailsDto;
export type CreateEmployee = CreateEmployeeDto;
export type UpdateEmployee = UpdateEmployeeDto;
export type EmployerMetadata = EmployerMetadataDto;
export type AttendanceDetails = AttendanceDetailDto;
export type LoggedInUserDetails = EmployerAdminDto;
export type EmployerDetails = EmployerDetailsDto | null;
export type LeaveDetails = LeaveDetailsDto;
export type Transaction = TransactionDto;
export type AdvanceSalary = AdvanceSalaryDto;
export type UserDetails = ProfileDetailsDto;
export type BulkEmployeeDocUploadHistory = BulkEmployeeDocUploadHistoryDto;
export type EmployerUploadedFileInfo = EmployerUploadedFileInfoDto;
export type EmployerConfig = EmployerConfigDto;
export type WorkerPendingApproval = WorkerPendingApprovalDto;
export type TransactionPendingApproval = TransactionPendingApprovalDto;
