import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReduxState } from "../../../reducers";
import dispatch from "../../../middleware";
import { isEmptyString } from "../../../utils";
import {
  AdminActivity,
  AdminTodo,
  AttendanceCycleDto,
  StatsDto,
  StuStatsByAttendanceCycleDto,
} from "../../../dto";
import StatBox from "../../../components/stat-box ";
import { getAdminTodo } from "../../../actions/userInfo";
import Todo from "./todo";
import Activity from "./activity";
import Banner from "../../../images/illustration.svg";
import getEffect from "../effect";
import { RoutePaths } from "../../../models";
import {
  getAttendanceCycle,
  getStuStatsForCurrentAttendanceCycle,
} from "../../../actions/attendance";
import TransactionSummary from "./transactionSummary";
import {
  getEmployerAdminActivities,
  getStats,
  getStuStats,
} from "../../../actions/employer";
import { Select } from "../../../components/input-functions";

const LendingProductTypes = ["EWA", "STU"] as const;
type LendingProductType = (typeof LendingProductTypes)[number];
const LendingProductOptions: Array<{
  value: LendingProductType;
  label: string;
}> = [
  {
    value: "EWA",
    label: "Earned Wage",
  },
  {
    value: "STU",
    label: "Salary Topups",
  },
];

function getAttendanceCycleStats(
  selectedLendingProduct: LendingProductType,
  ewaStatsForCurrentAttendanceCycle: AttendanceCycleDto | null,
  stuStatsForCurrentAttendanceCycle: StuStatsByAttendanceCycleDto | null
): AttendanceCycleDto | null {
  if (selectedLendingProduct === "EWA")
    return ewaStatsForCurrentAttendanceCycle;
  if (selectedLendingProduct === "STU") {
    if (!stuStatsForCurrentAttendanceCycle) return null;
    const {
      attendanceCycleDates,
      completeCycleSalaryTopupApplicationDetails,
      totalSalaryTopupApplications,
      totalSalaryTopupDisbursedValue,
    } = stuStatsForCurrentAttendanceCycle;
    return {
      attendanceCycleDates,
      totalNumberOfTransactions: totalSalaryTopupApplications,
      totalTransactionalValue: totalSalaryTopupDisbursedValue,
      completeCycleTransactionDetails:
        completeCycleSalaryTopupApplicationDetails,
    };
  }
  return null;
}

export default function Home() {
  const storeDispatch = useDispatch();
  const employerId: string = useSelector(
    (state: ReduxState) => state.login.userDetails.employerId
  );
  const stats: StatsDto | null = useSelector(
    (state: ReduxState) => state.employer.stats
  );
  const stuStats = useSelector((state: ReduxState) => state.employer.stuStats);
  const adminTodo: AdminTodo[] | null = useSelector(
    (state: ReduxState) => state.login.adminTodo
  );
  const adminActivities: AdminActivity | null = useSelector(
    (state: ReduxState) => state.employer.adminActivities
  );
  const attendanceCycle: AttendanceCycleDto | null = useSelector(
    (state: ReduxState) => state.attendance.attendanceCycle
  );
  const stuStatsForCurrentAttendanceCycle = useSelector(
    (state: ReduxState) => state.attendance.stuStatsForCurrentAttendanceCycle
  );
  const fullName: string = useSelector(
    (state: ReduxState) => state.login.userDetails?.name || ""
  );
  const [lendingProductSelected, setLendingProductSelected] =
    useState<LendingProductType>("EWA");

  function fetchActivities(status: boolean) {
    return dispatch(storeDispatch, getEmployerAdminActivities(status));
  }
  async function handleGetDashboardStats() {
    try {
      if (!adminTodo) dispatch(storeDispatch, getAdminTodo());
      if (!adminActivities) fetchActivities(true);
    } catch (e) {}
  }

  useEffect(() => {
    if (!isEmptyString(employerId)) {
      handleGetDashboardStats();
    }
  }, [employerId]);
  useEffect(getEffect("Dashboard", useDispatch()), []);
  useEffect(() => {
    if (lendingProductSelected === "EWA") {
      if (!stats) dispatch(storeDispatch, getStats(employerId));
      if (!attendanceCycle) dispatch(storeDispatch, getAttendanceCycle());
    }
    if (lendingProductSelected === "STU") {
      if (!stuStats) dispatch(storeDispatch, getStuStats(employerId));
      if (!stuStatsForCurrentAttendanceCycle)
        dispatch(storeDispatch, getStuStatsForCurrentAttendanceCycle());
    }
  }, [lendingProductSelected]);

  const statsToBeShown = {
    totalEmployees:
      lendingProductSelected === "EWA"
        ? stats?.totalEmployees
        : stuStats?.totalEmployees,
    totalTransactions:
      lendingProductSelected === "EWA"
        ? stats?.salaryWithdrawalCompleted
        : stuStats?.salaryTopupApplications,
    totalAmount:
      lendingProductSelected === "EWA"
        ? stats?.withdrawalValue
        : stuStats?.salaryTopupDisbursedValue,
  };
  const statsForCurrentAttendanceCycle =
    lendingProductSelected === "EWA"
      ? attendanceCycle
      : stuStatsForCurrentAttendanceCycle;

  return (
    <div>
      <div className={"row my-3"}>
        <div className="col-xl-8">
          <div className="card p-4 intro">
            <h5 className="mb-2 fs-18">Hey {fullName}!</h5>
            <p className="mb-4 fs-14 fw-300">Please complete your todo list.</p>
            <img src={Banner} className="intro-icon" alt="banner" />
          </div>

          <div className="d-flex align-items-center mt-4 mb-3">
            <p className="table-title mb-2 fw-600 fs-18 mr-3">Quick Stats</p>
            <Select
              fieldName="name"
              options={LendingProductOptions}
              value={lendingProductSelected}
              onChange={(_, value) =>
                setLendingProductSelected(value as LendingProductType)
              }
            />
          </div>
          <div className="row">
            <div className="col-xl-4 col-md-6 col-12">
              <StatBox
                label={"Total Employees"}
                value={statsToBeShown.totalEmployees ?? null}
                redirectRoute={RoutePaths.EMPLOYEES}
              />
            </div>
            <div className="col-xl-4 col-md-6 col-12">
              <StatBox
                label={"Total Transactions"}
                value={statsToBeShown.totalTransactions ?? null}
              />
            </div>
            <div className="col-xl-4 col-md-6 col-12">
              <StatBox
                label={"Total Withdraw Value"}
                value={statsToBeShown.totalAmount ?? null}
                redirectRoute={
                  lendingProductSelected === "EWA"
                    ? RoutePaths.EMPLOYERS_TRANSACTIONS
                    : RoutePaths.SALARY_TOPUPS
                }
              />
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col">
              <TransactionSummary
                attendanceCycle={getAttendanceCycleStats(
                  lendingProductSelected,
                  attendanceCycle,
                  stuStatsForCurrentAttendanceCycle
                )}
                viewAllRoute={
                  lendingProductSelected === "EWA"
                    ? RoutePaths.EMPLOYERS_TRANSACTIONS
                    : RoutePaths.SALARY_TOPUPS
                }
              />
            </div>
          </div>
        </div>
        <div className="col-xl-4 mt-lg-0 mt-4">
          <Activity
            activities={adminActivities}
            onShowMoreClick={() => fetchActivities(false)}
          />

          <Todo todoList={adminTodo} />
        </div>
      </div>
    </div>
  );
}
