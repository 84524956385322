import React, { FormEvent, useEffect, useMemo, useState } from 'react'
import DataTable, { IDataTableColumn } from 'react-data-table-component';
import { useDispatch } from 'react-redux';
import { getAllEmployeesAdvanceSalaries } from '../../../actions/employee';
import FullScreenLoader from '../../../components/fullscreen-loader';
import { Select } from '../../../components/input-functions';
import { AdvanceSalaryApplicationStatus } from '../../../dto';
import dispatch from '../../../middleware';
import { AdvanceSalary } from '../../../models';
import { dateToInputElemDate, downloadExcelFromArrayOfObjects, formatDate, getTodayDateForInputField, isEmptyString } from '../../../utils';

function getInitialDateRange() {
    const startDate = new Date();
    const endDate = new Date();

    startDate.setDate(1);
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);

    return {
        startDate: dateToInputElemDate(startDate),
        endDate: dateToInputElemDate(endDate),
    };
}

const FILTER_OPTIONS = [
    {
        label: "All",
        value: "ALL",
    },
    ...Object.values(AdvanceSalaryApplicationStatus).map(status => ({ label: status.replaceAll("_", " "), value: status }))
]

function filterAdvanceSalariesData(advanceSalaries: AdvanceSalary[], status: string) {
    const formattedRecords = advanceSalaries.map((elem) => {
        const { approvalDate, disbursalDate, emiStartDate } = elem;

        return {
            ...elem,
            approvalDate: approvalDate && formatDate(new Date(approvalDate)),
            disbursalDate: disbursalDate && formatDate(new Date(disbursalDate)),
            emiStartDate: emiStartDate && formatDate(new Date(emiStartDate)),
        }
    });

    if (status === "ALL") return formattedRecords;

    return formattedRecords.filter((t) => {
        return t.status === status;
    });
}

const COLUMNS: IDataTableColumn<AdvanceSalary>[] = [
    {
        minWidth: "10%",
        center: true,
        name: "Employee ID",
        selector: "employeeId",
        cell: (row) => <span className="primary-color fs-12">{row.employeeId}</span>,
    },
    {
        minWidth: "15%",
        center: true,
        name: "Name",
        selector: "name",
        cell: (row) => <span className="primary-color fs-12">{row.name}</span>,
    },
    {
        minWidth: "10%",
        center: true,
        name: "Approval date",
        selector: "approvalDate",
        cell: (row) => <span className="primary-color fs-12">{row.approvalDate ?? "-"}</span>,
    },
    {
        minWidth: "10%",
        center: true,
        name: "Disbursal date",
        selector: "disbursalDate",
        cell: (row) => <span className="primary-color fs-12">{row.disbursalDate ?? "-"}</span>,
    },
    {
        minWidth: "10%",
        center: true,
        name: "Amount Approved",
        selector: "currentApplicationTerm.amount",
        cell: (row) => <span className="primary-color fs-12">{row.currentApplicationTerm?.amount ?? "-"}</span>,
    },
    {
        minWidth: "10%",
        center: true,
        name: "Tenure",
        selector: "currentApplicationTerm.tenureInMonths",
        cell: (row) => <span className="primary-color fs-12">{row.currentApplicationTerm?.tenureInMonths ?? "-"}</span>,
    },
    {
        minWidth: "10%",
        center: true,
        name: "EMI amount",
        selector: "emiAmount",
        cell: (row) => <span className="primary-color fs-12">{row.emiAmount ?? "-"}</span>,
    },
    {
        minWidth: "10%",
        center: true,
        name: "EMI Start Date",
        selector: "emiStartDate",
        cell: (row) => <span className="primary-color fs-12">{row.emiStartDate ?? "-"}</span>,
    },
    {
        minWidth: "15%",
        center: true,
        name: "Status",
        selector: "status",
        cell: (row) => <span className="primary-color fs-12">{row.status ?? "-"}</span>,
    },
]

export default function AdvanceSalaries() {
    const storeDispatch = useDispatch()

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [dateRange, updateDateRange] = useState(getInitialDateRange);
    const [advanceSalaryRecords, setAdvanceSalaryRecords] = useState<AdvanceSalary[]>([]);
    const [filteredStatus, setFilteredStatus] = useState<string>("ALL");

    const filteredAdvanceSalaryRecords = useMemo(() => filterAdvanceSalariesData(advanceSalaryRecords, filteredStatus), [advanceSalaryRecords, filteredStatus]);

    useEffect(() => {
        fetchEmployeesAdvanceSalaries();
    }, [])

    function handleDateChange(fieldName: string, val: string) {
        updateDateRange((prevState) => {
            return {
                ...prevState,
                [fieldName]: val,
            };
        });
    }

    async function fetchEmployeesAdvanceSalaries() {
        const startDate = new Date(dateRange.startDate);
        const endDate = new Date(dateRange.endDate);
        try {
            setIsLoading(true);
            const resp: AdvanceSalary[] = await dispatch(storeDispatch, getAllEmployeesAdvanceSalaries(startDate, endDate));
            setAdvanceSalaryRecords(resp);
        } catch (error) {
            // TODO: Show error here
        } finally {
            setIsLoading(false);
        }
    }

    function handleDownloadClick() {
        if (filteredAdvanceSalaryRecords.length < 1) return;
        const payloadArr = filteredAdvanceSalaryRecords.map(elem => ({
            "Employee ID": elem.employeeId || "NA",
            "Name": elem.name || "NA",
            "Approval date": elem.approvalDate || "NA",
            "Disbursal date": elem.disbursalDate || "NA",
            "Amount Approved": elem.currentApplicationTerm?.amount || "NA",
            "Tenure": elem.currentApplicationTerm?.tenureInMonths || "NA",
            "EMI Amount": elem.emiAmount || "NA",
            "EMI Start Date": elem.emiStartDate || "NA",
            "Status": elem.status,
        }))
        downloadExcelFromArrayOfObjects(payloadArr, "advance-salary-report.xlsx");
    }

    function handleFormSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();
        fetchEmployeesAdvanceSalaries()
    }

    function renderTableHeader() {
        return (
            <div className="d-flex flex-column flex-md-row align-items-center justify-content-between">
                <p className="table-title fw-600 fs-18 m-0">Salary Topups</p>
                <div className="d-flex align-items-center">
                    <Select
                        value={filteredStatus}
                        options={FILTER_OPTIONS}
                        fieldName={"TRANSACTION_STATUS"}
                        onChange={(f: string, val: string | number) => setFilteredStatus(String(val))}
                    />
                    <button
                        className="btn outline-btn w-unset fs-12 ml-2 b-color-secondary secondary-color"
                        onClick={handleDownloadClick}
                        disabled={filteredAdvanceSalaryRecords.length < 1}
                    >
                        Download
                    </button>
                </div>
            </div>
        )
    }
    return (
        <div>
            <FullScreenLoader active={isLoading} />
            <p className="fw-600 fs-20 primary-color">Choose Date</p>
            <form className="row" onSubmit={handleFormSubmit}>
                <div className="col-md-5">
                    <div className="form-signin-label-group">
                        <input
                            type="date"
                            id="fromDate"
                            className="form-control input-login"
                            placeholder="From Date"
                            value={dateRange.startDate}
                            onChange={(e) => handleDateChange("startDate", e.target.value)}
                            max={getTodayDateForInputField()}
                            required
                        />
                        <label htmlFor="fromDate">From Date</label>
                    </div>
                </div>
                <div className="col-md-5">
                    <div className="form-signin-label-group">
                        <input
                            type="date"
                            id="toDate"
                            className="form-control input-login"
                            placeholder="To Date"
                            value={dateRange.endDate}
                            onChange={(e) => handleDateChange("endDate", e.target.value)}
                            min={dateRange.startDate}
                            max={getTodayDateForInputField()}
                            required
                        />
                        <label htmlFor="toDate">To Date</label>
                    </div>
                </div>

                <div className="col-md-2">
                    <button
                        type="submit"
                        className=" btn primary-button"
                        disabled={
                            isEmptyString(dateRange.startDate) || isEmptyString(dateRange.endDate)
                        }
                    >
                        Search
                    </button>
                </div>
            </form>
            <div className="mt-3">
                <DataTable
                    title={renderTableHeader()}
                    pagination={true}
                    columns={COLUMNS}
                    data={filteredAdvanceSalaryRecords}
                    className="table-wrapper"
                    highlightOnHover
                />
            </div>
        </div>
    );
}
