import React, {
  ComponentPropsWithoutRef,
  Ref,
  forwardRef,
  ReactNode,
} from "react";
import { constructClassName } from "../helpers/construct-classname";
import { CircleNotchAnimateIcon } from "../icon";
import { UnstyledButton, UnstyledButtonProps } from "../unstyled-button";
import "./styles.css";

type ButtonSize = "sm" | "md" | "lg";
type ButtonVariant = "primary" | "secondary" | "text";
type ButtonColor = "brand" | "danger" | "success";
type ButtonSpacing = "regular" | "compact" | "full-width";
type LoaderPosition = "left" | "right";

export interface ButtonProps extends UnstyledButtonProps {
  /** Predefined button size */
  size?: ButtonSize;

  /** Button color from theme */
  color?: ButtonColor;

  /** Adds icon before button label  */
  leftSection?: ReactNode;

  /** Adds icon after button label  */
  rightSection?: ReactNode;

  /** Controls button appearance */
  variant?: ButtonVariant;

  /** Set text-transform to uppercase */
  uppercase?: boolean;

  /** Reduces vertical and horizontal spacing */
  spacing?: ButtonSpacing;

  /** Indicate loading state */
  loading?: boolean;

  /** Loader position relative to button label */
  loaderPosition?: LoaderPosition;

  /** Button label */
  children?: React.ReactNode;

  /** Disabled state */
  disabled?: boolean;
}

function renderLeftSection(context: {
  loading: boolean;
  leftSection?: ReactNode;
  loaderPosition: LoaderPosition;
}): ReactNode {
  const { loading, leftSection, loaderPosition } = context;
  if (loading && loaderPosition === "left")
    return (
      <span className="rf-button__left-section">
        <CircleNotchAnimateIcon size="lg" />
      </span>
    );
  if (leftSection)
    return <span className="rf-button__left-section">{leftSection}</span>;
}

function renderRightSection(context: {
  loading: boolean;
  rightSection?: ReactNode;
  loaderPosition: LoaderPosition;
}): ReactNode {
  const { loading, rightSection, loaderPosition } = context;
  if (loading && loaderPosition === "right")
    return (
      <span className="rf-button__right-section">
        <CircleNotchAnimateIcon size="lg" />
      </span>
    );
  if (rightSection)
    return <span className="rf-button__right-section">{rightSection}</span>;
}

function _Button(
  {
    size = "md",
    color = "brand",
    variant = "primary",
    spacing = "regular",
    leftSection,
    rightSection,
    uppercase = false,
    loading = false,
    loaderPosition = "left",
    children,
    ...rest
  }: ButtonProps,
  ref: Ref<HTMLButtonElement>
) {
  const textTransform = uppercase ? "uppercase" : undefined;

  return (
    <UnstyledButton
      className={constructClassName("rf-button-root", {
        loading: loading,
        [`size-${size}`]: true,
        [`color-${color}`]: true,
        [`variant-${variant}`]: true,
        [`spacing-${spacing}`]: true,
      })}
      ref={ref}
      {...rest}
    >
      <div className="rf-button__inner">
        {renderLeftSection({ loading, leftSection, loaderPosition })}
        <span className="rf-button__label" style={{ textTransform }}>
          {children}
        </span>
        {renderRightSection({ loading, rightSection, loaderPosition })}
      </div>
    </UnstyledButton>
  );
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(_Button);
