import React from "react";
import DataField, { DataFieldProps } from "../../../components/data-field";
import DonutChart from "../../../components/charts/donut-chart";
import { formatInr } from "../../../utils";
import { ReactComponent as NotFoundIcon } from "../../../images/not-found.svg";

interface ActionLink {
  label: string;
  Icon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  onClick?: () => any;
  disabled?: boolean;
}

export interface PayrollMonthlySummaryProps {
  loading?: boolean;
  month?: string;
  hasData?: boolean;
  actionLinks?: ActionLink[];
  dataFieldRows?: DataFieldProps[][];
  disabled?: boolean;
  chartLabels?: string[];
  chartSeries?: (string | number)[];
}

export default function PayrollMonthlySummary({
  loading = false,
  month,
  hasData = true,
  actionLinks = [],
  dataFieldRows = [],
  disabled,
  chartLabels = [],
  chartSeries = [],
}: PayrollMonthlySummaryProps) {
  if (loading) return null;
  if (!hasData) {
    return (
      <div className="payroll-monthly-summary">
        <div className="d-flex flex-column align-items-center">
          <NotFoundIcon width="80" />
          <h4 className="payroll-monthly-summary__month-title mt-4 mb-2">
            Oops! No Data found
          </h4>
          <p className="payroll-monthly-summary__message-text my-1">
            Seems like payroll was not processed for the month of {month}
          </p>
        </div>
      </div>
    );
  }
  return (
    <div className={`payroll-monthly-summary${disabled ? " disabled" : ""}`}>
      <div className="d-flex flex-column flex-md-row align-items-center justify-content-between">
        <h4 className="payroll-monthly-summary__month-title">{month}</h4>
        <div className="payroll-monthly-summary__action-link-container">
          {actionLinks.map(({ label, Icon, onClick, disabled }, index) => (
            <button
              key={index}
              className="btn btn-link"
              onClick={onClick}
              disabled={disabled}
            >
              {Icon && (
                <Icon className="payroll-monthly-summary__action-link--icon" />
              )}
              <span className="payroll-monthly-summary__action-link--label">
                {label}
              </span>
            </button>
          ))}
        </div>
      </div>
      <div className="d-flex flex-column flex-lg-row align-items-center pt-5 pb-4">
        <div className="payroll-monthly-summary__donut-chart">
          <DonutChart
            labels={chartLabels}
            values={chartSeries}
            totalText="Total Amount Paid"
            showTotal={!disabled}
            totalFormatter={formatInr}
          />
        </div>
        <div className="payroll-monthly-summary__data-fileds flex-fill">
          {dataFieldRows.map((dataFieldsRow, index) => (
            <div
              key={index}
              className="row payroll-monthly-summary__data-filed--row"
            >
              {dataFieldsRow.map((dataFieldProps, index) => (
                <div className="col" key={index}>
                  <DataField key={index} {...dataFieldProps} />
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
