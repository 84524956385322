import { MiddlewareAction } from "../middleware";

export enum VendorAction {
    REQUEST_VENDOR_LIST = "REQUEST_VENDOR_LIST",
    REQUEST_VENDOR_DETAILS = "REQUEST_VENDOR_DETAILS",
    REQUEST_VENDOR_ATTENDANCE_LIST = "REQUEST_VENDOR_ATTENDANCE_LIST",
    REQUEST_GET_VENDOR_EMPLOYEES = "REQUEST_GET_VENDOR_EMPLOYEES",
    REQUEST_GET_VENDOR_ADMINS = "REQUEST_GET_VENDOR_ADMINS",
    CLEAR_VENDOR_DATA = "CLEAR_VENDOR_DATA",
    REQUEST_VENDOR_ATTENDANCE_CYCLE = "REQUEST_VENDOR_ATTENDANCE_CYCLE",
    REQUEST_VENDOR_STATS = "REQUEST_VENDOR_STATS",
    REQUEST_VENDOR_ADMIN_ACTIVITY = "REQUEST_VENDOR_ADMIN_ACTIVITY",
}

export function getVendorList(): MiddlewareAction {
    return {
        actionName: VendorAction.REQUEST_VENDOR_LIST,
        type: "GET",
        url: "/employer-admin/vendors",
    }
}

export function getVendorDetails(vendorId: string): MiddlewareAction {
    return {
        actionName: VendorAction.REQUEST_VENDOR_DETAILS,
        type: "GET",
        url: `/employer-admin/vendors/${vendorId}`,
    }
}

export function clearVendorData() {
    return {
        actionName: VendorAction.CLEAR_VENDOR_DATA,
    }
}