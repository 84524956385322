import React, { useEffect, useMemo, useState } from "react";
import DataField from "../../../../components/data-field";
import NotificationBox from "../../../../components/notification-box";
import { ReactComponent as CalendarIcon } from "../../../../images/calendar.svg";
import { ReactComponent as WalletIcon } from "../../../../images/wallet.svg";
import { ReactComponent as DownloadIcon } from "../../../../images/download-icon.svg";
import PayrollMonthlySummary from "../monthlySummary";
import { useDispatch, useSelector } from "react-redux";
import dispatch from "../../../../middleware";
import {
  getPayrollInfo,
  getPayrollMonthlySummary,
  getStreamingAccountInfo,
  getStreamingAccountPayrollDetails,
} from "../../../../actions/payroll";
import { ReduxState } from "../../../../reducers";
import {
  formatDate,
  formatInr,
  isLoadingActive,
} from "../../../../utils";
import FullScreenLoader from "../../../../components/fullscreen-loader";
import {
  PayrollInfo,
  StreamingAccountInfo,
} from "../../../../models";
import {
  dateStringToMonthAndYear,
  generateMonthYearDescendingSeries,
  getMonthlySummaryProps,
} from "../helpers";
import { getNotificationBoxProps } from "./helpers";
import isAuthorized from "../../../../authorization/authorizationAccess";
import { AccessType, Resource } from "../../../../authorization/authorization.enum";

export default function PayrollDashboard() {
  const storeDispatch = useDispatch();

  const [selectedMonthForSummary, setSelectedMonthForSummary] = useState("");

  const loadingQueue = useSelector(
    (state: ReduxState) => state.payroll.loadingQueue
  );
  const resources = useSelector(
    (state: ReduxState) => state.login.userDetails?.resources || {}
  );
  const payrollInfo = useSelector(
    (state: ReduxState) => state.payroll.payrollInfo
  );
  const payrollMonthlySummary = useSelector(
    (state: ReduxState) => state.payroll.payrollMonthlySummary
  );
  const streamingAccountInfo = useSelector(
    (state: ReduxState) => state.payroll.streamingAccountInfo
  );
  const streamingAccountPayroll = useSelector(
    (state: ReduxState) => state.payroll.streamingAccountPayroll
  );
  const streamingAccountPayrollCurrentMonth = useSelector(
    (state: ReduxState) => state.payroll.streamingAccountPayrollCurrentMonth
  );

  const summaryMonthYearText =
    (selectedMonthForSummary
      ? selectedMonthForSummary
      : payrollMonthlySummary?.latestExecutedPayrollMonthYear) || "";

  const payrollMonthYearList = generateMonthYearDescendingSeries(
    payrollMonthlySummary?.firstExecutedPayrollMonthYear,
    payrollMonthlySummary?.latestExecutedPayrollMonthYear
  );

  const hasWriteAccess = useMemo(
    () =>
      isAuthorized(
        resources,
        Resource.EMPLOYER_PORTAL_PAYROLL,
        AccessType.WRITE
      ),
    [resources]
  );

  useEffect(() => {
    dispatch(storeDispatch, getPayrollInfo(), false);
    dispatch(storeDispatch, getPayrollMonthlySummary(), false);
    dispatch(storeDispatch, getStreamingAccountInfo(), false);
  }, []);

  useEffect(() => {
    if (!payrollInfo || !payrollMonthlySummary) return;
    let { month, year } = dateStringToMonthAndYear(
      payrollInfo.monthForWhichPayrollWillBeExecuted
    );
    if (payrollInfo.isCurrentPayrollActive) {
      month = undefined;
      year = undefined;
    }
    dispatch(
      storeDispatch,
      getStreamingAccountPayrollDetails({ month, year, isCurrentMonth: true }),
      false
    );
  }, [payrollInfo, payrollMonthlySummary]);

  useEffect(() => {
    if (!payrollInfo || !summaryMonthYearText) return;
    const isCurrentPayrollActive = payrollInfo.isCurrentPayrollActive;
    const isCurrentMonth =
      summaryMonthYearText === payrollInfo.monthForWhichPayrollWillBeExecuted;
    let { month, year } = dateStringToMonthAndYear(summaryMonthYearText);
    if (isCurrentPayrollActive && isCurrentMonth) {
      month = undefined;
      year = undefined;
    }
    dispatch(
      storeDispatch,
      getStreamingAccountPayrollDetails({ month, year }),
      false
    );
  }, [summaryMonthYearText, payrollInfo]);

  function renderPayrollCard(
    payrollInfo: PayrollInfo | null,
    streamingAccountInfo: StreamingAccountInfo | null
  ) {
    const nextPayrollDate = payrollInfo
      ? formatDate(new Date(payrollInfo?.nextPayroll))
      : "";
    const accountBalance = formatInr(streamingAccountInfo?.accountBalance ?? 0, { roundOff: false });
    return (
      <div className="payroll-card">
        <div className="my-4">
          <DataField
            Icon={CalendarIcon}
            label="Next Payroll"
            value={nextPayrollDate}
          />
        </div>
        <div className="my-4">
          <DataField
            Icon={WalletIcon}
            label="Account Balance"
            value={accountBalance}
          />
        </div>
        <hr />
        <div className="text-center">
          <a
            href={streamingAccountInfo?.rechargeLink}
            rel="noopener noreferrer"
            target="_blank"
          >
            Add amount
          </a>
        </div>
      </div>
    );
  }

  return (
    <>
      <FullScreenLoader active={isLoadingActive(loadingQueue)} />
      <div className="row flex-column-reverse flex-xl-row">
        <div className="col-xl-9">
          <NotificationBox
            loading={isLoadingActive(loadingQueue)}
            {...getNotificationBoxProps({
              payrollInfo,
              payrollDetails: streamingAccountPayrollCurrentMonth,
              hasWriteAccess,
              storeDispatch
            })}
          />
          <div className="d-flex align-items-center justify-content-between mt-5 mb-2">
            <h5>Monthly Summary</h5>
            <select
              className="payroll-monthly-summary__month-dropdown"
              value={summaryMonthYearText}
              onChange={(e) => setSelectedMonthForSummary(e.target.value)}
            >
              {payrollMonthYearList.map((monthYear) => (
                <option key={monthYear} value={monthYear}>
                  {monthYear}
                </option>
              ))}
            </select>
          </div>
          <PayrollMonthlySummary
            loading={isLoadingActive(loadingQueue)}
            {...getMonthlySummaryProps({
              monthText: summaryMonthYearText,
              payrollDetails: streamingAccountPayroll,
              hasExecutedAnyPayrollBefore:
                payrollMonthlySummary?.hasExecutedAnyPayrollBefore,
              DownloadIcon,
              storeDispatch,
            })}
          />
        </div>
        <div className="col mb-5 mb-xl-0">
          {renderPayrollCard(payrollInfo, streamingAccountInfo)}
        </div>
      </div>
    </>
  );
}
