import React, { useEffect, useMemo, useState } from "react";
import PayrollMonthlySummary from "../monthlySummary";
import { ReactComponent as DownloadIcon } from "../../../../images/download-icon.svg";
import NotificationBox from "../../../../components/notification-box";
import TabBar from "../../../../components/tab-bar";
import Table from "../../../../components/table";
import {
  formatInr,
  getErrorMsgFromResponse,
  isLoadingActive,
  navigateAndReplaceTo,
  formatDateInDDMMYYYY,
  dateToTimeString,
} from "../../../../utils";
import Pagination from "../../../../components/library/pagination";
import { useDispatch, useSelector } from "react-redux";
import { useQueryParams } from "../../../../hooks/useQueryParams";
import { ReduxState } from "../../../../reducers";
import FullScreenLoader from "../../../../components/fullscreen-loader";
import dispatch from "../../../../middleware";
import {
  getEmployeePayoutDetails,
  getPayrollInfo,
  getPayrollMonthlySummary,
  getStreamingAccountPayrollDetails,
  reinitiatePayrollFailedTransactions,
} from "../../../../actions/payroll";
import {
  dateStringToMonthAndYear,
  generateMonthYearDescendingSeries,
  getTabCountValue,
  calculatePageCount,
  getMonthlySummaryProps,
} from "../helpers";
import {
  EmployeePayoutTableTab,
  NotifierType,
  RoutePaths,
  StreamingAccountPayrollDetails,
} from "../../../../models";
import { PayoutStatusDto, PayrollStatusDto } from "../../../../dto";
import Modal from "../../../../components/modal";
import Badge, { BadgeType } from "../../../../components/badge";
import { showNotifier } from "../../../../actions/ui";
import ConfirmDialog from "../../../../components/confirm-dialog";
import isAuthorized from "../../../../authorization/authorizationAccess";
import { AccessType, Resource } from "../../../../authorization/authorization.enum";

const statusesInWhichPayrollSummaryShouldRender = [
  PayrollStatusDto.DISBURSAL_INITIATED,
  PayrollStatusDto.DISBURSAL_IN_PROGRESS,
  PayrollStatusDto.DISBURSAL_COMPLETED,
  PayrollStatusDto.DISBURSAL_FAILED,
];

function renderHorizontalField(label: string, value?: string | number, removeWhenValueUndefined: boolean = false) {
  if (removeWhenValueUndefined && !value) return;
  return (
    <p className="d-flex my-4">
      <span className="fs-14 fw-300  primary-color m-0 w-50">{label}</span>
      <span className="fs-14 fw-600  primary-color m-0 w-50 break-word">
        {value}
      </span>
    </p>
  );
}

export default function PayrollSummary() {
  const storeDispatch = useDispatch();
  const [queryParams, setQueryParams] = useQueryParams({
    month: "",
    page: "1",
    tab: EmployeePayoutTableTab.TOTAL,
  });
  const { month: monthText, page: pageNo, tab } = queryParams;

  const [searchText, setSearchText] = useState("");
  const [hasSearchQueryActive, setHasSearchQueryActive] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isRerunConfirmationModalActive, setIsRerunConfirmationModalActive] = useState(false);
  const [isTransactionDetailsModalActive, setIsTransactionDetailsModalActive] =
    useState(false);
  const [transactionDetails, setTransactionDetails] = useState<{
    id?: string;
    utr?: string;
    date?: string;
    time?: string;
    status?: string;
  }>();

  const loadingQueue = useSelector(
    (state: ReduxState) => state.payroll.loadingQueue
  );
  const resources = useSelector(
    (state: ReduxState) => state.login.userDetails?.resources || {}
  );
  const payrollInfo = useSelector(
    (state: ReduxState) => state.payroll.payrollInfo
  );
  const payrollMonthlySummary = useSelector(
    (state: ReduxState) => state.payroll.payrollMonthlySummary
  );
  const streamingAccountPayroll = useSelector(
    (state: ReduxState) => state.payroll.streamingAccountPayroll
  );
  const { data: employeePayoutDetails, totalCount } = useSelector(
    (state: ReduxState) => state.payroll.employeePayoutDetails
  );

  const monthYearText = monthText
    ? monthText
    : payrollInfo?.monthForWhichPayrollWillBeExecuted;

  const payrollMonthYearList = generateMonthYearDescendingSeries(
    payrollMonthlySummary?.firstExecutedPayrollMonthYear,
    payrollMonthlySummary?.latestExecutedPayrollMonthYear
  );

  const totalPageCount = useMemo(
    () =>
      calculatePageCount(
        totalCount,
        streamingAccountPayroll?.paginationInfo.perPageLimit
      ),
    [totalCount, streamingAccountPayroll]
  );

  const hasWriteAccess = useMemo(
    () =>
      isAuthorized(
        resources,
        Resource.EMPLOYER_PORTAL_PAYROLL,
        AccessType.WRITE
      ),
    [resources]
  );

  useEffect(() => {
    dispatch(storeDispatch, getPayrollInfo(), false);
    dispatch(storeDispatch, getPayrollMonthlySummary(), false);
  }, []);

  useEffect(() => {
    let { month, year } = dateStringToMonthAndYear(monthYearText);
    dispatch(storeDispatch, getStreamingAccountPayrollDetails({ month, year }))
      .then((payroll: StreamingAccountPayrollDetails) => {
        setErrorMessage("");
        if (
          payroll.status &&
          !statusesInWhichPayrollSummaryShouldRender.includes(payroll.status)
        ) {
          return navigateAndReplaceTo(RoutePaths.PAYROLL);
        }
      })
      .catch((e) => {
        setErrorMessage(getErrorMsgFromResponse(e));
      });
  }, [monthYearText]);

  useEffect(() => {
    let { month, year } = dateStringToMonthAndYear(monthYearText);

    dispatch(
      storeDispatch,
      getEmployeePayoutDetails({
        page: Number(pageNo),
        searchId: hasSearchQueryActive ? searchText : undefined,
        status: tab,
        month,
        year,
      }),
      false
    );
  }, [pageNo, tab, monthText]);

  function handleSearchForm(e: React.FormEvent<HTMLFormElement>) {
    const { month, year } = dateStringToMonthAndYear(monthYearText);
    e.preventDefault();
    setHasSearchQueryActive(true);
    dispatch(
      storeDispatch,
      getEmployeePayoutDetails({
        status: tab,
        searchId: searchText,
        month,
        year,
      }),
      false
    );
  }
  function clearSearchText() {
    setSearchText("");
    setHasSearchQueryActive(false);
    const { month, year } = dateStringToMonthAndYear(monthYearText);
    dispatch(
      storeDispatch,
      getEmployeePayoutDetails({
        page: Number(pageNo),
        status: tab,
        month,
        year,
      }),
      false
    );
  }

  function handleTransactionDetailsModalClose() {
    setIsTransactionDetailsModalActive(false);
  }
  function handleTransactionDetailsModalOpen(transactionId?: string) {
    if (!transactionId) return;
    const payoutDataIndex = employeePayoutDetails.findIndex(
      ({ requestId }) => requestId === transactionId
    );
    if (payoutDataIndex < 0) return;
    const { requestId, status, utr, updatedDate } = employeePayoutDetails[payoutDataIndex];

    setTransactionDetails({
      id: requestId,
      utr,
      date: formatDateInDDMMYYYY(updatedDate),
      time: dateToTimeString(updatedDate),
      status,
    });
    setIsTransactionDetailsModalActive(true);
  }

  async function handleReinitiateClick() {
    if (!streamingAccountPayroll?._id) return;
    try {
      await dispatch(storeDispatch, reinitiatePayrollFailedTransactions(streamingAccountPayroll?._id));
      let { month, year } = dateStringToMonthAndYear(monthYearText);
      dispatch(storeDispatch, getStreamingAccountPayrollDetails({ month, year }), false);
      setQueryParams((prevState) => ({ ...prevState, page: "1", tab: EmployeePayoutTableTab.TOTAL }));
    } catch (error) {
      const message = getErrorMsgFromResponse(error);
      dispatch(
        storeDispatch,
        showNotifier("", message, NotifierType.ERROR, {
          autoClose: false,
        })
      );
    } finally {
      setIsRerunConfirmationModalActive(false);
    }
  }

  function getNotificationBoxMessage(
    payrollStatus?: PayrollStatusDto,
    errorMsg?: string
  ) {
    if (errorMsg) {
      return {
        message: errorMsg,
      };
    }
    if (
      payrollStatus === PayrollStatusDto.DISBURSAL_INITIATED ||
      payrollStatus === PayrollStatusDto.DISBURSAL_IN_PROGRESS
    ) {
      return {
        message: "Payroll execution in progress.",
      };
    }
    if (payrollStatus === PayrollStatusDto.DISBURSAL_COMPLETED) {
      return {
        message: "You have successfully ran payroll.",
      };
    }
    return {
      message: "Payroll execution failed",
    };
  }

  function renderRerunPayrollButton(currentTab: string, isActive?: boolean) {
    if (currentTab !== EmployeePayoutTableTab.FAILED) return;
    return (
      <div className="mt-3 mt-lg-0 ml-lg-2">
        <button
          className="btn btn-primary btn-lg payroll-search-bar--button"
          disabled={!isActive}
          onClick={() => setIsRerunConfirmationModalActive(true)}
        >
          Re-Run Payroll
        </button>
      </div>
    );
  }

  function renderStatusBadge(status: PayoutStatusDto) {
    const pendingStatuses = [
      PayoutStatusDto.INITIATED,
      PayoutStatusDto.PENDING,
    ];
    const failedStatuses = [PayoutStatusDto.FAILED, PayoutStatusDto.CANCELLED];

    let badgeType: BadgeType = BadgeType.INFO;
    if (status === PayoutStatusDto.TRANSFERRED) badgeType = BadgeType.SUCCESS;
    else if (pendingStatuses.includes(status)) badgeType = BadgeType.PENDING;
    else if (failedStatuses.includes(status)) badgeType = BadgeType.REJECT;

    return <Badge type={badgeType}>{status}</Badge>;
  }

  return (
    <>
      <FullScreenLoader active={isLoadingActive(loadingQueue)} />
      <NotificationBox
        loading={isLoadingActive(loadingQueue)}
        {...getNotificationBoxMessage(
          streamingAccountPayroll?.status,
          errorMessage
        )}
      />
      <div className="d-flex align-items-center justify-content-between mt-5 mb-2">
        <h5>Payroll Summary</h5>
        <select
          className="payroll-monthly-summary__month-dropdown"
          name="monthYear"
          value={monthYearText}
          onChange={(e) =>
            setQueryParams((prevState) => ({
              ...prevState,
              month: e.target.value,
              page: "1",
              tab: EmployeePayoutTableTab.TOTAL,
            }))
          }
        >
          {payrollMonthYearList.map((monthYear) => (
            <option key={monthYear} value={monthYear}>
              {monthYear}
            </option>
          ))}
        </select>
      </div>
      <PayrollMonthlySummary
        loading={isLoadingActive(loadingQueue)}
        {...getMonthlySummaryProps({
          monthText: monthYearText,
          payrollDetails: streamingAccountPayroll,
          hasExecutedAnyPayrollBefore:
            payrollMonthlySummary?.hasExecutedAnyPayrollBefore,
          DownloadIcon,
          storeDispatch,
          hasViewSummaryAction: false,
          setQueryParams,
        })}
      />
      <div className="payroll-card mt-5">
        <div style={{ overflowX: "auto" }}>
          <div className="d-flex flex-column flex-lg-row justify-content-between mb-4">
            <div className="flex-fill">
              <form className="payroll-search-bar" onSubmit={handleSearchForm}>
                <input
                  className="form-control form-control-lg payroll-search-bar--input"
                  type="text"
                  placeholder="Search Employee by name or ID"
                  name="searchText"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  required
                />
                <button
                  type="submit"
                  className="btn btn-outline-primary btn-lg payroll-search-bar--button"
                >
                  Search
                </button>
              </form>
              {hasSearchQueryActive && (
                <button className="btn btn-link" onClick={clearSearchText}>
                  <i className="fa fa-times-circle"></i> Clear search query
                </button>
              )}
            </div>
            {renderRerunPayrollButton(tab, hasWriteAccess && streamingAccountPayroll?.isReexecutionAllowed)}
          </div>
          <TabBar
            onClick={(tab) =>
              setQueryParams((prevState) => ({ ...prevState, tab, page: "1" }))
            }
            tabs={[
              {
                title: `Total ${getTabCountValue(
                  streamingAccountPayroll?.totalNumberOfEmployeeEntries
                )}`,
                key: EmployeePayoutTableTab.TOTAL,
              },
              {
                title: `Paid ${getTabCountValue(
                  streamingAccountPayroll?.paid?.count
                )}`,
                key: EmployeePayoutTableTab.TRANSFERRED,
              },
              {
                title: `Pending ${getTabCountValue(
                  streamingAccountPayroll?.pending?.count
                )}`,
                key: EmployeePayoutTableTab.PENDING,
              },
              {
                title: `Failed ${getTabCountValue(
                  streamingAccountPayroll?.failed?.count
                )}`,
                key: EmployeePayoutTableTab.FAILED,
              },
              {
                title: `Skipped ${getTabCountValue(
                  streamingAccountPayroll?.skippedEntries
                )}`,
                key: EmployeePayoutTableTab.SKIPPED,
              },
            ]}
            value={tab}
          />
          <Table
            columns={[
              { label: "Name", field: "name" },
              { label: "Employee ID", field: "employerEmployeeCode" },
              { label: "Mobile Number", field: "mobile" },
              { label: "Salary", field: "salary", formatter: formatInr },
              {
                label: "Refyne Deductions",
                field: "refyneDeductions",
                formatter: formatInr,
              },
              {
                label: "Net Salary",
                field: "amountPayable",
                formatter: formatInr,
              },
              { label: "Account Number", field: "accountNumber" },
              { label: "IFSC", field: "ifsc" },
              { label: "Date", field: "createdDate", formatter: formatDateInDDMMYYYY },
              {
                label: "Transaction ID",
                field: "requestId",
                formatter: (val) => (
                  <button
                    className="btn btn-link"
                    onClick={() => handleTransactionDetailsModalOpen(val)}
                  >
                    {val}
                  </button>
                ),
              },
              {
                label: "Status",
                field: "status",
                formatter: renderStatusBadge,
              },
            ]}
            data={employeePayoutDetails}
          />
          <Pagination
            currentPage={Number(pageNo)}
            totalPageCount={totalPageCount}
            onChange={(pageNo) =>
              setQueryParams((prevState) => ({
                ...prevState,
                page: String(pageNo),
              }))
            }
          />
        </div>
      </div>
      <Modal
        active={isTransactionDetailsModalActive}
        cancellable
        onClose={handleTransactionDetailsModalClose}
      >
        <div className="p-4">
          <h4 className="rf-notifier__title">Transaction details</h4>
          {renderHorizontalField("Transaction ID:", transactionDetails?.id)}
          {renderHorizontalField("Date:", transactionDetails?.date)}
          {renderHorizontalField("Time:", transactionDetails?.time)}
          {renderHorizontalField("UTR:", transactionDetails?.utr, true)}
          {renderHorizontalField(
            "Transaction Status:",
            transactionDetails?.status
          )}
        </div>
      </Modal>
      <ConfirmDialog
        active={isRerunConfirmationModalActive}
        onClose={() => setIsRerunConfirmationModalActive(false)}
        title="Re-run Payroll?"
        message="Are you sure, you want to Re-run the payroll?"
        positiveActionButtonLabel="Re-run Payroll"
        onPositiveActionButtonClick={handleReinitiateClick}
        negativeActionButtonLabel="Not Now"
        onNegativeActionButtonClick={() => setIsRerunConfirmationModalActive(false)}
      />
    </>
  );
}
