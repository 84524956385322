import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  activateEmployees,
  terminateEmployees,
} from "../../../actions/employee";
import { Button } from "../../../components/library/button";
import { CheckCircleIcon, WarningIcon } from "../../../components/library/icon";
import Tag from "../../../components/library/tag";
import { Tooltip } from "../../../components/library/tooltip";
import dispatch from "../../../middleware";
import { getErrorMsgFromResponse } from "../../../utils";

function renderNameList(
  items: Array<{
    employerEmployeeId: string;
    fullName?: string;
    reason?: string;
  }>,
  tagColor: "white" | "error" = "white"
) {
  return (
    <div
      className="my-2"
      style={{
        backgroundColor: "var(--clr-grey-light)",
        borderRadius: "12px",
        padding: "18px",
        width: "100%",
        maxHeight: "160px",
        overflowY: "auto",
        display: "flex",
        gap: "8px",
        flexWrap: "wrap",
      }}
    >
      {items.map((item) => {
        const tagElem = (
          <Tag
            key={item.employerEmployeeId}
            color={tagColor}
            rounded
            textOverflow="visible"
          >
            {item.fullName ?? "NA"}
          </Tag>
        );

        if (item.reason) {
          const reason = `Reason: ${item.reason}`;
          return (
            <Tooltip
              key={item.employerEmployeeId}
              label={reason}
              position="top-start"
            >
              {tagElem}
            </Tooltip>
          );
        }
        return tagElem;
      })}
    </div>
  );
}

export type EmployeeAction = "TERMINATE" | "ACTIVATE";

interface EmployeeActionResponse {
  employeeId: string;
  status: "SUCCESS" | "FAILED";
  reason?: string;
}

interface EmployeeActionModalContentProps {
  action: EmployeeAction;
  items: Array<{ employerEmployeeId: string; fullName: string }>;
  onFinish?: () => void;
  onClose?: () => void;
}

export default function EmployeeActionModalContent({
  action,
  items,
  onFinish,
  onClose,
}: EmployeeActionModalContentProps) {
  const storeDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [actionStatus, setActionStatus] = useState<"SUCCESS" | "FAILED">();
  const [failureResponse, setFailureResponse] = useState<
    Array<{ employerEmployeeId: string; fullName?: string; reason?: string }>
  >([]);
  const [errorMessage, setErrorMessage] = useState("");

  const employerEmployeeIdToNameMapping = useMemo(
    () =>
      items.reduce((record, item) => {
        record[item.employerEmployeeId] = item.fullName;
        return record;
      }, {} as Record<string, string>),
    [items]
  );

  async function handleAction() {
    const employerEmployeeIds = items.map((item) => item.employerEmployeeId);
    let resp: EmployeeActionResponse[] | null = null;
    try {
      setIsLoading(true);
      if (action === "ACTIVATE") {
        resp = await dispatch(
          storeDispatch,
          activateEmployees(employerEmployeeIds)
        );
      }
      if (action === "TERMINATE") {
        resp = await dispatch(
          storeDispatch,
          terminateEmployees(employerEmployeeIds)
        );
      }

      const failedItems = resp?.filter((item) => item.status === "FAILED");
      if (failedItems && failedItems.length > 0) {
        const formattedFailedResp = failedItems.map((item) => ({
          employerEmployeeId: item.employeeId,
          fullName: employerEmployeeIdToNameMapping[item.employeeId],
          reason: item.reason,
        }));
        setActionStatus("FAILED");
        setFailureResponse(formattedFailedResp);
      } else {
        setActionStatus("SUCCESS");
      }
    } catch (error) {
      const errMessage = getErrorMsgFromResponse(error);
      setActionStatus("FAILED");
      setErrorMessage(errMessage);
    } finally {
      setIsLoading(false);
    }
  }

  if (actionStatus === "SUCCESS")
    return (
      <div className="d-flex align-items-center justify-content-center flex-column p-4">
        <CheckCircleIcon
          variant="fill"
          color="var(--clr-info-success-default)"
          size="el"
        />
        <h5 className="fs-16 fw-600 primary-color mt-4 text-center">
          {action === "TERMINATE" ? "Termination" : "Activation"} Successful
        </h5>
        <p className="fs-14 fw-400 light-gray text-center my-2">
          All the select employess got{" "}
          {action === "TERMINATE" ? "terminated" : "activated"} successfully.
        </p>
        <div className="mt-4 align-self-end">
          <Button variant="primary" color="brand" size="sm" onClick={onFinish}>
            Ok
          </Button>
        </div>
      </div>
    );

  const isFailure = actionStatus === "FAILED";
  if (isFailure && errorMessage) {
    return (
      <div className="d-flex align-items-center justify-content-center flex-column p-4">
        <WarningIcon
          variant="fill"
          color="var(--clr-info-alert-default)"
          size="el"
        />
        <h5 className="fs-16 fw-600 primary-color mt-4 text-center">
          {action === "TERMINATE" ? "Termination" : "Activation"} Failed
        </h5>
        <p className="fs-14 fw-400 light-gray text-center my-2">
          {errorMessage}
        </p>
        <div className="mt-4 align-self-end">
          <Button variant="primary" color="brand" size="sm" onClick={onClose}>
            Close
          </Button>
        </div>
      </div>
    );
  }

  if (isFailure && failureResponse.length > 0) {
    const isAllFailed = items.length === failureResponse.length;

    const actionTitle = action === "TERMINATE" ? "Termination" : "Activation";
    const actionDesc = action === "TERMINATE" ? "terminated" : "activated";

    return (
      <div className="d-flex align-items-center justify-content-center flex-column p-4">
        <WarningIcon
          variant="fill"
          color="var(--clr-info-alert-default)"
          size="el"
        />
        <h5 className="fs-16 fw-600 primary-color mt-4 text-center">
          {isAllFailed ? `${actionTitle} Failed` : `Partial ${actionTitle}`}
        </h5>
        <p className="fs-14 fw-400 light-gray text-center my-2">
          {isAllFailed
            ? `${actionTitle} failed for all ${failureResponse.length} employee(s)`
            : `Only ${
                items.length - failureResponse.length
              } employes(s) got ${actionDesc} out of ${items.length}.`}
        </p>
        {renderNameList(failureResponse, "error")}
        <div className="mt-4 align-self-end">
          <Button variant="primary" color="brand" size="sm" onClick={onFinish}>
            Close
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="d-flex align-items-center justify-content-center flex-column p-4">
      <WarningIcon
        variant="fill"
        color="var(--clr-info-alert-default)"
        size="el"
      />
      <h5 className="fs-16 fw-600 primary-color mt-4 text-center">
        Are you sure want to {action === "TERMINATE" ? "terminate" : "activate"}{" "}
        {items.length} employees?
      </h5>
      <p className="fs-14 fw-400 light-gray text-center my-2">
        If {action === "TERMINATE" ? "terminating" : "activating"}, all the
        selected employee will be moved to{" "}
        {action === "TERMINATE" ? "inactive" : "active"} state.
      </p>
      {renderNameList(items, "white")}
      <div className="mt-4 align-self-end">
        <Button
          variant="text"
          color="danger"
          size="sm"
          disabled={isLoading}
          onClick={onClose}
        >
          Not now
        </Button>
        <Button
          variant="primary"
          color="brand"
          size="sm"
          onClick={handleAction}
          loading={isLoading}
        >
          Yes, {action === "TERMINATE" ? "terminate" : "activate"}
        </Button>
      </div>
    </div>
  );
}