import React, {useEffect} from "react";
import DataTable from "react-data-table-component";
import dispatch from "../../../middleware";
import {
  downloadExcelFromBase64,
  formatDate,
  formatDateFromNumber,
  getErrorMsgFromResponse, getParamFromUrl,
  navigateTo,
} from "../../../utils";
import {showNotifier} from "../../../actions/ui";
import {AttendanceDetails, NotifierBgColor, RoutePaths} from "../../../models";
import {useDispatch, useSelector} from "react-redux";
import {downloadAttendance, listAttendance,} from "../../../actions/attendance";
import {ReduxState} from "../../../reducers";
import FullScreenLoader from "../../../components/fullscreen-loader";
import getEffect from "../effect";
import Authorized from "../../../authorization/authorized";
import {AccessType, Resource} from "../../../authorization/authorization.enum";
import isAuthorized from "../../../authorization/authorizationAccess";
import { logEventInFirebase } from "../../../analytics/firebase.analytics";
import AnalyticsEvent from "../../../analytics/events";


function renderPrevAttendanceUploads(
  attendanceDetails: AttendanceDetails[],
  handleClickButton: (
    documentId: string,
    employerAdminId: string,
    type: string
  ) => void
) {
  const columns = [
    {
      minWidth: "20%",
      name: "Upload date",
      selector: "uploadedDate",
      sortable: true,
      cell: (row: any) => (
        <span className="primary-color fs-12">{row.uploadedDate}</span>
      ),
    },
    {
      minWidth: "30%",
      name: "Uploaded by",
      selector: "uploadedBy",
      sortable: true,
      cell: (row: any) => (
        <span className="primary-color fs-12">{row.uploadedBy}</span>
      ),
    },
    {
      minWidth: "20%",
      name: "Upload history",
      selector: "latestAttendanceDate",
      sortable: true,
      cell: (row: any) => (
        <span className="primary-color fs-12">{row.latestAttendanceDate}</span>
      ),
    },
    {
      minWidth: "10%",
      name: "Download",
      cell: (row: any) => (
        <button
          type="button"
          className="btn btn-link p-0"
          onClick={() =>
            handleClickButton(
              row.documentId,
              row.employerAdminId,
              "downloadAttendanceSheet"
            )
          }
        >
          <i className="fa fa-cloud-download" />
        </button>
      ),
      ignoreRowClick: true,
      button: true,
    },
  ];

  const tableData = attendanceDetails.map((attendanceDetail, index) => {
    return {
      documentId: attendanceDetail.processedDocumentId,
      uploadedDate: formatDate(new Date(attendanceDetail.uploadedDate)),
      uploadedBy: attendanceDetail.uploadedBy,
      latestAttendanceDate: formatDateFromNumber(attendanceDetail.latestAttendanceDate),
      status: attendanceDetail.status || "PROCESSED",
      employerAdminId: attendanceDetail.employerAdminId,
    };
  });

  return (
    <div>
      <DataTable
        className="table-wrapper"
        columns={columns}
        data={tableData}
        pagination
        paginationPerPage={5}
        subHeaderAlign={"left"}
        title={
          <div className="d-flex  align-items-center justify-content-between">
            <p className="table-title fw-600 fs-18 m-0">
              Attendance
            </p>
          </div>
        }
      />
    </div>
  );
}

export default function Attendance() {
  const storeDispatch = useDispatch();
  const vendorId = getParamFromUrl("vendorId");
  const authResource = vendorId ? Resource.EMPLOYER_PORTAL_VENDORS_ATTENDANCE : Resource.EMPLOYER_PORTAL_ATTENDANCE;
  const loading: boolean = useSelector(
    (state: ReduxState) => state.attendance.loading
  );
  const resources = useSelector(
      (state: ReduxState) => state.login.userDetails?.resources || {}
  );
  const attendances: AttendanceDetails[] = useSelector(
    (state: ReduxState) => vendorId ? state.attendance.vendorAttendances : state.attendance.attendances
  );
  attendances.sort((a, b) => (a.uploadedDate < b.uploadedDate ? 1 : -1));

  async function handleClickButton(
    documentId: string,
    employerAdminId: string,
    type: string
  ) {
    try {
      if (type === "downloadAttendanceSheet") {
        const response = await dispatch(
          storeDispatch,
          downloadAttendance(documentId, employerAdminId)
        );
        downloadExcelFromBase64(response.b64File, `attendance_${documentId}`);
      }
    } catch (e) {
      await dispatch(
        storeDispatch,
        showNotifier(NotifierBgColor.ERROR, getErrorMsgFromResponse(e))
      );
    }
  }

  function renderNotAccessible() {
    return (
        <div className={"d-flex justify-content-center align-items-center"}>
          You aren't authorized to access this page
        </div>
    )
  }

  function getBulkUploadPath() {
    let path = `${RoutePaths.DOCUMENT_UPLOAD}?document=ATTENDANCE`;
    if (vendorId) path = `${path}&vendorId=${vendorId}`;
    return path;
  }

  useEffect(() => {
    if(!attendances?.length && isAuthorized(resources, authResource, AccessType.READ)) {
      dispatch(storeDispatch, listAttendance(vendorId));
    }
  }, []);
  useEffect(getEffect("Attendance", useDispatch()), []);

  return (
    <div>
      <FullScreenLoader active={loading} />
      <Authorized resourceName={authResource} requiredAccessType={AccessType.WRITE}>
        <div className="d-flex justify-content-end">
          <div className="d-flex mt-3 mb-3 mt-md-0">
            <button
                className="btn outline-btn w-unset fs-12 b-color-secondary secondary-color"
                onClick={() => {
                  logEventInFirebase(AnalyticsEvent.UPLOAD_BULK_ATTENDANCE_OPTION_CLICKED, { vendorId });
                  navigateTo(getBulkUploadPath())
                }}
            >
              Bulk Upload
            </button>
          </div>
        </div>
      </Authorized>
      <Authorized
        resourceName={authResource}
        requiredAccessType={AccessType.READ}
        unAuthorizedView={renderNotAccessible()}
      >
        {renderPrevAttendanceUploads(attendances, handleClickButton)}
      </Authorized>
    </div>
  );
}
