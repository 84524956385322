import React, { ChangeEvent, CSSProperties, ReactNode } from "react";
import { useId } from "../../../../hooks/use-id";
import { RadioGroupProvider } from "../radio-group.context";
import "./styles.css";

type RadioGroupOrientation = "horizontal" | "vertical";

const ORIENTATION_TO_FLEX_DIRECTION_MAP: Record<
  RadioGroupOrientation,
  CSSProperties["flexDirection"]
> = {
  horizontal: "row",
  vertical: "column",
};
const ORIENTATION_TO_FLEX_ALIGN_ITEMS_MAP: Record<
  RadioGroupOrientation,
  CSSProperties["alignItems"]
> = {
  horizontal: "center",
  vertical: "flex-start",
};

export interface RadioGroupProps {
  label?: string;
  required?: boolean;
  orientation?: RadioGroupOrientation;
  spacing?: CSSProperties["gap"];
  value?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  children: ReactNode;
  unStyled?: boolean;
  error?: ReactNode;
}

export function RadioGroup({
  label,
  required = false,
  orientation = "horizontal",
  spacing = "24px",
  value,
  onChange,
  children,
  unStyled = false,
  error,
}: RadioGroupProps) {
  const labelId = useId();

  if (unStyled) {
    return (
      <RadioGroupProvider value={{ value, onChange }}>
        {children}
      </RadioGroupProvider>
    );
  }
  return (
    <RadioGroupProvider value={{ value, onChange }}>
      <div
        className="rf-radio-group__root"
        role="radiogroup"
        aria-labelledby={labelId}
      >
        <div className="rf-radio-group__label" id={labelId}>
          {label}
          {required && (
            <span className="rf-radio-group__label-required" aria-hidden="true">
              {" "}
              *
            </span>
          )}
        </div>
        <div
          className="rf-radio-group__container"
          style={{
            flexDirection: ORIENTATION_TO_FLEX_DIRECTION_MAP[orientation],
            alignItems: ORIENTATION_TO_FLEX_ALIGN_ITEMS_MAP[orientation],
            gap: spacing,
          }}
        >
          {children}
        </div>
        {error && <div className="rf-radio-group__error">{error}</div>}
      </div>
    </RadioGroupProvider>
  );
}
