import React from "react";
import "./styles.css";

interface InfoBarProps {
  data?: {
    label: string;
    value?: string | number;
  }[];
}

export default function InfoBar({ data = [] }: InfoBarProps) {
  return (
    <div className="info-bar">
      {data.map(({ label, value }, index) => (
        <div key={index} className="info-bar__data-container">
          <h5 className="info-bar__data-container--label">{label}</h5>
          <p className="info-bar__data-container--value">{value}</p>
        </div>
      ))}
    </div>
  );
}
