import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FullScreenLoader from "../../../components/fullscreen-loader";
import StatBox from "../../../components/stat-box ";
import dispatch from "../../../middleware";
import { ReduxState } from "../../../reducers";
import { getParamFromUrl } from "../../../utils";
import {getAttendanceCycle} from "../../../actions/attendance";
import Transactionummary from "../home/transactionSummary";
import {AdminActivity, AttendanceCycleDto, StatsDto} from "../../../dto";
import AllStats from "../home/allStats";
import Activity from "../home/activity";
import {getEmployerAdminActivities, getStats} from "../../../actions/employer";

export default function VendorSummary() {
    const storeDispatch = useDispatch();
    const loader: boolean = useSelector((state: ReduxState) => state.vendor.loading);
    const employerId: string = useSelector(
        (state: ReduxState) => state.login.userDetails.employerId
      );
    const vendorId: string | null = getParamFromUrl("vendorId");
    const vendorAttendanceCycle: AttendanceCycleDto | null = useSelector(
        (state: ReduxState) => state.attendance.vendorAttendanceCycle
    );
    const vendorStats: StatsDto | null = useSelector(
        (state: ReduxState) => state.employer.vendorStats
    );
    const vendorAdminActivities: AdminActivity | null = useSelector(
        (state: ReduxState) => state.employer.vendorAdminActivities
    );
    function fetchActivities(status: boolean) {
        return dispatch(storeDispatch, getEmployerAdminActivities(status, vendorId));
    }
    async function getVendorSummary() {
        try {
            if(vendorId) {
                if(!vendorStats) dispatch(storeDispatch, getStats(employerId, vendorId));
                if(!vendorAttendanceCycle) dispatch(storeDispatch, getAttendanceCycle(vendorId));
                if (!vendorAdminActivities) fetchActivities(true);
            }
        } catch(e) {

        }
    }
    useEffect(() => {
        if (vendorId) {
            getVendorSummary()
        }
    }, [])
    return (
        <div>
            <FullScreenLoader active={loader}/>
            <div className={"row my-3"}>
                <div className="col-xl-8">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-12">
                            <StatBox
                                label={"Total Employees"}
                                value={vendorStats?.totalEmployees || "0"}
                                redirectRoute={`/vendors/employees?vendorId=${vendorId}`}
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 col-12">
                            <StatBox
                                label={"Total Transactions"}
                                value={vendorStats?.salaryWithdrawalCompleted || "0"}
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 col-12">
                            <StatBox
                                label={"Total Withdraw Value"}
                                value={`₹ ${vendorStats?.withdrawalValue || 0}`}
                                redirectRoute={`/vendors/transactions?vendorId=${vendorId}`}
                            />
                        </div>
                    </div>
                    <div className="row my-3">
                        <div className="col-6">
                            <AllStats stats={vendorStats}/>
                        </div>
                        <div className="col-6">
                            <Transactionummary
                                attendanceCycle={vendorAttendanceCycle}
                                viewAllRoute={`/vendors/transactions?vendorId=${vendorId}`}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 mt-lg-0 mt-4">
                    <Activity activities={vendorAdminActivities} onShowMoreClick={() => fetchActivities(false)}/>
                </div>
            </div>
        </div>
    )
}