import React, { CSSProperties, ReactNode } from "react";
import { XCircleIcon } from "../icon";
import { IconButton } from "../icon-button";
import { Overlay } from "../overlay";
import "./styles.css";

type DrawerStickPosition = "left" | "right" | "top" | "bottom";

export interface DrawerProps {
  open: boolean;
  onClose: () => void;
  stickPosition?: DrawerStickPosition;
  size?: string;
  borderRadius?: CSSProperties["borderRadius"];
  padding?: CSSProperties["padding"];
  closeOnEscape?: boolean;
  closeOnOutsideClick?: boolean;

  withCloseButton?: boolean;
  closeButtonLabel?: string;

  children: ReactNode;
  overlayBackgroundColor?: string;
  overlayOpacity?: CSSProperties["opacity"];
  overlayZIndex?: CSSProperties["zIndex"];
}

function getPositionStyles(context: {
  position: DrawerStickPosition;
  size: CSSProperties["height"] | CSSProperties["width"];
  borderRadius: CSSProperties["borderRadius"];
}): Partial<CSSProperties> | undefined {
  const { position, size, borderRadius } = context;
  // TODO: Handle sizes
  if (position === "top")
    return {
      top: 0,
      left: 0,
      right: 0,
      height: size,
      borderBottomLeftRadius: borderRadius,
      borderBottomRightRadius: borderRadius,
    };
  if (position === "bottom")
    return {
      bottom: 0,
      left: 0,
      right: 0,
      height: size,
      borderTopLeftRadius: borderRadius,
      borderTopRightRadius: borderRadius,
    };
  if (position === "right")
    return {
      bottom: 0,
      top: 0,
      right: 0,
      width: size,
      borderTopLeftRadius: borderRadius,
      borderBottomLeftRadius: borderRadius,
    };
  if (position === "left")
    return {
      bottom: 0,
      top: 0,
      left: 0,
      width: size,
      borderTopRightRadius: borderRadius,
      borderBottomRightRadius: borderRadius,
    };
}

function getCloseButtonStyles(
  position: DrawerStickPosition,
  buttonSize: number = 40
): Partial<CSSProperties> | undefined {
  if (position === "left")
    return { right: buttonSize * -1, top: `calc(50vh - ${buttonSize / 2}px)` };
  if (position === "right")
    return { left: buttonSize * -1, top: `calc(50vh - ${buttonSize / 2}px)` };
  if (position === "top")
    return {
      bottom: buttonSize * -1,
      left: `calc(50vw - ${buttonSize / 2}px)`,
    };
  if (position === "bottom")
    return { top: buttonSize * -1, left: `calc(50vw - ${buttonSize / 2}px)` };
}

export function Drawer({
  open,
  onClose,
  size = "25%",
  stickPosition = "left",
  padding = "40px",
  borderRadius,
  closeOnOutsideClick = true,
  closeOnEscape = true,
  withCloseButton,
  closeButtonLabel = "Close",
  children,
  overlayBackgroundColor = "var(--clr-text-primary)",
  overlayOpacity = 0.56,
  overlayZIndex = 0,
}: DrawerProps) {
  // TODO: Add Return Focus on Close & Focus trap

  if (!open) return null;
  return (
    <div className="rf-drawer__root" role="dialog" aria-modal>
      <div
        className="rf-drawer__container"
        style={{
          padding,
          ...getPositionStyles({
            position: stickPosition,
            size,
            borderRadius,
          }),
        }}
        onKeyDownCapture={(event) => {
          event.key === "Escape" && closeOnEscape && onClose();
        }}
        tabIndex={-1}
      >
        <div className="rf-drawer__body">{children}</div>
        {withCloseButton && (
          <div
            className="rf-drawer__close"
            style={getCloseButtonStyles(stickPosition)}
          >
            <IconButton title={closeButtonLabel} spacing="no-padding" onClick={onClose}>
              <XCircleIcon
                size="lg"
                ariaHidden
                variant="fill"
                color="var(--clr-grey-light)"
              />
            </IconButton>
          </div>
        )}
      </div>
      <div className="rf-drawer__overlay">
        <Overlay
          backgroundColor={overlayBackgroundColor}
          opacity={overlayOpacity}
          zIndex={overlayZIndex}
          onMouseDown={closeOnOutsideClick ? onClose : undefined}
        />
      </div>
    </div>
  );
}
