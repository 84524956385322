import { MutableRefObject, useEffect } from "react";

export function useOnFocusOutside(
  ref: MutableRefObject<HTMLElement | null>,
  handler: (event: FocusEvent) => void
) {
  useEffect(() => {
    const listener = (event: FocusEvent) => {
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("focusin", listener);
    return () => {
      document.removeEventListener("focusin", listener);
    };
  }, [ref, handler]);
}
