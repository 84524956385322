export class AuthUser {
    static email: string;
    static phone: string;
    static password: string;
    static phoneOtpVerificationId: any;
    static recaptchaVerifier: any;
    static multiFactorAuthRequired: boolean;
    static resolver: any;

    static resetProperties() {
        this.email = '';
        this.phone = '';
        this.password = '';
        this.phoneOtpVerificationId = null;
        this.recaptchaVerifier = null;
        this.multiFactorAuthRequired = false;
        this.resolver = null;
    }
}