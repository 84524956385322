import {
  AsyncValidationRules,
  FormErrors,
  ValidationError,
  ValidationErrors,
  ValidationRules,
} from "../../types";
import { filterErrors } from "../filter-errors";

function getValidationResult<Values>(
  errors: FormErrors<Values>
): ValidationErrors<Values> {
  const filteredErrors = filterErrors(errors);
  return {
    hasError: Object.keys(filteredErrors).length > 0,
    errors: filteredErrors,
  };
}

export function validateValues<Values>(
  rules: ValidationRules<Values>,
  values: Values,
  errors: FormErrors<Values> = {}
): ValidationErrors<Values> {
  const _errors = Object.keys(rules).reduce((accumulator, _ruleKey) => {
    const ruleKey = _ruleKey as keyof ValidationRules<Values>;
    const validator = rules[ruleKey];
    const value = values[ruleKey];

    if (validator) {
      accumulator[ruleKey] = validator(value, values);
    }

    return accumulator;
  }, errors);
  return getValidationResult(_errors);
}

export function validateFieldValue<Values>(
  path: keyof Values,
  rules: ValidationRules<Values>,
  values: Values
): ValidationError {
  const validator = rules[path];
  const value = values[path];

  if (!validator) return { hasError: false, error: null };
  const error = validator(value, values);
  if (error === null || error === undefined || error === false)
    return { hasError: false, error: null };
  return { hasError: !!error, error };
}

export async function validateFieldValueAsync<Values>(
  path: keyof Values,
  rules: AsyncValidationRules<Values>,
  values: Values
): Promise<ValidationError> {
  const validator = rules[path];
  const value = values[path];

  if (!validator) return { hasError: false, error: null };
  const error = await validator(value, values);
  if (error === null || error === undefined || error === false)
    return { hasError: false, error: null };
  return { hasError: !!error, error };
}
