import React, { useEffect } from "react";
import {
  clearFirebaseResponse,
  confirmOTP,
  resendOtp,
} from "../../actions/login";
import { useDispatch, useSelector } from "react-redux";
import { ReduxState } from "../../reducers";
import { showNotifier } from "../../actions/ui";
import { NotifierBgColor } from "../../models";
import dispatch from "../../middleware";
import Logo from "../../images/logo-dark.svg";
import Otp from "../../components/otp";
import { AuthUser } from "../../auth/auth";
import firebase from "firebase/app";
import FullScreenLoader from "../../components/fullscreen-loader";
import { renderFirebaseResponse } from "./utils";
import { navigateTo } from "../../utils";

export default function OtpVerification() {
  const firebaseResponse = useSelector(
    (state: ReduxState) => state.login.firebaseResponse
  );
  const loading: boolean = useSelector(
    (state: ReduxState) => state.login.loading
  );
  const storeDispatch = useDispatch();

  useEffect(() => {
    if (firebaseResponse.type !== "") {
      dispatch(storeDispatch, clearFirebaseResponse());
    }
    if(!AuthUser.phone){
      navigateTo("/")
    }
  }, []);

  useEffect(() => {
    AuthUser.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "2fa-captcha",
      {
        size: "invisible",
      }
    );
  }, []);

  return (
    <div className="auth-page-wrapper">
      <FullScreenLoader active={loading} opacity={"1"} />
      <div className="auth-small-card text-center">
        <div id="2fa-captcha"></div>
        <div>
          <img src={Logo} alt="Logo" width="100px" />
        </div>
        <p className="subheading text-center mt-5 mb-2">Enter OTP</p>
        <p className="primary-color fs-12">
          Please confirm your account by entering the otp sent to{" "}
          {AuthUser.phone}
        </p>
        <Otp
          timer={15}
          handleResend={resendOtp}
          handleSubmit={(otp: string) => confirmOTP(otp, storeDispatch)}
          isSubmitting={false}
        />
        {firebaseResponse.type !== "" &&
          renderFirebaseResponse(firebaseResponse.response.message)}
      </div>
    </div>
  );
}
