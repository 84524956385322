import React from 'react';
import { config } from '../../firebase/firebaseUtils';
import ResetPassword from '../login/resetPasswordForm';

const domain = document.location.host;
const login_url = `https://${domain}/#/`

export default function FirebaseAction() {
    function handleVerifyEmail(auth:any, actionCode:any) {
        auth.applyActionCode(actionCode).then(function(resp:any) {
          window.location.replace(login_url)
        }).catch(function(error: Error) {
          console.log(error)
        });
      }

      function handleResetPassword(actionCode:any) {
        return <ResetPassword loginUrl={login_url} actionCode={actionCode}/>
      }

      function handleFirebaseAction(){
        console.log('running')

        const urlParams = new URLSearchParams(`?${window.location.href.split("?")[1]}`);
        const mode = urlParams.get('mode');
        // Get the one-time code from the query parameter.
        const actionCode = urlParams.get('oobCode');
        const auth = config.auth();
        console.log(mode)
        switch (mode) {
            case 'resetPassword':
              return handleResetPassword(actionCode);
            case 'recoverEmail':
            {return "can't handle recover mail"; }
            case 'verifyEmail':
            {handleVerifyEmail(auth, actionCode); break;}
            default:
            return 'wrong action mode'
        }
        return 'Verifying and redirecting...'
      }

    return (
        <div>
            { handleFirebaseAction()}
        </div>
    )
}
