import {MiddlewareAction} from "../middleware";
import {VendorAction} from "./vendor";

export enum Actions {
    REQUEST_DOWNLOAD_ATTENDANCE = "REQUEST_DOWNLOAD_ATTENDANCE",
    REQUEST_ATTENDANCE_LIST = "REQUEST_ATTENDANCE_LIST",
    REQUEST_UPLOAD_ATTENDANCE_BULK = "REQUEST_UPLOAD_ATTENDANCE_BULK",
    REQUEST_ATTENDANCE_CYCLE = "REQUEST_ATTENDANCE_CYCLE",
    REQUEST_STU_STATS_FOR_CURRENT_ATTENDANCE_CYCLE = "REQUEST_STU_STATS_FOR_CURRENT_ATTENDANCE_CYCLE"
}

export function uploadAttendanceInBulk(b64File: string, vendorId?: string | null): MiddlewareAction {
    const params = {
        vendorId,
    }
    if (!vendorId) delete params.vendorId;
    return {
        actionName: Actions.REQUEST_UPLOAD_ATTENDANCE_BULK,
        type: "POST",
        url: "/employer-admin/upload-attendance-doc",
        params,
        body: {
            file: b64File,
        }
    }
}

export function listAttendance(vendorId?: string | null): MiddlewareAction {
    const params = {
        vendorId,
    }
    if (!vendorId) delete params.vendorId;
    const actionName = vendorId ? VendorAction.REQUEST_VENDOR_ATTENDANCE_LIST :Actions.REQUEST_ATTENDANCE_LIST;
    return {
        actionName,
        type: "GET",
        params,
        url: "/employer-admin/attendance",
    }
}

export function downloadAttendance(documentId: string, employerAdminId: string): MiddlewareAction {
    return {
        actionName: Actions.REQUEST_DOWNLOAD_ATTENDANCE,
        type: "GET",
        url: `/employer-admin/doc?documentType=PROCESSED_ATTENDANCE_SHEET&documentId=${documentId}&employerAdminId=${employerAdminId}`,
    }
}

export function getAttendanceCycle(vendorId?: string | null): MiddlewareAction {
    const params = {vendorId};
    if (!vendorId) delete  params.vendorId;
    return {
        actionName: vendorId ? VendorAction. REQUEST_VENDOR_ATTENDANCE_CYCLE : Actions.REQUEST_ATTENDANCE_CYCLE,
        type: "GET",
        url: "/employer-admin/stats/attendance-cycle",
        params,
    }
}

export function getStuStatsForCurrentAttendanceCycle(): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_STU_STATS_FOR_CURRENT_ATTENDANCE_CYCLE,
    type: "GET",
    url: "/employer-admin/current-attendance-cycle-salarytopup-stats",
  };
}
