import React from "react";
import {AccessType, Resource} from "./authorization.enum";
import {useSelector} from "react-redux";
import {ReduxState} from "../reducers";

interface AuthorizedProps {
    resourceName: Resource | null;
    children: JSX.Element;
    requiredAccessType: AccessType;
    unAuthorizedView?: JSX.Element;
}

export default function Authorized(props: AuthorizedProps) {
    const resources = useSelector((state: ReduxState) => state.login?.userDetails?.resources || {})

    if (
        props.resourceName &&
        (
            resources[props.resourceName]?.includes(props.requiredAccessType) ||
            resources[props.resourceName]?.includes(AccessType.WRITE)
        )
    ) {
        return props.children
    }
    return props.unAuthorizedView ? props.unAuthorizedView : <></>
}