export enum Resource {
    EMPLOYER_PORTAL_ADMIN = "EMPLOYER_PORTAL_ADMIN",
    EMPLOYER_PORTAL_EMPLOYEES = "EMPLOYER_PORTAL_EMPLOYEES",
    EMPLOYER_PORTAL_PAYROLL = "EMPLOYER_PORTAL_PAYROLL",
    EMPLOYER_PORTAL_ATTENDANCE = "EMPLOYER_PORTAL_ATTENDANCE",
    EMPLOYER_PORTAL_TRANSACTIONS = "EMPLOYER_PORTAL_TRANSACTIONS",
    EMPLOYER_PORTAL_RAW_FILES = "EMPLOYER_PORTAL_RAW_FILES",

    EMPLOYER_PORTAL_VENDORS_ADMIN = "EMPLOYER_PORTAL_VENDORS_ADMIN",
    EMPLOYER_PORTAL_VENDORS_ATTENDANCE = "EMPLOYER_PORTAL_VENDORS_ATTENDANCE",
    EMPLOYER_PORTAL_VENDORS_EMPLOYEES = "EMPLOYER_PORTAL_VENDORS_EMPLOYEES",
    EMPLOYER_PORTAL_VENDORS_PAYROLL = "EMPLOYER_PORTAL_VENDORS_PAYROLL",
    EMPLOYER_PORTAL_VENDORS_TRANSACTIONS = "EMPLOYER_PORTAL_VENDORS_TRANSACTIONS",
}

export enum AccessType {
    WRITE = "WRITE",
    READ = "READ",
}