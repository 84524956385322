import React from "react";
import { ReactComponent as LoaderIcon } from "../../images/loader.svg";
import "./styles.css";

export interface TableColumns {
  field: string;
  label: string;
  style?: React.CSSProperties;
  formatter?: (value: any) => any;
}

export interface TableProps {
  loading?: boolean;
  columns: TableColumns[];
  data: any[];
  children?: React.ReactNode;
}

function renderNotDataFoundText(colSpan: number) {
  return (
    <tr>
      <td colSpan={colSpan} style={{ textAlign: "center" }}>
        <h5>No data found</h5>
      </td>
    </tr>
  );
}

function getLoadingIcon(colSpan: number) {
  return (
    <tr>
      <td colSpan={colSpan} style={{ textAlign: "center" }}>
        <LoaderIcon />
      </td>
    </tr>
  );
}

export default function Table({
  loading = false,
  columns,
  data,
}: TableProps) {
  return (
    <div className="table-comp">
      <table className="table table-borderless">
        <thead>
          <tr>
            {columns.map((column) => (
              <th className="align-middle" key={column.field}>{column.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {loading
            ? getLoadingIcon(columns.length + 1)
            : data.map((eachData, index) => (
                <tr key={index}>
                  {columns.map((column) => (
                    <td key={column.field} className="align-middle" style={column.style}>
                      {column.formatter ? column.formatter(eachData[column.field]) : eachData[column.field]}
                    </td>
                  ))}
                </tr>
              ))}
          {!loading &&
            data.length < 1 &&
            renderNotDataFoundText(columns.length + 1)}
        </tbody>
      </table>
    </div>
  );
}