import React, { FunctionComponent } from "react";
import "./styles.scss";

interface SummaryCardProps {
    logoUrl: string;
    label: string
    onButtonClick: () => void;
    buttonLabel: string;
    cardSummary: FunctionComponent;
}

export default function SummaryCard(props: SummaryCardProps) {
    return (
        <div className="summary-card"> 
            <div className="card-logo-wrapper">
            <img src={props.logoUrl} alt="logo" className="logo-img mt-2"/>
            </div>
            <b className="card-label primary-color my-2">{props.label}</b>
            {((Comp:FunctionComponent) => <Comp/>)(props.cardSummary)}            
            <button
                className="card-btn"
                type="button"
                onClick={() => props.onButtonClick()}
            >
                {props.buttonLabel}
            </button>
        </div>
    )
}