import React, {
  ComponentPropsWithoutRef,
  CSSProperties,
  Ref,
  forwardRef,
} from "react";
import { mergeClassNames } from "../helpers/merge-classnames";
import "./styles.css";

export interface OverlayProps extends ComponentPropsWithoutRef<"div"> {
  backgroundColor?: CSSProperties["backgroundColor"];
  opacity?: CSSProperties["opacity"];
  zIndex?: CSSProperties["zIndex"];
  blur?: number;
}

export default function _Overlay(
  {
    backgroundColor = "#000",
    opacity = 0.6,
    zIndex,
    blur,
    className,
    style,
    ...rest
  }: OverlayProps,
  ref: Ref<HTMLDivElement>
) {
  const innerOverlay = (
    <div
      ref={ref}
      className={mergeClassNames("rf-overlay__root", className)}
      style={{ backgroundColor, opacity, zIndex, ...style }}
      {...rest}
    />
  );
  if (blur) {
    return (
      <div
        className="rf-overlay__blur"
        style={{ backdropFilter: `blur(${blur}px)` }}
      >
        {innerOverlay}
      </div>
    );
  }
  return innerOverlay;
}

export const Overlay = forwardRef<HTMLDivElement, OverlayProps>(_Overlay);
