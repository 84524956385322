import { FormErrors } from "../../types";

export function filterErrors<Values>(
  errors: FormErrors<Values>
): FormErrors<Values> {
  if (errors === null || typeof errors !== "object") {
    return {};
  }
  return Object.keys(errors).reduce<FormErrors<Values>>((acc, _key) => {
    const key = _key as keyof FormErrors<Values>;
    const errorValue = errors[key];

    if (
      errorValue !== undefined &&
      errorValue !== null &&
      errorValue !== false
    ) {
      acc[key] = errorValue;
    }

    return acc;
  }, {});
}
