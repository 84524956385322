import React, { Ref, forwardRef } from "react";
import { BaseInput, BaseInputProps } from "../base-input";


export interface NumberInputProps extends BaseInputProps<"input"> {}

function _NumberInput(
  props: NumberInputProps,
  ref: Ref<HTMLInputElement>
) {

  return (
    <BaseInput component="input" ref={ref} {...props} type="number" />
  );
}

export const NumberInput =
  forwardRef<HTMLInputElement, NumberInputProps>(_NumberInput);
