import React, {useState} from "react";
import {EmployerAdminDto} from "../../../dto";
import {TextField} from "../../../components/input-functions";
import {FieldName, NotifierBgColor, NotifierType} from "../../../models";
import {getError, getErrorMsgFromResponse} from "../../../utils";
import Button from "../../../components/button";
import {validateCreateOrEditAdminForm} from "./addOrEditAdmin.validation";
import dispatch from "../../../middleware";
import {useDispatch} from "react-redux";
import {showNotifier} from "../../../actions/ui";
import {createOrEditAdmin, getEmployerAdmins, removeAdmin} from "../../../actions/employee";
import Modal from "../../../components/modal";
import {vendorRoleIds} from "../../../config";

const defaultUserInput = {
    branchLocation: "",
    email: "",
    employerId: "",
    lastActiveAt: "",
    mobile: "",
    name: "",
    roles: [],
    roleIds: [],
    _id: "",
};

interface Roles {name: string; value: string; description: string}

interface AddOrEditAdminProps {
    vendorId?: string | null;
    editValues: EmployerAdminDto | null;
    roles: Roles[]
    employerId: string;
    onFormSubmit: () => void
}

export default function AddOrEditAdmin(props: AddOrEditAdminProps) {
    const [userInput, updateUserInput] = useState<EmployerAdminDto>(props.editValues || defaultUserInput);
    const [error, updateError] = useState({status: false, msg: "", type: ""});
    const [isRemoveUserModalActive, toggleRemoveUserModal] = useState(false);
    const [loading, updateLoading] = useState(false);
    const storeDispatch = useDispatch();

    function handleChange(fieldName: string, value: string | number) {
        updateUserInput((prevState) => {
            return {
                ...prevState,
                [fieldName]: value
            }
        })
    }
    function handleRoleSelect(value: string) {
        updateUserInput((prevState) => {
            return {
                ...prevState,
                roleIds: [value]
            }
        })
    }
    function isEditForm() {
        return !!props.editValues
    }
    async function removeUserFromAdmin() {
        updateLoading(true)
        try {
            await dispatch(
                storeDispatch,
                removeAdmin(userInput._id)
            );
            toggleRemoveUserModal(false)
            dispatch(
                storeDispatch,
                showNotifier(NotifierBgColor.SUCCESS, "User has been removed!", NotifierType.SUCCESS)
            );
            await dispatch(storeDispatch, getEmployerAdmins(props.vendorId))
            props.onFormSubmit()
        } catch (e) {
            dispatch(
                storeDispatch,
                showNotifier(NotifierBgColor.ERROR, getErrorMsgFromResponse(e))
            );
        }
        updateLoading(false)
    }
    async function handleCreateEmployee() {
        const validation = validateCreateOrEditAdminForm(userInput);
        if (validation.status) {
            const successMsg =
                isEditForm() ? "Updated successful!" : `An invitation link has been sent to ${userInput.name}`
            try {
                updateLoading(true)
                const body = { ...userInput };
                body.employerId = props.employerId || "";
                if (props.vendorId) {
                    body.departmentId = props.vendorId
                }
                //@ts-ignore
                if (!isEditForm()) delete body._id;
                await dispatch(
                    storeDispatch,
                    createOrEditAdmin(body, isEditForm(), body._id)
                );
                await dispatch(storeDispatch, getEmployerAdmins(props.vendorId))
                props.onFormSubmit()
                dispatch(
                    storeDispatch,
                    showNotifier(NotifierBgColor.SUCCESS, successMsg, NotifierType.SUCCESS)
                );
            } catch (e) {
                dispatch(
                    storeDispatch,
                    showNotifier(NotifierBgColor.ERROR, getErrorMsgFromResponse(e))
                );
            }
        }
        updateLoading(false)
        updateError(validation);
    }
    function selectedRoleDescription() {
        if (userInput?.roleIds[0]) {
            const selectedRole = props.roles.filter((role) => {
                return role.value === userInput?.roleIds[0]
            })
            return selectedRole[0]?.description
        }
        return undefined;
    }
    function isRoleAllowed(roleId: string) {
        return props.vendorId ? vendorRoleIds.includes(roleId) : !vendorRoleIds.includes(roleId);
    }
    function renderRoleSelections() {
        return (
            <div>
                <div className={"fs-10 m-0 primary-color"}>
                    Role
                </div>
                <div className={"text-center"}>
                    {
                        props.roles.map((role, index) => {
                            //Todo: Temp solution for vms, need better backend support to get the roles
                            if (isRoleAllowed(role.value)) {
                                return (
                                    <label className={"m-2"} key={index}>
                                        <input type="radio"
                                               checked={userInput?.roleIds?.includes(role.value)}
                                               onChange={() => handleRoleSelect(role.value)}
                                        />
                                        <small className={"text-capitalize primary-color mx-2"}>
                                            {role.name}
                                        </small>
                                    </label>
                                )
                            } else return null;
                        })
                    }
                </div>
                {userInput.roleIds?.length > 0 && <div className={"text-center mt-3 bg-lightgray p-3 primary-color rounded"}>
                    <small>
                        {selectedRoleDescription()}
                    </small>
                </div>}
            </div>
        )
    }
    return (
        <div className="form-label-group entry-input-container px-4">
            <p className="primary-color fw-600 my-4">
                {isEditForm() ? "Manage" : "Add User"}
            </p>
            <TextField
                type={"text"}
                valueType={"string"}
                value={userInput.name}
                fieldName={FieldName.NAME}
                onChange={handleChange}
                label={"Full Name"}
                error={getError(FieldName.NAME, error)}
            />
            <TextField
                type={"text"}
                valueType={"string"}
                value={userInput.mobile}
                fieldName={FieldName.MOBILE}
                onChange={handleChange}
                label={"Phone number"}
                error={getError(FieldName.MOBILE, error)}
            />
            <TextField
                type={"text"}
                valueType={"string"}
                value={userInput.email}
                fieldName={FieldName.EMAIL}
                onChange={handleChange}
                label={"Employee's Official Email Id"}
                error={getError(FieldName.EMAIL, error)}
            />
            <TextField
                type={"text"}
                valueType={"string"}
                value={userInput.branchLocation}
                fieldName={FieldName.BRANCH_LOCATION}
                onChange={handleChange}
                label={"Location"}
                error={getError(FieldName.BRANCH_LOCATION, error)}
            />
            <br/>
            {renderRoleSelections()}
            <div className="mt-5">
                <span
                    className={`text-danger float-left mt-2 cursor-pointer ${!isEditForm() && "d-none"}`}
                    onClick={() => toggleRemoveUserModal(true)}
                >
                    Remove user
                </span>
                <Button
                    handleBtnClick={handleCreateEmployee}
                    label={
                        loading ? "Please wait..." : isEditForm() ? "Update" : "Add User"
                    }
                    cls={isEditForm() ? "float-right" : "float-left"}
                    mergeClass
                    disabled={loading}
                />
            </div>
            <Modal
                active={isRemoveUserModalActive}
                cancellable={true}
                onClose={() => toggleRemoveUserModal(false)}
            >
                <div className={"my-5 px-4 primary-color"}>
                    <b>Remove user</b>
                    <div className={"my-4"}>
                        Do you want to remove {userInput.name} ?
                    </div>
                    <Button
                        handleBtnClick={removeUserFromAdmin}
                        parentClass={"float-left"}
                        label={loading ? "Please wait..." : "Yes"}
                        cls={"mr-2"}
                        mergeClass
                        disabled={loading}
                    />
                    <Button
                        handleBtnClick={() => toggleRemoveUserModal(false)}
                        label={"No"}
                        parentClass={"float-left"}
                        cls={"ml-2 btn w-unset secondary-button fs-14 px-5"}
                        mergeClass={false}
                        disabled={loading}
                    />
                </div>
            </Modal>
        </div>
    )
}