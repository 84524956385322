import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { TextField } from '../../components/input-functions'
import { config } from '../../firebase/firebaseUtils'

interface ResetProps {
    loginUrl: string;
    actionCode: any;
}

export default function ResetPassword(props: ResetProps) {
  const [userInput, updateUserInput] = useState({pw: '', reEnterPw: ''});
  const [loading, updateLoading] = useState<boolean>(false);
  const [error, updateError] = useState({msg: "", type: ""})
  const [isValidReset, updateValidStatus] = useState<boolean>(false);
  function handleChange(fieldName: string, value: string | number, valueType: string) {
    updateError({msg: "", type: ""})
    updateUserInput((prevState) => {
        return {
            ...prevState,
            [fieldName]: String(value)
        }
     })
  }
  function handleSubmit(e: any) {
    e.preventDefault();
    if (!isValidReset) {
        updateError({msg: "Invalid reset link", type: "invalidLink"})
    } else if (userInput.pw.trim().length < 6) {
        updateError({msg: "Minimum 6 character password required", type: "pw"})
    } else if (userInput.pw.trim() !== userInput.reEnterPw.trim()) {
      updateError({msg: "Password didn't match", type: "reEnterPw"})
    } else { 
        const auth = config.auth();
        updateLoading(true);
        auth.confirmPasswordReset(props.actionCode, userInput.pw).then(function(resp: any) {
            window.location.replace(props.loginUrl)
        }).catch(function(error: Error) {
            updateLoading(false)
            alert(error.message)
            console.log(error.message)
        })
    }
  }
  useEffect(() => {
    const auth = config.auth();
    auth.verifyPasswordResetCode(props.actionCode).then(function (email: string) {
        updateValidStatus(true)
    }).catch(function (error: Error) {
        updateValidStatus(false)
    })
  }, [])
  return (
    <div className={'d-flex align-items-center'}>
      <div className="container">
        <div className="row no-gutters align-items-center min-vh-100">
          <div className="col-md-6 offset-md-3">
            <form className="card bg-white border-0 form-container py-5 px-3 text-center">
              <div className="upper-part primary-color">
                <Link to={"/"} className="text-left">
                  <h5 className={'primary-color font-weight-bold ml-md-5 mb-5'}>
                    <i className={'fa font-weight-bold fa-angle-left mr-3'} />
                    Password
                  </h5>
                </Link>

                <div className={'text-center mt-4'}>
                  <b className={'primary-color'}>Change Your Password</b>
                </div>

                <div className="text-left px-4 mt-5 pt-3">
                    <TextField label={"New Password"} valueType={"string"} value={userInput.pw}
                        fieldName={"pw"} onChange={handleChange} type={"password"}
                        error={error.type === "pw" ? error.msg : ""}
                    />
                    <TextField label={"Confirm New Password"} valueType={"string"} value={userInput.reEnterPw}
                        fieldName={"reEnterPw"} onChange={handleChange} type={"password"}
                        error={error.type === "reEnterPw" ? error.msg : ""}
                    />
                </div>

                {
                  error.type === "invalidLink" && 
                  <div className="error-msg error-border py-3 m-3">
                    {error.msg}
                  </div>
                }
              </div>

              <div className="lower-part py-4 mb-2 px-3 py-md-4">
                <button
                  className={'btn btn2 btn-block font login-btn-text'}
                  type="submit"
                  disabled={loading}
                  onClick={(e) => handleSubmit(e)}
                >
                  {loading ? 'Please wait...' : 'Submit'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
