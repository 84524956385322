import React from "react";
import "./styles.css";

export enum BadgeType {
    SUCCESS = "success",
    PENDING = "pending",
    REJECT = "reject",
    INFO = "info",
}
interface BadgeProps extends React.HTMLAttributes<HTMLSpanElement> {
  type: BadgeType;
  children?: React.ReactNode;
}

export default function Badge({
  type = BadgeType.INFO,
  children,
  ...props
}: BadgeProps) {
  return (
    <span className={`badge rf-label rf-label--${type}`} {...props}>
      {children}
    </span>
  );
}
