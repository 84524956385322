import React from "react";

interface CheckboxIconProps extends React.ComponentPropsWithoutRef<"svg"> {
  indeterminate?: boolean;
}

export default function CheckboxIcon({
  indeterminate,
  ...rest
}: CheckboxIconProps) {
  if (indeterminate) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 32 6"
        {...rest}
      >
        <rect width="32" height="6" fill="currentColor" rx="3" />
      </svg>
    );
  }
  return (
    <svg
      viewBox="0 0 12 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M11 1.25L4.125 8.125L1 5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
