import React, { CSSProperties, ReactNode } from "react";
import { useId } from "../../../hooks/use-id";
import { mergeClassNames } from "../helpers/merge-classnames";

type GroupOrientation = "horizontal" | "vertical";

const ORIENTATION_TO_FLEX_DIRECTION_MAP: Record<
  GroupOrientation,
  CSSProperties["flexDirection"]
> = {
  horizontal: "row",
  vertical: "column",
};
const ORIENTATION_TO_FLEX_ALIGN_ITEMS_MAP: Record<
  GroupOrientation,
  CSSProperties["alignItems"]
> = {
  horizontal: "center",
  vertical: "flex-start",
};

export interface InlineInputGroupProps {
  label?: ReactNode;
  role?: string;
  className?: string;
  style?: CSSProperties;
  orientation?: GroupOrientation;
  spacing?: CSSProperties["gap"];
  hasAsterisk?: boolean;
  error?: ReactNode;
  children: ReactNode;
}

export function InlineInputGroup({
  label,
  role = "group",
  orientation = "horizontal",
  spacing = "24px",
  className,
  style,
  hasAsterisk = false,
  error,
  children,
}: InlineInputGroupProps) {
  const labelId = useId();

  return (
    <div
      className={mergeClassNames("rf-radio-group__root", className)}
      role={role}
      aria-labelledby={labelId}
      style={style}
    >
      {!!label && (
        <div className="rf-radio-group__label" id={labelId}>
          {label}
          {hasAsterisk && (
            <span className="rf-radio-group__label-asterisk" aria-hidden="true">
              *
            </span>
          )}
        </div>
      )}
      <div
        className="rf-radio-group__container"
        style={{
          flexDirection: ORIENTATION_TO_FLEX_DIRECTION_MAP[orientation],
          alignItems: ORIENTATION_TO_FLEX_ALIGN_ITEMS_MAP[orientation],
          gap: spacing,
        }}
      >
        {children}
      </div>
      {error && <div className="rf-radio-group__error">{error}</div>}
    </div>
  );
}
