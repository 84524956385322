import React, { useEffect, useMemo, useState } from "react";
import {
  dateToInputElemDate,
  downloadExcelFromArrayOfObjects,
  formatDate,
  formatDateInDDMMYYYY,
  getErrorMsgFromResponse,
  getParamFromUrl,
  getTodayDateForInputField,
  isDateOlderThan,
  isEmptyString,
  navigateTo,
} from "../../../utils";
import dispatch from "../../../middleware";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import { NotifierBgColor, RoutePaths, Transaction } from "../../../models";
import { getAllEmployeesTransaction } from "../../../actions/employee";
import FullScreenLoader from "../../../components/fullscreen-loader";
import { showNotifier } from "../../../actions/ui";
import { TransactionStatusDto } from "../../../dto";
import { Select } from "../../../components/input-functions";
import getEffect from "../effect";
import Authorized from "../../../authorization/authorized";
import {
  AccessType,
  Resource,
} from "../../../authorization/authorization.enum";
import { ReduxState } from "../../../reducers";
import isAuthorized from "../../../authorization/authorizationAccess";
import { SearchInput } from "../../../components/library/search-input";
import { toast } from "react-hot-toast";
import "./styles.css";
import { MAX_TRANSACTION_ENTRIES_PER_CALL } from "../../../constants";

const TOAST_DURATION_IN_MS = 3000;

const statusOptions = [
  {
    label: "All",
    value: "ALL",
  },
  {
    label: "Transferred",
    value: TransactionStatusDto.TRANSFERRED,
  },
  {
    label: "Deducted From Salary",
    value: TransactionStatusDto.DEDUCTED_FROM_SALARY,
  },
  {
    label: "Repaid",
    value: TransactionStatusDto.REPAID,
  },
];
const tableStatusConfig: any = {
  [TransactionStatusDto.TRANSFERRED]: {
    className: "success-btn amount-transfered-tab ",
    label: "Transferred",
  },
  [TransactionStatusDto.DEDUCTED_FROM_SALARY]: {
    className: "success-btn amount-deducted-tab ",
    label: "Deducted From Salary",
  },
  [TransactionStatusDto.REPAID]: {
    className: "success-btn amount-repaid-tab",
    label: "Repaid",
  },
};

const TransactionColumn = [
  {
    minWidth: "120px",
    left: true,
    name: "Name",
    selector: "name",
    cell: (row: any) => {
      if (row.employerContractorId) {
        return <span className="fs-12 font-weight-bold">{row.name}</span>;
      }
      const isClickable = Boolean(row.employeeId && row.employerEmployeeId);
      const handleClick = isClickable
        ? () => {
            const vendorId = getParamFromUrl("vendorId");
            const path = vendorId
              ? `/vendors/employeeProfile`
              : RoutePaths.EMPLOYEE_PROFILE;
            let query = `?empId=${row.employeeId}&employerEmployeeId=${row.employerEmployeeId}`;
            if (vendorId) {
              query = `${query}&vendorId=${vendorId}`;
            }
            navigateTo(`${path}${query}`);
          }
        : undefined;

      return (
        <span
          className={`${
            isClickable ? "secondary-color cursor-pointer " : ""
          }fs-12 font-weight-bold`}
          onClick={handleClick}
        >
          {row.name}
        </span>
      );
    },
  },
  {
    minWidth: "120px",
    center: true,
    name: "Worker Id",
    selector: "employerEmployeeId",
    cell: (row: any) => (
      <span className="primary-color fs-12">
        {row.employerEmployeeId ?? row.employerContractorId}
      </span>
    ),
  },
  {
    minWidth: "180px",
    center: true,
    name: "Date & time",
    selector: "date",
    cell: (row: any) => (
      <span className="primary-color fs-12">
        {row.date} | {row.time}
      </span>
    ),
  },
  {
    minWidth: "100px",
    name: "Amount Disbursed",
    center: true,
    selector: "amount",
    cell: (row: any) => (
      <span className="primary-color fs-12">{row.amount}</span>
    ),
  },
  {
    minWidth: "100px",
    name: "Repaid Amount",
    center: true,
    selector: "repaidAmount",
    cell: (row: any) => (
      <span className="primary-color fs-12">{row.repaidAmount ?? "NA"}</span>
    ),
  },
  {
    minWidth: "100px",
    name: "Last Repayment Date",
    center: true,
    selector: "latestRepaymentDate",
    cell: (row: any) => (
      <span className="primary-color fs-12">
        {row.latestRepaymentDate ? row.latestRepaymentDate : "NA"}
      </span>
    ),
  },
  {
    minWidth: "100px",
    name: "Deduction Confirmed",
    center: true,
    selector: "pendingRepaymentCommitmentsAmount",
    cell: (row: any) => (
      <span className="primary-color fs-12">
        {row.pendingRepaymentCommitmentsAmount ?? "NA"}
      </span>
    ),
  },
  {
    minWidth: "100px",
    name: "Pending Amount",
    center: true,
    selector: "pendingAmount",
    cell: (row: any) => (
      <span className="primary-color fs-12">{row.pendingAmount ?? "NA"}</span>
    ),
  },
  {
    minWidth: "100px",
    center: true,
    name: "Status",
    selector: "status",
    cell: (row: any) => (
      <span
        className={
          tableStatusConfig[row.status]?.className || "primary-color fs-12"
        }
      >
        {tableStatusConfig[row.status]?.label || row.status}
      </span>
    ),
  },
];
function formatTransactionData(
  transactions: Transaction[],
  transactionStatus: string
) {
  const formattedData = transactions.map((transaction: Transaction) => {
    const workerName =
      transaction.employeeDataSnapshot?.fullName ??
      transaction.contractorDataSnapshot?.fullName ??
      transaction.userDataSnapshot?.name?.data;
    return {
      date: formatDateInDDMMYYYY(transaction.createdAt),
      time: new Date(transaction.createdAt).toLocaleTimeString(),
      amount: transaction.amount,
      status: transaction.status,
      name: workerName,
      employerEmployeeId: transaction.employeeDataSnapshot?.employerEmployeeId,
      employerContractorId:
        transaction.contractorDataSnapshot?.employersContractorId,
      employeeId: transaction.employeeId,
      repaidAmount: transaction.repaidAmount,
      pendingRepaymentCommitmentsAmount:
        transaction.pendingRepaymentCommitmentsAmount,
      latestRepaymentDate: formatDateInDDMMYYYY(
        transaction.latestRepaymentDate
      ),
      pendingAmount: transaction.pendingRepaymentAmount,
    };
  });

  const statusesToBeFilltered = statusOptions.map((el) => el.value);

  return formattedData.filter((t) => {
    if (transactionStatus === "ALL")
      return statusesToBeFilltered.includes(t.status);
    return t.status === transactionStatus;
  });
}
function validateDate(startDate: Date, endDate: Date) {
  return !isDateOlderThan(2, startDate);
}

function getInitialDateRange(
  startDateString: string | null,
  endDateString: string | null
) {
  let startDate: Date;
  let endDate: Date;

  if (startDateString && endDateString) {
    startDate = new Date(startDateString);
    endDate = new Date(endDateString);
  } else {
    startDate = new Date();
    endDate = new Date();

    startDate.setDate(1);
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);
  }

  return {
    startDate: dateToInputElemDate(startDate),
    endDate: dateToInputElemDate(endDate),
  };
}

export default function Transactions() {
  const storeDispatch = useDispatch();
  useEffect(getEffect("Transactions", useDispatch()), []);
  const vendorId = getParamFromUrl("vendorId");
  const authResource = vendorId
    ? Resource.EMPLOYER_PORTAL_VENDORS_TRANSACTIONS
    : Resource.EMPLOYER_PORTAL_TRANSACTIONS;
  const startDateString = getParamFromUrl("startDate");
  const endDateString = getParamFromUrl("endDate");
  const [dateRange, updateDateRange] = useState(() =>
    getInitialDateRange(startDateString, endDateString)
  );
  const [transactions, updateTransactions] = useState<Transaction[]>([]);
  const [isLoading, updateLoading] = useState<boolean>(false);
  const [transactionStatus, updateTransactionStatus] = useState<string>("ALL");
  const [errorMsg, updateErrorMsg] = useState("");
  const formattedTransactions = useMemo(
    () => formatTransactionData(transactions, transactionStatus),
    [transactions, transactionStatus]
  );
  const resources = useSelector(
    (state: ReduxState) => state.login.userDetails?.resources || {}
  );
  const [employerWorkerId, setEmployerWorkerId] = useState<string>("");

  function handleDateChange(fieldName: string, val: string) {
    updateDateRange((prevState) => {
      return {
        ...prevState,
        [fieldName]: val,
      };
    });
    updateErrorMsg("");
  }
  function handleDownloadClick() {
    if (formattedTransactions.length < 1) return;
    const transactionsArr = formattedTransactions.map((transaction) => ({
      Name: transaction.name || "NA",
      "Worker Id":
        transaction.employerEmployeeId ||
        transaction.employerContractorId ||
        "NA",
      Date: transaction.date,
      Time: transaction.time,
      Amount: transaction.amount,
      "Repaid Amount": transaction.repaidAmount ?? "",
      "Last Repayment Date": transaction.latestRepaymentDate,
      "Deduction Confirmed":
        transaction.pendingRepaymentCommitmentsAmount ?? "",
      "Pending Amount": transaction.pendingAmount ?? "",
      Status: transaction.status,
    }));
    downloadExcelFromArrayOfObjects(
      transactionsArr,
      "transactions-report.xlsx"
    );
  }
  function renderTableHeader() {
    return (
      <div className="d-flex flex-column flex-md-row align-items-center justify-content-between">
        <p className="table-title fw-600 fs-18 m-0">Transactions</p>
        <div className="d-flex align-items-center">
          <Select
            value={transactionStatus}
            options={statusOptions}
            fieldName={"TRANSACTION_STATUS"}
            onChange={(f: string, val: string | number) =>
              updateTransactionStatus(String(val))
            }
          />
          <button
            type="button"
            className="btn outline-btn w-unset fs-12 ml-2 b-color-secondary secondary-color"
            onClick={handleDownloadClick}
            disabled={formattedTransactions.length < 1}
          >
            Quick Download
          </button>
        </div>
      </div>
    );
  }
  async function handleSubmit(e?: React.FormEvent<HTMLFormElement>) {
    if (e) {
      e.preventDefault();
    }
    updateLoading(true);
    try {
      const transactionStartDate = new Date(dateRange.startDate);
      const transactionEndDate = new Date(dateRange.endDate);
      if (validateDate(transactionStartDate, transactionEndDate)) {
        transactionStartDate.setHours(0, 0, 0);
        transactionEndDate.setHours(23, 59, 59, 999);

        const response = await dispatch(
          storeDispatch,
          getAllEmployeesTransaction({
            startDate: transactionStartDate,
            endDate: transactionEndDate,
            employerWorkerId: employerWorkerId.trim(),
            vendorId,
          })
        );
        if (response.transactions) {
          if (
            Array.isArray(response.transactions) &&
            response.transactions.length >= MAX_TRANSACTION_ENTRIES_PER_CALL
          ) {
            toast(
              `Results are limited to ${MAX_TRANSACTION_ENTRIES_PER_CALL} records, To access full report, please connect with Refyne`,
              {
                duration: TOAST_DURATION_IN_MS,
              }
            );
          }
          updateTransactions(response.transactions);
          updateTransactionStatus("ALL");
        }
        updateErrorMsg("");
      } else {
        updateErrorMsg(
          "Invalid date range! Please select a 'From Date' within last 2 years."
        );
      }
    } catch (e) {
      await dispatch(
        storeDispatch,
        showNotifier(NotifierBgColor.ERROR, getErrorMsgFromResponse(e))
      );
    }
    updateLoading(false);
  }

  function renderNotAccessible() {
    return (
      <div className={"d-flex justify-content-center align-items-center"}>
        You aren't authorized to access this page
      </div>
    );
  }

  useEffect(() => {
    if (isAuthorized(resources, authResource, AccessType.READ)) {
      handleSubmit();
    }
  }, []);
  return (
    <div>
      <FullScreenLoader active={isLoading} />
      <Authorized
        resourceName={authResource}
        requiredAccessType={AccessType.READ}
        unAuthorizedView={renderNotAccessible()}
      >
        <>
          <p className="fw-600 fs-20 primary-color">Choose Date</p>
          <form className="row" onSubmit={handleSubmit}>
            <div className="col-md-3">
              <div className="form-signin-label-group">
                <input
                  type="date"
                  id="fromDate"
                  className="form-control input-login"
                  placeholder="From Date"
                  value={dateRange.startDate}
                  onChange={(e) =>
                    handleDateChange("startDate", e.target.value)
                  }
                  max={getTodayDateForInputField()}
                  required
                />
                <label htmlFor="fromDate">From Date</label>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-signin-label-group">
                <input
                  type="date"
                  id="toDate"
                  className="form-control input-login"
                  placeholder="To Date"
                  value={dateRange.endDate}
                  onChange={(e) => handleDateChange("endDate", e.target.value)}
                  min={dateRange.startDate}
                  max={getTodayDateForInputField()}
                  required
                />
                <label htmlFor="toDate">To Date</label>
              </div>
            </div>
            <div className="col-md-4">
              <SearchInput
                placeholder="Search by WorkerId"
                value={employerWorkerId}
                onChange={(e) => setEmployerWorkerId(e.currentTarget.value)}
                className="bg-white"
              />
            </div>

            <div className="col-md-2">
              <button
                type="submit"
                className=" btn primary-button"
                disabled={
                  isEmptyString(dateRange.startDate) ||
                  isEmptyString(dateRange.endDate)
                }
              >
                Search
              </button>
            </div>
            <div className="text-small text-danger col-sm-12">{errorMsg}</div>
            <div className="col-sm-12 mt-3">
              <DataTable
                title={renderTableHeader()}
                pagination={true}
                columns={TransactionColumn}
                data={formattedTransactions}
                className="table-wrapper"
                highlightOnHover
                responsive={true}
              />
            </div>
          </form>
        </>
      </Authorized>
    </div>
  );
}
