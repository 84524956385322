import { ChangeEvent, createContext, useContext } from "react";
import { SwitchSize } from ".";

interface SwitchGroupContextBody {
    value: string[];
    onChange?(event: ChangeEvent<HTMLInputElement>): void;
    size?: SwitchSize;
}

const SwitchGroupContext = createContext<SwitchGroupContextBody | null>(null);
export const SwitchGroupProvider = SwitchGroupContext.Provider;
export const useSwitchGroupContext = () => useContext(SwitchGroupContext);