import React from "react";
import Chart from 'react-apexcharts'

interface BarChartProps {
  xLabel: string,
  yLabel: string,
  xAxisTitleList: string[],
  barChartValueList: number[],
  tooltipTitle?: string,
  chartHeight: number,
  isYAxisTitleVisible: boolean,
  
}

export default function BarChart(props: BarChartProps) {

    const state = {
        options: {
            chart: {
                width: '100%',
                id: 'apexchart-example',
                toolbar: {
                    show: false
                },
            },
            background: '#000',
            xaxis: {
                categories: props.xAxisTitleList,
                lines: {
                    show: false,
                },
                labels: {
                    style: {
                        colors: ["gainsboro", "gainsboro", "gainsboro", "gainsboro", "gainsboro", "gainsboro"],
                        fontSize: '10px',
                        fontWeight: 600,
                        cssClass: 'apexcharts-yaxis-title',
                    },
                },
                title: {
                    text: props.xLabel,
                    rotate: -90,
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: "gainsboro",
                        fontSize: '10px',
                        fontWeight: 600,
                        cssClass: 'apexcharts-yaxis-title',
                    },
                },
            },
            yaxis: {
                show: true,
                labels: {
                    show: props.isYAxisTitleVisible,
                },
                title: {
                    text: props.yLabel,
                    rotate: -90,
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: "gainsboro",
                        fontSize: '10px',
                        fontWeight: 600,
                        cssClass: 'apexcharts-yaxis-title',
                    },
                },
            },
            fill: {
                colors: ['#57DB9C']
            },

            grid: {

                show: false,
            },
            plotOptions: {
                bar: {
                    barHeight: '60%',
                    borderRadius: 4,
                    backgroundColor: "#57DB9C",
                    dataLabels: {
                        position: 'center',
                    },
                }
            },

            dataLabels: {
                enabled: true,
                offsetY: 10,
                style: {
                    fontSize: '10px',
                    colors: ["#15171A"]
                }
            },
        },
        series: [{
            name: props.tooltipTitle,
            data: props.barChartValueList
        }],

    }
    return (
        <Chart options={state.options} series={state.series} type="bar" height={props.chartHeight} />
    )
}
