import React from "react";
import { StatsDto } from "../../../dto";
import Withdraw from "../../../images/withdraw.svg";
import RequestCompleted from "../../../images/request-completed.svg";
import RequestProgress from "../../../images/request-progress.svg";
import Chart from 'react-apexcharts'


interface StatBoxProps {
    stats: StatsDto | null,
}

const statStyle = {
    minHeight: 360,
    width: "100%",
    borderRadius: 8,
}

function renderStats(stat: StatsDto | null) {
    if (!stat) return null
    return (
        <div className="table-title align-items-end bg-white stats-wrapper rounded">
            <div>
                <div className="d-flex justify-content-between mb-5">
                    <div>
                        <p className={"table-title fw-600 fs-18 mb-2"}>Salary Credited</p>
                    </div>
                    {/* <div className="month-wrapper"><p className="m-0 fs-12">August</p></div> */}
                </div>
                <div className="d-flex mb-4">
                    <div className="stats-icon-wrapper mr-3">
                        <img src={Withdraw} alt="withdraw icon" width="20px" />
                    </div>
                    <div>
                        <b className={"table-title fs-28 fw-800"}>{stat.salaryWithdrawalRequests}</b>
                        <br />
                        <p className="fs-12 fw-300 m-0">Total salary withdraw requests</p>
                    </div>
                </div>

                <div className="d-flex mb-4">
                    <div className="stats-icon-wrapper mr-3">
                        <img src={RequestCompleted} alt="withdraw icon" width="20px" />
                    </div>
                    <div>
                        <b className={"table-title fs-28 fw-800"}>{stat.salaryWithdrawalCompleted}</b>
                        <br />
                        <p className="fs-12 fw-300 m-0">Total number of requests completed</p>
                    </div>
                </div>

                <div className="d-flex mb-5">
                    <div className="stats-icon-wrapper mr-3">
                        <img src={RequestProgress} alt="withdraw icon" width="20px" />
                    </div>
                    <div>
                        <b className={"table-title fs-28 fw-800"}>{stat.salaryWithdrawalPending}</b>
                        <br />
                        <p className="fs-12 fw-300 m-0">Total number of requests in progress</p>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default function AllStats(props: StatBoxProps) {
    return (
        <div style={statStyle}>
            {renderStats(props.stats)}
        </div>
    )
}