import React, { Ref, forwardRef } from "react";
import { BaseInput, BaseInputProps } from "../base-input";
import { CaretDownIcon } from "../icon";

export interface SelectItem {
  value: string;
  label?: string;
  disabled?: boolean;
}

export interface NativeSelectProps
  extends Omit<
    BaseInputProps<"select">,
    "component" | "rightSection" | "overrideRightSectionForWarningErrorIcon"
  > {
  placeholder?: string;
  placeholderDisabled?: boolean;
  placeholderHidden?: boolean;
  options?: Array<SelectItem>;
}

function _NativeSelect(
  {
    placeholder,
    placeholderDisabled = false,
    placeholderHidden = false,
    options = [],
    ...restProps
  }: NativeSelectProps,
  ref: Ref<HTMLSelectElement>
) {
  const optionsElem = options.map(({ value, label, disabled = false }) => (
    <option key={value} value={value} disabled={disabled}>
      {label ?? value}
    </option>
  ));

  if (placeholder) {
    optionsElem.unshift(
      <option
        key="placeholder"
        value=""
        disabled={placeholderDisabled}
        hidden={placeholderHidden}
      >
        {placeholder}
      </option>
    );
  }
  return (
    <BaseInput
      component="select"
      ref={ref}
      overrideRightSectionForWarningErrorIcon={false}
      rightSection={<CaretDownIcon size="sm" />}
      {...restProps}
    >
      {optionsElem}
    </BaseInput>
  );
}

export const NativeSelect =
  forwardRef<HTMLSelectElement, NativeSelectProps>(_NativeSelect);
