import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getExternalDashboardLinks } from "../../actions/employer";
import { showNotifier } from "../../actions/ui";
import dispatch from "../../middleware";
import { NotifierBgColor } from "../../models";
import { ReduxState } from "../../reducers";
import { getErrorMsgFromResponse } from "../../utils";

export default function AnalyticsDashboard() {
  const data = useSelector(
    (state: ReduxState) => state.employer.externalDashboardLinks,
  );
  const loading = useSelector((state: ReduxState) => state.employer.loading);

  const storeDispatch = useDispatch();

  useEffect(() => {
    dispatch(storeDispatch, getExternalDashboardLinks()).catch((r) => {
      dispatch(
        storeDispatch,
        showNotifier(NotifierBgColor.ERROR, getErrorMsgFromResponse(r)),
      );
    });
  }, []);

  return (
    <div
      style={{
        display: "grid",
        height: "100%",
        width: "100%",
        gap: "24px",
      }}
    >
      {loading && <span className="spinner-border" role="status" />}
      {!loading && data !== null && data.length === 0 && <div>No data</div>}
      {!loading &&
        data &&
        data.map((d) => {
          return (
            <div
              style={{
                display: "grid",
                gridTemplateRows: "min-content auto",
                gap: "8px",
              }}
            >
              <h5>{d.description}</h5>
              <iframe
                title={d.description}
                style={{
                  minHeight: "500px",
                  height: "100%",
                  width: "100%",
                  border: "none",
                }}
                src={d.embeddedLink}
              />
            </div>
          );
        })}
    </div>
  );
}
