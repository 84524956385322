import React from "react";
import "./styles.css";

interface DataColumn {
  label: string;
  value: string | number;
}

export interface NotificationBoxProps {
  loading?: boolean;
  message?: React.ReactNode;
  messageStyle?: React.CSSProperties;
  description?: React.ReactNode;
  leftBorderColor?: string;
  dataColumns?: DataColumn[];
  actionButtonLabel?: string;
  onActionButtonClick?: () => void;
  actionButtonDisabled?: boolean;
}

export default function NotificationBox({
  loading = false,
  leftBorderColor = "var(--secondary)",
  message,
  messageStyle,
  description,
  dataColumns = [],
  actionButtonLabel,
  onActionButtonClick,
  actionButtonDisabled,
}: NotificationBoxProps) {
  function renderMessage(msg?: React.ReactNode, description?: React.ReactNode) {
    if (!msg) return;
    const descriptionElem = description ? (
      <>
        <br />
        <span className="notification-box__description">{description}</span>
      </>
    ) : undefined;
    return (
      <h4 className="notification-box__message" style={messageStyle}>
        {message}
        {descriptionElem}
      </h4>
    );
  }
  function renderDataColumns(dataColumns: DataColumn[]) {
    if (dataColumns.length < 1) return;
    return (
      <div className="row flex-column flex-md-row notification-box__data-column-container">
        {dataColumns.map(({ label, value }, index) => (
          <div key={index} className="col notification-box__data-column">
            <h5 className="notification-box__data-column--label">{label}</h5>
            <h4 className="notification-box__data-column--value">{value}</h4>
          </div>
        ))}
      </div>
    );
  }
  function renderActionButton(actionLabel?: string) {
    if (!actionLabel) return;
    return (
      <button
        className="btn notification-box__cta-btn"
        onClick={onActionButtonClick}
        disabled={actionButtonDisabled}
      >
        {actionLabel}
      </button>
    );
  }

  if (loading) return null;

  return (
    <div
      className="notification-box"
      style={{ borderLeftColor: leftBorderColor }}
    >
      {renderMessage(message, description)}
      {renderDataColumns(dataColumns)}
      {renderActionButton(actionButtonLabel)}
    </div>
  );
}
