import { combineReducers } from 'redux';
import UIStateReducer, {UIState} from "./ui";
import LoginStateReducer, {LoginState} from "./login";
import PayrollReducer, {PayrollState} from "./payrollReducer";
import employeeReducer, {EmployeeState} from "./employeeReducer";
import AttendanceReducer, {AttendanceState} from "./attendanceReducer";
import leaveReducer, { LeaveState } from './leaveReducer';
import vendorReducer, { VendorState } from './vendorReducer';
import employerReducer, { EmployerState } from './employerReducer';

export interface ReduxState {
    ui: UIState;
    login: LoginState,
    payroll: PayrollState,
    employee: EmployeeState,
    attendance: AttendanceState
    leave: LeaveState,
    vendor: VendorState,
    employer: EmployerState
}

export default combineReducers<ReduxState>({
    ui: UIStateReducer,
    login: LoginStateReducer,
    payroll: PayrollReducer,
    attendance: AttendanceReducer,
    employee: employeeReducer,
    leave: leaveReducer,
    vendor: vendorReducer,
    employer: employerReducer
})