import React from "react";
import {
  Icon,
  CheckCircle,
  CircleNotch,
  Plus,
  CaretLeft,
  CaretRight,
  XCircle,
  Warning,
  CaretDown,
  Funnel,
  SquareHalf,
  MagnifyingGlass,
  Info,
  PencilSimpleLine,
  X,
  CurrencyInr,
} from "phosphor-react";

// IconWrapper - An abstract layer on top of the icon component library

type IconSize = "sm" | "md" | "lg" | "el";
type IconVariant = "regular" | "fill";

interface IconWrapperProps {
  color?: React.CSSProperties["color"];
  size?: IconSize;
  variant?: IconVariant;
  alt?: string;
  ariaHidden?: boolean;
  Icon: Icon;
  children: React.ReactNode;
}

const ICON_SIZE_TO_PIXEL_SIZE_MAP: Record<IconSize, number> = {
  sm: 16,
  md: 20,
  lg: 24,
  el: 72,
};

function IconWrapper({
  color = "currentColor",
  size = "md",
  variant = "regular",
  ariaHidden,
  Icon,
  ...rest
}: IconWrapperProps) {
  return (
    <Icon
      color={color}
      size={ICON_SIZE_TO_PIXEL_SIZE_MAP[size]}
      weight={variant}
      aria-hidden={ariaHidden}
      {...rest}
    />
  );
}

type IconProps = Omit<IconWrapperProps, "Icon" | "children">;
function getIconComponentWrapped(Icon: Icon, children?: React.ReactNode) {
  return (props: IconProps) => (
    <IconWrapper {...props} Icon={Icon} children={children}></IconWrapper>
  );
}

export type { IconSize, IconVariant, IconProps };

// INFO: Import required Icon(s) here and export then with the wrapper;
export const CheckCircleIcon = getIconComponentWrapped(CheckCircle);
export const PlusIcon = getIconComponentWrapped(Plus);
export const CircleNotchAnimateIcon = getIconComponentWrapped(
  CircleNotch,
  <animateTransform
    attributeName="transform"
    attributeType="XML"
    type="rotate"
    dur="2s"
    from="0 0 0"
    to="360 0 0"
    repeatCount="indefinite"
  ></animateTransform>
);
export const CaretLeftIcon = getIconComponentWrapped(CaretLeft);
export const CaretRightIcon = getIconComponentWrapped(CaretRight);
export const XCircleIcon = getIconComponentWrapped(XCircle);
export const WarningIcon = getIconComponentWrapped(Warning);
export const CaretDownIcon = getIconComponentWrapped(CaretDown);
export const FunnelIcon = getIconComponentWrapped(Funnel);
export const SquareHalfIcon = getIconComponentWrapped(SquareHalf);
export const MagnifyingGlassIcon = getIconComponentWrapped(MagnifyingGlass);
export const InfoIcon = getIconComponentWrapped(Info);
export const PencilSimpleLineIcon = getIconComponentWrapped(PencilSimpleLine);
export const CrossIcon = getIconComponentWrapped(X);
export const CurrencyInrIcon = getIconComponentWrapped(CurrencyInr);
