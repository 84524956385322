import {
  downloadProcessedPayroll,
  getStreamingAccountPayrollDetails,
} from "../../actions/payroll";
import dispatch from "../../middleware";
import { DocumentType, StreamingAccountPayrollDetails } from "../../models";

export async function downloadPayrollFileUsingURI(
  storeDispatch: any,
  uri: string
): Promise<string | undefined> {
  const [_, employerAdminId, documentType, documentId] = uri.split("/");
  const resp = await dispatch(
    storeDispatch,
    downloadProcessedPayroll(documentId, employerAdminId, documentType as DocumentType)
  );
  return resp?.b64File;
}

export async function downloadProcessedPayrollFile(
  storeDispatch: any
): Promise<string | undefined> {
  try {
    const payrollDetails: StreamingAccountPayrollDetails | null = await dispatch(
      storeDispatch,
      getStreamingAccountPayrollDetails()
    );
    if (!payrollDetails) return;
    const {
      payrollFile: { processedFileUrl },
    } = payrollDetails;
    if (!processedFileUrl) return;
  
    const b64File = await downloadPayrollFileUsingURI(
      storeDispatch,
      processedFileUrl
    );
  
    return b64File;
  } catch (error) {
    // TODO: Handle error
  }
}
