import { VendorAction } from "../actions/vendor";
import { VendorSummary } from "../dto";

export interface VendorState {
    vendorList: VendorSummary[];
    loading: boolean;
}

const defaultState: VendorState = {
    vendorList: [],
    loading: false,
};

export default function UIStateReducer(state = defaultState, action: any) : VendorState {
    switch (action.type) {
        case VendorAction.REQUEST_VENDOR_LIST:
            return {
                ...state,
                loading: true
            };
        case `${VendorAction.REQUEST_VENDOR_LIST}_SUCCESS`:
            return {
                ...state,
                loading: false,
                vendorList: action.payload,
            };
        case `${VendorAction.REQUEST_VENDOR_LIST}_FAILURE`:
            return {
                ...state,
                loading: false,
                vendorList: [],
            };
        default:
            return state
    }
}