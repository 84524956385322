export enum FileType {
  IMG_PNG = "image/png",
  IMG_JPEG = "image/jpeg",
  APP_PDF = "application/pdf",
  XLSX = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  XLS = "application/vnd.ms-excel",
  CSV = "text/csv",
  DOC = "application/msword",
  DOCX = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  UNKNOWN = "Unknown",
}

export const FILE_TYPE_TO_FILE_EXTENSION_MAP: Record<FileType, string> = {
  [FileType.IMG_PNG]: "png",
  [FileType.IMG_JPEG]: "jpeg",
  [FileType.APP_PDF]: "pdf",
  [FileType.XLS]: "xls",
  [FileType.XLSX]: "xlsx",
  [FileType.CSV]: "csv",
  [FileType.DOC]: "doc",
  [FileType.DOCX]: "docx",
  [FileType.UNKNOWN]: "",
};

export const MAX_QTY_OF_RECENT_ACTIVITY = 10;
export const MAX_MONTHLY_SALARY = 9_999_999;
export const DEFAULT_HTTP_REQUEST_TIMEOUT = 1 * 60 * 1000;
export const ONE_MB_IN_BYTES = 1024 * 1024;

export const MAX_TRANSACTION_ENTRIES_PER_CALL = Number(
  process.env.REACT_APP_MAX_TRANSACTION_ENTRIES_PER_CALL ?? 5000
);

export const FIREBASE_AUTH_MFA_REQUIRED_ERROR_CODE =
  "auth/multi-factor-auth-required";
export const MFA_REQUIRED_CORE_SERVER_ERROR_CODE = "E11023";
