import React, { ReactNode } from "react";
import { CheckboxGroupProvider } from "../checkbox-group.context";

interface CheckboxGroupProps {
  children: ReactNode;
  value?: string[];
  onChange?: (value: string[]) => void;
}

export default function CheckboxGroup({
  value = [],
  onChange,
  children,
}: CheckboxGroupProps) {
  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value: currentItemValue, checked } = event.currentTarget;
    const valueSet = new Set(value);
    if (checked) valueSet.add(currentItemValue);
    else valueSet.delete(currentItemValue);
    onChange?.(Array.from(valueSet));
  }
  return (
    <CheckboxGroupProvider value={{ value, onChange: handleChange }}>
      {children}
    </CheckboxGroupProvider>
  );
}
