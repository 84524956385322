import React, { CSSProperties, forwardRef, ReactNode, Ref } from "react";
import { constructClassName } from "../helpers/construct-classname";
import { mergeClassNames } from "../helpers/merge-classnames";
import "./styles.css";

type TagColor =
  | "alert"
  | "success"
  | "grey"
  | "white"
  | "brand"
  | "highlight"
  | "error";

export interface TagProps {
  color?: TagColor;
  indicatorPosition?: "left" | "right";
  fullWidth?: boolean;
  // TODO: Implement size?
  size?: string;
  rounded?: boolean;
  leftSection?: ReactNode;
  rightSection?: ReactNode;
  textOverflow?: CSSProperties["overflow"];
  children: ReactNode;
}

function _Tag(
  {
    color = "brand",
    indicatorPosition,
    fullWidth,
    size = "md",
    rounded,
    textOverflow,
    leftSection,
    rightSection,
    children,
  }: TagProps,
  ref: Ref<HTMLDivElement>
) {
  return (
    <div
      ref={ref}
      className={mergeClassNames(
        `rf-tag__wrapper rf-tag__color-${color} rf-tag__size-${size}`,
        constructClassName({
          "rf-tag__full-width": !!fullWidth,
          "rf-tag__rounded": !!rounded,
          [`rf-tag__dot-${indicatorPosition}`]: !!indicatorPosition,
        })
      )}
      style={{ overflow: textOverflow }}
    >
      {leftSection && <span className="rf-tag__left">{leftSection}</span>}
      <span className="rf-tag__inner">{children}</span>
      {rightSection && <span className="rf-tag__right">{rightSection}</span>}
    </div>
  );
}

const Tag = forwardRef<HTMLDivElement, TagProps>(_Tag);
export default Tag;
