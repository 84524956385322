import React, {
  ComponentPropsWithoutRef,
  forwardRef,
  ReactNode,
  Ref,
} from "react";
import { useId } from "../../../hooks/use-id";
import { useSwitchGroupContext } from "./switch-group.context";
import "./styles.css";
import { constructClassName } from "../helpers/construct-classname";

export type SwitchSize = "md" | "sm";

export interface SwitchProps
  extends Omit<ComponentPropsWithoutRef<"input">, "size"> {
  label?: React.ReactNode;
  value: string;
  size?: SwitchSize;
  labelPosition?: "left" | "right";
  fullWidth?: boolean;
  thumbIcon?: ReactNode;
  onTrackLabel?: ReactNode;
  offTrackLabel?: ReactNode;
  description?: React.ReactNode;
}

function _Switch(
  {
    label,
    size: _size = "md",
    labelPosition = "right",
    fullWidth = false,
    description,
    thumbIcon,
    onTrackLabel,
    offTrackLabel,
    value,
    checked,
    ...restProps
  }: SwitchProps,
  ref: Ref<HTMLInputElement>
) {
  const _id = useId();
  const context = useSwitchGroupContext();
  const contextProps: Partial<
    Pick<ComponentPropsWithoutRef<"input">, "checked" | "onChange">
  > = context
    ? { checked: context.value.includes(value), onChange: context.onChange }
    : {};
  const size = context?.size ?? _size;
  const _checked = contextProps.checked ?? checked;

  return (
    <div
      className={constructClassName(
        `rf-switch__wrapper rf-switch__wrapper-size-${size}`,
        { "full-width": fullWidth }
      )}
    >
      <div
        className={`rf-switch__inner rf-switch__inner-position-${labelPosition}`}
      >
        <input
          ref={ref}
          type="checkbox"
          className="rf-switch__input"
          id={_id}
          value={value}
          checked={checked}
          {...restProps}
          {...contextProps}
        />
        <label htmlFor={_id} className="rf-switch__track">
          <div className="rf-switch__thumb">{thumbIcon}</div>
          <div className="rf-switch__track-label">
            {_checked ? onTrackLabel : offTrackLabel}
          </div>
        </label>
      </div>
      {label && (
        <div
          className={`rf-switch__label-wrapper rf-switch__label-wrapper-position-${labelPosition}`}
        >
          <label htmlFor={_id} className="rf-switch__label">
            {label}
          </label>
          {description && (
            <span className="rf-switch__label-description">{description}</span>
          )}
        </div>
      )}
    </div>
  );
}

export const Switch = forwardRef<HTMLInputElement, SwitchProps>(_Switch);
