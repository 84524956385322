import { useState, useEffect } from "react";

export function useDebounce<T>(
  value: T,
  delay: number,
  callback?: (value: T) => void
) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = window.setTimeout(() => {
      setDebouncedValue(value);
      callback?.(value);
    }, delay);
    return () => {
      window.clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
}
