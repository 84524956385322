import React, { useState } from "react";
import { useEffect } from "react";
import "./styles.css";
import dispatch from "../../middleware";
import { useDispatch, useSelector } from "react-redux";
import { hideNotifier } from "../../actions/ui";
import { ReduxState } from "../../reducers";
import Modal from "../modal";
import CheckImg from "../../images/check.png";
import { NotifierType } from "../../models";
import { ReactComponent as ExclamationIcon } from "../../images/exclamation.svg";

let timeOut: ReturnType<typeof setTimeout>;
let bgColor = "";

export default function Notifier() {
  const storeDispatch = useDispatch();
  const [isModalActive, toggleModalActive] = useState<boolean>(false);
  const notifierDetails = useSelector(
    (state: ReduxState) => state.ui.notifierDetails
  );
  bgColor = notifierDetails.bgColor !== "" ? notifierDetails.bgColor : bgColor;
  useEffect(() => {
    const autoClose = notifierDetails.autoClose ?? true;
    if (notifierDetails.msg !== "") {
      toggleModalActive(true);
      clearTimeout(timeOut);
      if (autoClose) {
        timeOut = setTimeout(() => {
          dispatch(storeDispatch, hideNotifier());
        }, 5000);
      }
    } else {
      toggleModalActive(false);
    }
  }, [notifierDetails.msg]);

  return (
    <Modal
      active={isModalActive}
      cancellable
      onClose={() => dispatch(storeDispatch, hideNotifier())}
    >
      <div className="rf-notifier">
        <div className="rf-notifier__icon--container">
          {notifierDetails.type === NotifierType.ERROR && (
            <figure className="rf-notifier__icon--wrapper">
              <ExclamationIcon className="rf-notifier__icon" />
            </figure>
          )}
          {notifierDetails.type === NotifierType.SUCCESS && (
            <img src={CheckImg} alt="check-img" />
          )}
        </div>
        <div>
          {notifierDetails.title && (
            <h4 className="rf-notifier__title">{notifierDetails.title}</h4>
          )}
          <p
            className="rf-notifier__message"
            style={{ color: notifierDetails.bgColor }}
          >
            {notifierDetails.msg}
          </p>
          {notifierDetails.actionButtonLabel ? (
            <button
              className="btn btn-primary"
              onClick={notifierDetails.onActionButtonClick}
            >
              {notifierDetails.actionButtonLabel}
            </button>
          ) : (
            <button
              className="btn rf-notifier__btn"
              onClick={() => dispatch(storeDispatch, hideNotifier())}
            >
              OK
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
}
