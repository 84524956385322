import React, {
  cloneElement,
  ReactElement,
  ReactNode,
  useCallback,
  useRef,
} from "react";
import { useOnClickOutside } from "../../../hooks/use-on-click-outside";
import { useOnFocusOutside } from "../../../hooks/use-on-focus-outside";
import { constructClassName } from "../helpers/construct-classname";
import "./styles.css";

type PopoverPosition = "bottom-right" | "bottom-center";

interface PopoverProps {
  active?: boolean;
  triggerElement: ReactElement;
  children: ReactNode;
  position?: PopoverPosition;
  onToggle?: (state?: boolean) => void;
}

export function Popover({
  active = false,
  triggerElement,
  position = "bottom-right",
  children,
  onToggle,
}: PopoverProps) {
  if (triggerElement.type === React.Fragment)
    throw new Error("Fragment can't be a Trigger Element for Popover");

  const dropDownRef = useRef<HTMLDivElement | null>(null);
  const targetRef = useRef<HTMLElement | null>(null);

  const handleOutsideClickOrFocus = useCallback((e: MouseEvent | TouchEvent | FocusEvent) => {
    if (!targetRef.current || targetRef.current.contains(e.target as Node)) return;
    onToggle?.(false);
  }, []);
  useOnClickOutside(dropDownRef, handleOutsideClickOrFocus);
  useOnFocusOutside(dropDownRef, handleOutsideClickOrFocus)

  return (
    <div className="popover__wrapper">
      {cloneElement(triggerElement, { ref: targetRef, onClick: onToggle })}
      {active && (
        <div
          ref={dropDownRef}
          className={constructClassName("popover-dropdown", {
            [`popover-dropdown-loc-${position}`]: !!position,
          })}
        >
          {children}
        </div>
      )}
    </div>
  );
}
