import React from "react";
import { NotificationBoxProps } from "../../../../components/notification-box";
import { PayrollStatusDto } from "../../../../dto";
import {
  PayrollInfo,
  RoutePaths,
  StreamingAccountPayrollDetails,
} from "../../../../models";
import { formatDateInDDMMYYYY, formatInr, navigateTo } from "../../../../utils";
import { getStreamingPayrollUploadPath, handleDownloadeportClick } from "../helpers";

export function getNotificationBoxMessageAndActionProps(
  payrollStatus: PayrollStatusDto,
  monthText?: string,
  hasWriteAccess: boolean = false
) {
  const statusesWhenCtaIsFinalizePayroll = [
    PayrollStatusDto.PROCESSED,
  ];
  const statusesWhenCtaIsExecutePayroll = [PayrollStatusDto.PAYROLL_FROZEN];
  const statusesWhenCtaIsViewStatus = [
    PayrollStatusDto.DISBURSAL_INITIATED,
    PayrollStatusDto.DISBURSAL_IN_PROGRESS,
    PayrollStatusDto.DISBURSAL_COMPLETED,
  ];

  if (statusesWhenCtaIsFinalizePayroll.includes(payrollStatus)) {
    return {
      message: (
        <>
          <b>Run Payroll</b>, {monthText} is pending.
        </>
      ),
      actionButtonLabel: "Finalize Payroll",
      actionButtonDisabled: !hasWriteAccess,
      onActionButtonClick: () => {
        navigateTo(RoutePaths.PAYROLL_UPLOAD_SUMMARY);
      },
    };
  }
  if (statusesWhenCtaIsExecutePayroll.includes(payrollStatus)) {
    return {
      message: (
        <>
          <b>Run Payroll</b>, {monthText} is pending.
        </>
      ),
      actionButtonLabel: "Execute payroll",
      actionButtonDisabled: !hasWriteAccess,
      onActionButtonClick: () => {
        navigateTo(RoutePaths.PAYROLL_UPLOAD_SUMMARY);
      },
    };
  }
  if (statusesWhenCtaIsViewStatus.includes(payrollStatus)) {
    return {
      message: (
        <>
          <b>Run Payroll</b>, {monthText} has been executed.
        </>
      ),
      actionButtonLabel: "View Status",
      onActionButtonClick: () => {
        navigateTo(`${RoutePaths.PAYROLL_SUMMARY}?month=${monthText}`);
      },
    };
  }
  if (payrollStatus === PayrollStatusDto.DISBURSAL_FAILED) {
    return {
      message: "Payroll Disbursal Failed",
      actionButtonLabel: "View Status",
      onActionButtonClick: () => {
        navigateTo(`${RoutePaths.PAYROLL_SUMMARY}?month=${monthText}`);
      },
    };
  }
}

function getDataColumnProps(object: Record<string, string | number>) {
  return Object.keys(object).map((key) => ({
    label: key,
    value: object[key],
  }));
}

export function getNotificationBoxProps(props: {
  payrollInfo: PayrollInfo | null;
  payrollDetails: StreamingAccountPayrollDetails | null;
  hasWriteAccess?: boolean;
  storeDispatch?: any;
}): NotificationBoxProps {
  const {
    payrollInfo,
    payrollDetails,
    hasWriteAccess = false,
    storeDispatch,
  } = props;

  const isCurrentPayrollActive =
    !!payrollInfo && payrollInfo.isCurrentPayrollActive;

  const NOTIFICATION_BOX_PROPS_WITH_UPLOAD_CTA = {
    message: "Click on upload payroll button to upload your payroll sheet",
    actionButtonLabel: "Upload Payroll",
    actionButtonDisabled: !hasWriteAccess,
    onActionButtonClick: () =>
      navigateTo(
        getStreamingPayrollUploadPath(
          payrollInfo?.monthForWhichPayrollWillBeExecuted
        )
      ),
  };

  const NOTIFICATION_BOX_PROPS_PAYROLL_EXPIRED = {
    message: "Your payroll got expired, since no action was taken",
  };

  if (!payrollDetails) {
    if (!isCurrentPayrollActive) {
      return NOTIFICATION_BOX_PROPS_PAYROLL_EXPIRED;
    }
    return NOTIFICATION_BOX_PROPS_WITH_UPLOAD_CTA;
  }
  if (isCurrentPayrollActive && payrollDetails) {
    if (
      [PayrollStatusDto.UPLOADED, PayrollStatusDto.PROCESSING].includes(
        payrollDetails.status
      )
    ) {
      return {
        message: "Please wait, We are still processing your payroll sheet.",
      };
    }
    if (payrollDetails.status === PayrollStatusDto.PAYROLL_PROCESSING_FAILED) {
      return {
        ...NOTIFICATION_BOX_PROPS_WITH_UPLOAD_CTA,
        message:
          "Payroll processing failed, Click on upload payroll button to re-upload.",
        description: payrollDetails.payrollFile?.processedFileUrl ? (
          <>
            <button
              className="btn btn-link p-0"
              onClick={handleDownloadeportClick(
                storeDispatch,
                payrollDetails.payrollFile.processedFileUrl,
                "PAYROLL_PROCESSED_SHEET"
              )}
            >
              Click here
            </button>{" "}
            to download a copy of the previously uploaded payroll sheet with the
            errors highlighted.
          </>
        ) : undefined,
      };
    }
    const {
      totalNumberOfEmployeeEntries,
      completePayrollPayoutBreakup,
      workingDays,
      uploadDate,
    } = payrollDetails;
    return {
      messageStyle: {
        fontSize: "16px",
        fontWeight: 600,
      },
      dataColumns: getDataColumnProps({
        "Payment Date": formatDateInDDMMYYYY(uploadDate),
        "Total Employees": totalNumberOfEmployeeEntries,
        "Working Days": workingDays || "-",
        "Net Pay": formatInr(
          completePayrollPayoutBreakup?.totalBalanceRequired
        ),
      }),
      ...getNotificationBoxMessageAndActionProps(
        payrollDetails.status,
        payrollInfo?.monthForWhichPayrollWillBeExecuted,
        hasWriteAccess
      ),
    };
  }
  if (!isCurrentPayrollActive && payrollDetails) {
    const disbursalStatuses = [
      PayrollStatusDto.DISBURSAL_INITIATED,
      PayrollStatusDto.DISBURSAL_IN_PROGRESS,
      PayrollStatusDto.DISBURSAL_COMPLETED,
      PayrollStatusDto.DISBURSAL_FAILED,
    ];
    if (disbursalStatuses.includes(payrollDetails.status)) {
      const {
        totalNumberOfEmployeeEntries,
        completePayrollPayoutBreakup,
        uploadDate,
        workingDays,
      } = payrollDetails;
      return {
        messageStyle: {
          fontSize: "16px",
          fontWeight: 600,
        },
        dataColumns: getDataColumnProps({
          "Payment Date": formatDateInDDMMYYYY(uploadDate),
          "Total Employees": totalNumberOfEmployeeEntries,
          "Working Days": workingDays || "-",
          "Net Pay": formatInr(
            completePayrollPayoutBreakup?.totalEmployeePayoutAmount
          ),
        }),
        ...getNotificationBoxMessageAndActionProps(
          payrollDetails.status,
          payrollInfo?.monthForWhichPayrollWillBeExecuted,
          hasWriteAccess
        ),
      };
    }
  }
  return NOTIFICATION_BOX_PROPS_PAYROLL_EXPIRED;
}
