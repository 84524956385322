import React from "react";

interface LoaderProps {
    active: boolean;
    opacity?: string;
}

export default function FullScreenLoader(props: LoaderProps) {
    return props.active ?
    <div className={"text-center loader-container"} style={{opacity: props.opacity || "0.7"}}>
        <div>
            <span className="spinner-border" role="status"/>
        </div>
    </div> : <div/>


}