import React, {
  cloneElement,
  isValidElement,
  ReactNode,
  useRef,
  useState,
} from "react";
import {
  useFloating,
  useHover,
  useFocus,
  useInteractions,
  offset,
  flip,
  shift,
  arrow,
} from "@floating-ui/react-dom-interactions";
import { useId } from "../../../hooks/use-id";
import "./styles.css";

export type TooltipPosition =
  | "top"
  | "top-start"
  | "top-end"
  | "right"
  | "right-start"
  | "right-end"
  | "bottom"
  | "bottom-start"
  | "bottom-end"
  | "left"
  | "left-start"
  | "left-end";

function getPositionFromTooltipPosition(position: TooltipPosition) {
  return position.split("-")[0] as "top" | "right" | "bottom" | "left";
}

const ARROW_STATIC_SIDE_MAP = {
  top: "bottom",
  right: "left",
  bottom: "top",
  left: "right",
};

export interface TooltipProps {
  label: ReactNode;
  position?: TooltipPosition;
  children: ReactNode;
}

// INFO: Very basic implementation for current use case
// Will improve later
export function Tooltip({
  label,
  position = "top",
  children,
}: TooltipProps) {
  if (!isValidElement(children)) {
    throw new Error("Children must be a valid ReactElement");
  }
  if (children.type === React.Fragment) {
    throw new Error("Children of Tooltip can't be a Fragment");
  }

  const arrowRef = useRef<HTMLDivElement | null>(null);
  const [open, onOpenChange] = useState(false);

  const { x, y, reference, floating, strategy, context, middlewareData } =
    useFloating({
      open,
      onOpenChange,
      placement: position,
      middleware: [
        offset(12),
        flip(),
        shift({ padding: 8 }),
        arrow({ element: arrowRef, padding: 4 }),
      ],
    });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context),
    useFocus(context),
  ]);

  const _id = useId();

  return (
    <>
      {open && (
        <div
          id={_id}
          ref={floating}
          className="rf-tooltip__container"
          style={{
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
          }}
          role="tooltip"
          {...getFloatingProps()}
        >
          {label}
          <div
            ref={arrowRef}
            className="rf-tooltip__arrow"
            style={{
              left: middlewareData.arrow?.x ?? undefined,
              top: middlewareData.arrow?.y ?? undefined,
              [ARROW_STATIC_SIDE_MAP[getPositionFromTooltipPosition(position)]]:
                -4,
            }}
          ></div>
        </div>
      )}
      {/* TODO: Use merge ref */}
      {cloneElement(children, {
        ref: reference,
        "aria-describedby": _id,
        ...getReferenceProps(),
      } as any)}
    </>
  );
}
