import React from "react";
import "./styles.css";

export interface DataFieldProps {
  label: string;
  value: string | number;
  Icon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  onValueClick?: () => void;
}

export default function DataField({
  label,
  value,
  Icon,
  onValueClick,
}: DataFieldProps) {
  return (
    <div className="data-field">
      {Icon && <Icon className="data-field--icon" />}
      <div>
        <h5 className="data-field--label">{label}</h5>
        <h4
          className={`data-field--value${onValueClick ? " clickable" : ""}`}
          onClick={onValueClick}
        >
          {value}
        </h4>
      </div>
    </div>
  );
}
