import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from "react-redux";
import store from "./store";
import App from './app';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Env, env } from './config';

if (env === Env.PROD) {
    Sentry.init({
        dsn: "https://d848d53edc79417c817bde125df2e49d@o451541.ingest.sentry.io/5960074",
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 1.0,
        release: process.env.REACT_APP_RELEASE_NAME || "employer-web-prod",
        environment: env,
    });
}

ReactDOM.render(
    <Provider store={store({})}>
        <App/>
    </Provider>
    , document.getElementById('root'));
