import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { logout } from "../../actions/login";
import AnalyticsEvent from "../../analytics/events";
import { logEventInFirebase } from "../../analytics/firebase.analytics";
import {EmployerDetails, RoutePaths} from "../../models";
import { ReduxState } from "../../reducers";
import {navigateTo} from "../../utils";

function renderEmployerDetails(details: EmployerDetails) {

    if (!details) return null;
    if (details.name) return (<span className="fs-12 mx-2 white-color">
        {details.name.slice(0, 13).toUpperCase()}
        {
            details.name.length > 13 ? "..." : ""
        }
    </span>
    )
    return null;
}

export default function Toggler() {
    const [isDropdownOpen, updateDropdownStatus] = useState<boolean>(false);
    const node: any = useRef();
    const employerDetails: EmployerDetails = useSelector((state: ReduxState) => state.employer.employerDetails)
    const employerAdminId = useSelector((state: ReduxState) => state.login.userDetails._id);
    const screenName: string = useSelector((state: ReduxState) => state.ui.screenName)

    function handleClick(e: any) {
        if (node.current.contains(e.target)) {
          return;
        }
        updateDropdownStatus(!isDropdownOpen)
    }
    function handleClickRoute(path: string) {
        navigateTo(path)
        updateDropdownStatus(false)
    }
    function handleLogout() {
        // TODO: Need to check if this works or not because logout() call will reload the page
        logEventInFirebase(AnalyticsEvent.LOGOUT_OPTION_CLICKED);
        logout();
    }
    useEffect(() => {
        if (isDropdownOpen) {
            document.addEventListener("mousedown", handleClick);
        } else {
            document.removeEventListener("mousedown", handleClick);
        }
        return () => {
            document.removeEventListener("mousedown", handleClick);
        }
      }, [isDropdownOpen]);
    return (
        <div className="primary-color secondary-bg toggler d-flex align-items-center justify-content-between">
            <p className="page-title m-0">{screenName}</p>
            <span className="dropdown-options" ref={node}>
                <span className={"cursor-pointer d-flex align-items-center"} onClick={() => updateDropdownStatus(!isDropdownOpen)}>
                    <span className="name-circle-toggler">
                        {employerDetails && employerDetails.name && employerDetails.name.charAt(0).toUpperCase()}
                    </span>
                    <span>
                        <span className="d-none d-md-inline">{renderEmployerDetails(employerDetails)}</span>
                        <i className={`mx-3 text-white fa fa-angle-${isDropdownOpen ? 'up' : 'down'}`} aria-hidden="true"/>
                    </span>
                </span>

                {isDropdownOpen && <div className="dropdown-box">
                    <ul>
                        <li onClick={() => handleClickRoute(RoutePaths.PROFILE)}>
                            <i className="fa fa-user mb-3 mr-2" aria-hidden="true"/>
                            Profile
                        </li>
                        <li onClick={handleLogout}>
                            <i className="fa fa-arrow-circle-left mr-2" aria-hidden="true"/>
                            Logout
                        </li>
                    </ul>
                </div>}
            </span>
        </div>
    )
}