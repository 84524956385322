import React from "react";
import {AdminTodo} from "../../../dto";
import {RoutePaths} from "../../../models";
import {navigateTo} from "../../../utils";

interface TodoProps {
    todoList: AdminTodo[] | null
}

const actionTypeToText: any = {
    ATTENDANCE_UPLOAD: {
        label: "Upload Attendance",
        route: RoutePaths.ATTENDANCE
    },
    ADD_EMPLOYEES_BULK_UPLOAD: {
        label: "Upload Employee in Bulk",
        route: RoutePaths.EMPLOYEES,
    },
    PAYROLL_UPLOAD: {
        route: RoutePaths.PAYROLL,
        label: "Upload Payroll"
    }

}

const TodoBoxStyles: any = {
    height: 423,
    width: "100%",
    overflowX: "hidden",
    overflowY: "scroll",
    borderRadius: 8,
    border: "20px solid white",
    borderBottom: "30px solid white",
}

function renderTodoList(todoList: AdminTodo[] | null) {
    if (!todoList) return <div className="text-center p-4 text-secondary">Loading your todo...</div>
    if (todoList.length === 0) return <div className="text-center p-4 text-secondary">No todo available</div>
    return todoList.map((val, index) => {
        return (
            <div className="my-3" key={index} style={{fontSize: 14, overflow: "hidden"}}
                 onClick={() => navigateTo(actionTypeToText[val.title].route)}
            >
                <div className={"outer-circle-todo"}>
                    <div className={"inner-circle-todo"}>

                    </div>
                </div>
                <div className="table-title todo-text cursor-pointer">
                    {actionTypeToText[val.title].label}
                </div>
            </div>
        )
    })
}

export default function Todo(props: TodoProps) {

    return (
        <div className={"bg-white p-1 mt-3"} style={TodoBoxStyles}>
            <p className={"table-title fw-600 fs-18"}>To Do List</p>
            {renderTodoList(props.todoList)}
        </div>
    )
}