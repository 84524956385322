import React, {
  ComponentPropsWithoutRef,
  ComponentPropsWithRef,
  ElementType,
  Ref,
  forwardRef,
  ReactNode,
} from "react";
import { useId } from "../../../hooks/use-id";
import { constructClassName } from "../helpers/construct-classname";
import {
  CheckCircleIcon,
  CircleNotchAnimateIcon,
  WarningIcon,
  XCircleIcon,
} from "../icon";
import { IconButton } from "../icon-button";
import "./styles.css";

// INFO: It's a Polymorphic Components with a support on <input> & <select>
// TODO: Modularize Polymorphic Component types

type PolymorphicComponentRef<C extends ElementType> =
  ComponentPropsWithRef<C>["ref"];
type PolymorphicComponentProps<C extends ElementType, Props = {}> =
  ComponentPropsWithoutRef<C> & { component?: C } & Props;

interface BaseInputSharedProps {
  label?: string;
  required?: boolean;
  leftSection?: ReactNode;
  rightSection?: ReactNode;
  overrideRightSectionForWarningErrorIcon?: boolean;
  disabled?: boolean;
  success?: boolean;
  hint?: ReactNode;
  warning?: ReactNode;
  error?: ReactNode;
  hasClearButton?: boolean;
  onClear?: () => void;
  loading?: boolean;
}

function renderHintSection(context: {
  inputDescribedById: string;
  hint?: string;
  warning?: string;
  error?: string;
}) {
  const { inputDescribedById, hint, warning, error } = context;
  const value = error || warning || hint;
  if (value) {
    return (
      <div
        id={inputDescribedById}
        className={constructClassName({
          "rf-input__error": !!error,
          "rf-input__warning": !!warning,
          "rf-input__hint": !!hint,
        })}
        role="alert"
      >
        {value}
      </div>
    );
  }
}

function renderRightSection(context: {
  loading: boolean;
  overrideRightSectionForWarningErrorIcon: boolean;
  hasClearButton: boolean;
  onClear?: () => void;
  hasValue: boolean;
  isSuccess: boolean;
  isDisabled: boolean;
  isWarning: boolean;
  isError: boolean;
  rightSection?: ReactNode;
}) {
  const {
    loading,
    hasClearButton,
    overrideRightSectionForWarningErrorIcon,
    onClear,
    hasValue,
    isDisabled,
    isSuccess,
    isError,
    isWarning,
    rightSection,
  } = context;
  if (loading)
    return (
      <CircleNotchAnimateIcon
        size="md"
        color="var(--clr-brand-default)"
        alt="Loading"
      />
    );
  if (!overrideRightSectionForWarningErrorIcon) return rightSection;
  if (isWarning) return <WarningIcon size="md" alt="Has warning" />;
  if (hasClearButton && !!onClear && hasValue && !isDisabled) {
    return (
      <IconButton
        title="Clear"
        spacing="no-padding"
        type="button"
        color={isError ? "danger" : undefined}
        onClick={onClear}
      >
        <XCircleIcon size="md" ariaHidden />
      </IconButton>
    );
  }
  if (isError) return <XCircleIcon size="md" alt="Has Error" />;
  if (isSuccess)
    return (
      <CheckCircleIcon
        size="md"
        alt="Valid"
        variant="fill"
        color="var(--clr-info-success-default)"
      />
    );
  return rightSection;
}

export type BaseInputProps<C extends ElementType> = PolymorphicComponentProps<
  C,
  BaseInputSharedProps
>;

export default function _BaseInput<C extends ElementType = "input">(
  {
    id,
    label,
    value,
    component,
    required = false,
    leftSection,
    rightSection,
    overrideRightSectionForWarningErrorIcon = true,
    hint,
    warning,
    error,
    success = false,
    disabled = false,
    loading = false,
    hasClearButton = false,
    onClear,
    className,
    ...rest
  }: BaseInputProps<C>,
  ref: Ref<PolymorphicComponentRef<C>>
) {
  const _id = useId(id);

  const inputDescribedById = `${_id}_hint`;

  const rightSectionNode = renderRightSection({
    loading,
    rightSection,
    overrideRightSectionForWarningErrorIcon,
    hasClearButton,
    onClear,
    hasValue: !!value,
    isDisabled: disabled,
    isError: !!error,
    isWarning: !!warning,
    isSuccess: !!success,
  });

  const Component = component || "input";

  return (
    <div
      className={constructClassName("rf-input__wrapper", {
        error: !!error,
        warning: !!warning,
      })}
    >
      {!!label && <label htmlFor={_id} className="rf-input__label">
        {label}
        {required && (
          <span className="rf-input__label-required" aria-hidden="true">
            {" "}
            *
          </span>
        )}
      </label>}
      <div className="rf-input__container">
        {leftSection && (
          <div className="rf-input__container__left-section">{leftSection}</div>
        )}
        <Component
          ref={ref}
          id={_id}
          {...rest}
          className={constructClassName("rf-input__container__input", {
            [className]: !!className,
            "left-section": !!leftSection,
            "right-section": !!rightSectionNode,
          })}
          value={value}
          disabled={disabled || loading}
          aria-invalid={error ? "true" : "false"}
          aria-describedby={inputDescribedById}
        />
        {rightSectionNode && (
          <div className="rf-input__container__right-section">
            {rightSectionNode}
          </div>
        )}
      </div>
      {renderHintSection({ inputDescribedById, error, warning, hint })}
    </div>
  );
}

type BaseInputComponent = <C extends React.ElementType = "input">(
  props: BaseInputProps<C> & { ref?: PolymorphicComponentRef<C> }
) => React.ReactElement | null;

export const BaseInput: BaseInputComponent = forwardRef(_BaseInput);
