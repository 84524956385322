import React, { useEffect, useState } from "react";
import {
  checkForAlreadyLogin,
  clearFirebaseResponse,
  login,
  register,
  sendResetPwEmail,
} from "../../actions/login";
import { FieldName, IValidation } from "../../models";
import Logo from "../../images/logo-dark.svg";
import AuthenticationVector from "../../images/authentication.svg";
import {
  renderEmail,
  renderFirebaseResponse,
  renderForgetPassword,
  renderPassword,
  renderSignUpText,
  renderSubmit,
  validateLogin,
  renderWelcomeText,
} from "./utils";
import "../../app/styles.css";
import "./styles.css";
import "../../app/floating-labels.scss";
import { useDispatch, useSelector } from "react-redux";
import { ReduxState } from "../../reducers";
import dispatch from "../../middleware";
import { isEmail } from "../../utils";
import FullScreenLoader from "../../components/fullscreen-loader";
import { AuthUser } from "../../auth/auth";
import firebase from "firebase/app";

export interface IFirebaseResponse {
  type: string;
  response: {
    code: string;
    message: string;
  };
}

export interface ILoginFields {
  email: string;
  password: string;
  reEnteredPw: string;
}

function Login(props: any) {
  const storeDispatch = useDispatch();
  const urlParams = new URLSearchParams(
    `?${window.location.href.split("?")[1]}`
  );
  const [userInput, setUserInput] = useState<ILoginFields>({
    email: "",
    password: "",
    reEnteredPw: "",
  });
  const loading: boolean = useSelector(
    (state: ReduxState) => state.login.loading
  );

  const [isPasswordVisible, togglePasswordVisiblity] = useState<boolean>(false);

  const [isConfirmPasswordVisible, toggleConfirnPasswordVisiblity] =
    useState<boolean>(false);

  const [isSignIn, updateIsSignIn] = useState<boolean>(
    urlParams.get("signIn") === "true"
  );
  const [error, setError] = useState({ type: "", msg: "" });
  const firebaseResponse: IFirebaseResponse = useSelector(
    (state: ReduxState) => state.login.firebaseResponse
  );
  function handleChange(fieldName: string, value: string | number) {
    setUserInput((prevState) => {
      return {
        ...prevState,
        [fieldName]: String(value),
      };
    });
    setError({ type: "", msg: "" });
    if (firebaseResponse.type !== "")
      dispatch(storeDispatch, clearFirebaseResponse());
  }
  function handleSubmit(e: any) {
    e.preventDefault();
    const validation: IValidation = validateLogin(
      userInput.email,
      userInput.password,
      userInput.reEnteredPw,
      isSignIn
    );
    if (validation.status) {
      if (isSignIn) {
        login(userInput.email, userInput.password, storeDispatch);
      } else {
        register(userInput.email, userInput.password, storeDispatch);
      }
    }
    setError({ type: validation.type, msg: validation.msg });
  }
  function handleForgotPassword() {
    if (isEmail(userInput.email)) {
      sendResetPwEmail(userInput.email, storeDispatch);
    } else {
      setError({ type: FieldName.EMAIL, msg: "Please enter your email" });
    }
  }
  useEffect(() => {
    if (firebaseResponse.type !== "") {
      dispatch(storeDispatch, clearFirebaseResponse());
    }
  }, [isSignIn]);
  useEffect(() => {
    checkForAlreadyLogin(storeDispatch);
  }, []);
  useEffect(() => {
    if (firebaseResponse.type === "SUCCESS" && userInput.email)
      props.history.push(`/resendEmail?email=${userInput.email}`);
  }, [firebaseResponse.type !== ""]);

  useEffect(() => {
    AuthUser.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "2fa-captcha",
      {
        size: "invisible",
      }
    );
  }, []);

  return (
    <div>
      <FullScreenLoader active={loading} opacity={"1"} />
      <div className="auth-page-wrapper">
        <div className="auth-card">
          <div className="illustartion-box">
            <img src={AuthenticationVector} alt="Authentiaction image" />
          </div>
          <div className="auth-main-content">
            <div className="auth-form-wrapper">
              <div className="text-center mb-5">
                <img src={Logo} alt="Logo" className="auth-logo" />
              </div>
              <div id="2fa-captcha"></div>
              <p className="subheading text-center mb-2">
                {isSignIn && "Sign in"} {!isSignIn && "Sign up"}
              </p>
              <form>
                {renderEmail(
                  userInput.email,
                  handleChange,
                  error.type === FieldName.EMAIL ? error.msg : ""
                )}
                {renderPassword(
                  userInput.password,
                  handleChange,
                  "Password",
                  isPasswordVisible,
                  togglePasswordVisiblity,
                  FieldName.PASSWORD,
                  error.type === FieldName.PASSWORD ? error.msg : ""
                )}
                {!isSignIn &&
                  renderPassword(
                    userInput.reEnteredPw,
                    handleChange,
                    "Re-enter password",
                    isConfirmPasswordVisible,
                    toggleConfirnPasswordVisiblity,
                    FieldName.RE_ENTER_PW,
                    error.type === FieldName.RE_ENTER_PW ? error.msg : ""
                  )}
                <div id="2fa-captcha"></div>
                {firebaseResponse.type !== "" &&
                  renderFirebaseResponse(firebaseResponse.response.message)}
                {renderSubmit(handleSubmit, isSignIn)}
              </form>
              <div>{isSignIn && renderForgetPassword()}</div>
            </div>
            <div className="bottom-form-detail">
              {renderSignUpText(updateIsSignIn, isSignIn)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Login;
