import {MiddlewareAction} from "../middleware";

export enum Actions {
    REQUEST_UPLOAD_LEAVE = "REQUEST_UPLOAD_LEAVE",
    REQUEST_LEAVES_SHEET_LIST = "REQUEST_LEAVES_SHEET_LIST",
    REQUEST_LEAVE_SHEET_DOWNLOAD = "REQUEST_LEAVE_SHEET_DOWNLOAD",
}

export function uploadLeaveSheet(b64File: string): MiddlewareAction {
    return {
        actionName: Actions.REQUEST_UPLOAD_LEAVE,
        type: "POST",
        url: "/hrAction",
        body: {
            type: "LEAVES_UPLOAD",
            b64File,
        }
    }
}
export function leavesUploadList(): MiddlewareAction {
    return {
        actionName: Actions.REQUEST_LEAVES_SHEET_LIST,
        type: "POST",
        url: "/getHrDoc",
        body: {
            type: "LEAVES_SHEET_LIST",
        }
    }
}
export function downloadLeaveSheet(documentId: string): MiddlewareAction {
    return {
        actionName: Actions.REQUEST_LEAVE_SHEET_DOWNLOAD,
        type: "POST",
        url: "/getHrDoc",
        body: {
            type: "LEAVES_SHEET",
            data: {
                documentId
            }
        }
    }
}