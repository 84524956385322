import React, { useEffect } from "react";
import { Route, Switch } from "react-router";
import PayrollDashboard from "./dashboard";
import OldPayroll from "./oldPayroll";
import PayrollSummary from "./summary";
import PayrollUploadSummary from "./upload-summary";
import "./payrollStyles.css";
import dispatch from "../../../middleware";
import { useDispatch, useSelector } from "react-redux";
import {
  Actions as PayrollActions,
  listPayrolls,
} from "../../../actions/payroll";
import { ReduxState } from "../../../reducers";
import isAuthorized from "../../../authorization/authorizationAccess";
import {
  AccessType,
  Resource,
} from "../../../authorization/authorization.enum";
import FullScreenLoader from "../../../components/fullscreen-loader";
import { isLoadingActive } from "../../../utils";
import { RoutePaths } from "../../../models";
import getEffect from "../effect";
import UnauthorizedView from "../unauthorizedView";
import Authorized from "../../../authorization/authorized";

export default function Payroll() {
  const storeDispatch = useDispatch();

  const loadingQueue = useSelector(
    (state: ReduxState) => state.payroll.loadingQueue
  );
  const resources = useSelector(
    (state: ReduxState) => state.login.userDetails?.resources || {}
  );
  const { payrolls, hasEmployerOptedForStreamingAccount } = useSelector(
    (state: ReduxState) => state.payroll.payroll
  );

  useEffect(() => {
    if (
      isAuthorized(resources, Resource.EMPLOYER_PORTAL_PAYROLL, AccessType.READ)
    ) {
      dispatch(storeDispatch, listPayrolls(), false);
    }
  }, []);
  useEffect(getEffect("Payroll", storeDispatch), []);

  return (
    <Authorized
      resourceName={Resource.EMPLOYER_PORTAL_PAYROLL}
      requiredAccessType={AccessType.READ}
      unAuthorizedView={<UnauthorizedView />}
    >
      <>
        <FullScreenLoader
          active={isLoadingActive(loadingQueue, [
            PayrollActions.REQUEST_PAYROLL_LIST,
          ])}
        />
        {hasEmployerOptedForStreamingAccount ? (
          <Switch>
            <Route
              path={RoutePaths.PAYROLL_UPLOAD_SUMMARY}
              component={PayrollUploadSummary}
            />
            <Route
              path={RoutePaths.PAYROLL_SUMMARY}
              component={PayrollSummary}
            />
            <Route path={RoutePaths.PAYROLL} component={PayrollDashboard} />
          </Switch>
        ) : (
          <OldPayroll payrolls={payrolls} />
        )}
      </>
    </Authorized>
  );
}
