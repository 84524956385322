import React, { useEffect, useState } from "react";
import {
  clearFirebaseResponse,
  registerPhoneForEnablingFMA,
} from "../../actions/login";
import { useDispatch, useSelector } from "react-redux";
import { ReduxState } from "../../reducers";
import dispatch from "../../middleware";
import Logo from "../../images/logo-dark.svg";
import { renderFirebaseResponse } from "./utils";
import { AuthUser } from "../../auth/auth";
import firebase from "firebase/app";
import FullScreenLoader from "../../components/fullscreen-loader";
import { navigateTo } from "../../utils";

export default function RegisterMfa() {
  const [phoneNumber, setPhoneNumber] = useState("");

  const firebaseResponse = useSelector(
    (state: ReduxState) => state.login.firebaseResponse
  );
  const loading: boolean = useSelector(
    (state: ReduxState) => state.login.loading
  );
  const storeDispatch = useDispatch();

  useEffect(() => {
    AuthUser.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "2fa-captcha",
      {
        size: "invisible",
      }
    );
  }, []);

  useEffect(() => {
    if (firebaseResponse.type !== "") {
      dispatch(storeDispatch, clearFirebaseResponse());
    }
    if (!AuthUser.email) {
      navigateTo("/");
    }
  }, []);

  return (
    <div className="auth-page-wrapper">
      <FullScreenLoader active={loading} opacity={"1"} />
      <div className="auth-small-card text-center">
        <div>
          <img src={Logo} alt="Logo" width="100px" />
        </div>
        <p className="subheading text-center mt-5 mb-2">Enable 2FA</p>
        <p className="primary-color fs-12">
          Enter your register phone number to confirm that you want to enable
          two-factor authentication.
        </p>
        <div className="form-signin-label-group">
          <input
            type="phone"
            id="inputPhone"
            className="form-control input-login"
            placeholder="Phone Number"
            value={phoneNumber}
            onChange={(e) => {
              AuthUser.phone = e.target.value;
              setPhoneNumber(e.target.value);
            }}
          />
          <label htmlFor="inputEmail">Phone Number</label>
        </div>
        <div id="2fa-captcha"></div>
        {firebaseResponse.type !== "" &&
          renderFirebaseResponse(firebaseResponse.response.message)}
        <button
          className="btn primary-button mt-3"
          onClick={() =>
            registerPhoneForEnablingFMA(
              phoneNumber,
              storeDispatch,
              AuthUser.recaptchaVerifier
            )
          }
          type="submit"
        >
          Next
        </button>
      </div>
    </div>
  );
}
