import React, { ReactNode, useEffect, useRef, useState } from 'react';
import './styles.css';

interface OtpProperties {
  handleSubmit: (otp: string) => void;
  isSubmitting: boolean;
  handleResend?: (context?: any) => Promise<void>;
  timer?: number;
  fixedSubmitBtn?: boolean;
  hideSubmit?: boolean;
  label?: string;
  timerEndCallBack?: () => void;
  OTPCredential?: {
    autoDetect: boolean;
    autoSubmit: boolean;
  };
  resendOtpFallback?: (
    timer: number,
    handleResend: (context?: object) => Promise<void>
  ) => React.ReactNode;
  onOtpEnteredOnce?: () => void;
  footerLabel?: ReactNode;
}
let onGoingTimer: NodeJS.Timeout | null = null;
let abortController: AbortController | null = null;

const OTP_LENGTH = 6;

export default function Otp(props: OtpProperties) {
  let time = props.timer ? props.timer : 45;
  const [timer, updateTimer] = useState<number>(time);
  const fields = useRef<Array<React.MutableRefObject<HTMLInputElement | null>>>([
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null)
  ]);
  const [otp, updateOtp] = useState<string[]>(['', '', '', '', '', '']);

  const hasTypedFullOtpOnce = useRef<boolean>(false);

  useEffect(() => {
    const length = otp.join('').length;
    if (length === OTP_LENGTH && !hasTypedFullOtpOnce.current) {
      if (props.onOtpEnteredOnce) props.onOtpEnteredOnce();
      hasTypedFullOtpOnce.current = true;
    }
  }, [otp]);

  function startTimer() {
    time = props.timer ? props.timer : 45;
    if (onGoingTimer) clearInterval(onGoingTimer);
    onGoingTimer = setInterval(() => {
      // TODO: Refactor it to use React state as Mutating local
      //  variable can result into some unexpected behaviour
      if (time !== 0) {
        time = time - 1;
        updateTimer(time);
      } else {
        if (props.timerEndCallBack) props.timerEndCallBack();
        if (onGoingTimer) clearInterval(onGoingTimer);
      }
    }, 1000);
  }
  async function handleResend(context?: object) {
    updateOtp(['', '', '', '', '', '']);
    try {
      await props.handleResend?.(context);
      startTimer();
    } catch (_) {

    }
  }

  function renderSubmitBtn() {
    return (
      <div className={'py-4 small '}>

        <p
          className={`secondary-color mt-5 ${timer === 0 ? ' cursor-pointer' : ''}`}
          onClick={timer === 0 ? handleResend : undefined}
        >
          {timer === 0
            ? "Send new OTP"
            : `Resend OTP in 00:${timer}`}
        </p>
        <button
            className={"btn btn2 primary-button fs-14 px-5"}
            disabled={otp.join('').length !== 6 || props.isSubmitting}
        >
            {props.isSubmitting ? "Please Wait" : "Next"}
        </button>
      </div>
    );
  }

  function handleInputChange(index: number, value: string) {
    // To support only numbers 0-9 & empty string (used to delete)
    const isNumericValue = /^[0-9]+$|^$/.test(value);
    if (isNumericValue) {
      let updatedOtp = otp;

      // If condition to fill otp from auto suggestion
      if (value.length === 6) {
        updatedOtp = Array.from(value);
      } else if (value.length === 1 || value.length === 0) {
        updatedOtp[index] = value;
        if (value !== '') {
          const i = index === 5 ? 5 : index + 1;
          fields.current[i].current?.focus();
        }
      }
      updateOtp([...updatedOtp]);
    }
  }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    props.handleSubmit(otp.join(''));
  }

  useEffect(() => {
    startTimer();
    return () => {
      abortController?.abort();
      if (onGoingTimer) clearInterval(onGoingTimer);
    };
  }, []);

  function handleKeyDown(index: number, e: any) {
    const key = e.keyCode || e.charCode;
    if (key === 8 || key === 46) {
      const i = index === 0 ? 0 : index - 1;
      fields.current[i]?.current?.focus();
      fields.current[i]?.current?.select();
    }
  }

  return (
    <form className={'text-center'} onSubmit={handleSubmit}>
      <input
        type="text"
        inputMode="numeric"
        ref={fields.current[0]}
        value={otp[0]}
        className={otp[0] === '' ? 'otp-box' : 'otp-text'}
        onKeyUp={(e) => handleKeyDown(0, e)}
        onChange={(e) => handleInputChange(0, e.target.value)}
      />
      <input
        type="text"
        inputMode="numeric"
        value={otp[1]}
        ref={fields.current[1]}
        className={otp[1] === '' ? 'otp-box' : 'otp-text'}
        onKeyUp={(e) => handleKeyDown(1, e)}
        onChange={(e) => handleInputChange(1, e.target.value)}
      />
      <input
        type="text"
        inputMode="numeric"
        value={otp[2]}
        ref={fields.current[2]}
        className={otp[2] === '' ? 'otp-box' : 'otp-text'}
        onKeyUp={(e) => handleKeyDown(2, e)}
        onChange={(e) => handleInputChange(2, e.target.value)}
      />
      <input
        type="text"
        inputMode="numeric"
        value={otp[3]}
        ref={fields.current[3]}
        className={otp[3] === '' ? 'otp-box' : 'otp-text'}
        onKeyUp={(e) => handleKeyDown(3, e)}
        onChange={(e) => handleInputChange(3, e.target.value)}
      />
      <input
        type="text"
        inputMode="numeric"
        value={otp[4]}
        ref={fields.current[4]}
        className={otp[4] === '' ? 'otp-box' : 'otp-text'}
        onKeyUp={(e) => handleKeyDown(4, e)}
        onChange={(e) => handleInputChange(4, e.target.value)}
      />
      <input
        type="text"
        inputMode="numeric"
        value={otp[5]}
        ref={fields.current[5]}
        className={otp[5] === '' ? 'otp-box' : 'otp-text'}
        onKeyUp={(e) => handleKeyDown(5, e)}
        onChange={(e) => handleInputChange(5, e.target.value)}
      />
      {renderSubmitBtn()}
    </form>
  );
}
