import React, { Ref, forwardRef } from "react";
import { BaseInput, BaseInputProps } from "../base-input";
import { MagnifyingGlassIcon } from "../icon";
import "./styles.css"

export interface SearchInputProps extends BaseInputProps<"input"> {}

function _SearchInput(
  props: SearchInputProps,
  ref: Ref<HTMLInputElement>
) {
  return (
    <BaseInput
      ref={ref}
      leftSection={<MagnifyingGlassIcon size="md" />}
      className="rf-search-input"
      {...props}
    />
  );
}

export const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  _SearchInput
);
