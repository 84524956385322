import React, {useEffect, useState} from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import VendorList from "./vendorList";
import VendorSummary from "./vendorSummary";
import { Link } from "react-router-dom";
import {getErrorMsgFromResponse, getParamFromUrl} from "../../../utils";
import "./vendorStyles.scss";
import Attendance from "../attendance/attendance";
import {useDispatch, useSelector} from "react-redux";
import {ReduxState} from "../../../reducers";
import {CurrentEmployees} from "../employees/currentEmployees";
import Transactions from "../all-transactions/transactions";
import RoleSettings from "../settings/roleSettings";
import {AccessType, Resource} from "../../../authorization/authorization.enum";
import Authorized from "../../../authorization/authorized";
import dispatch from "../../../middleware";
import {clearVendorData, getVendorList} from "../../../actions/vendor";
import {showNotifier} from "../../../actions/ui";
import {NotifierBgColor, RoutePaths} from "../../../models";
import EmployeeProfile from "../employees/employeeProfile";
import AnalyticsEvent from "../../../analytics/events";
import { logEventInFirebase } from "../../../analytics/firebase.analytics";

function handleAnalyticsEvent(event: AnalyticsEvent, params?: Record<string, any>) {
    return () => logEventInFirebase(event, params);
}

export default function VendorDashboard() {
    const storeDispatch = useDispatch();
    const { path } = useRouteMatch();
    const vendorId = getParamFromUrl("vendorId");
    const vendorList = useSelector((state: ReduxState) => state.vendor.vendorList)

    async function handleFetchVendorList() {
        try {
            dispatch(storeDispatch, getVendorList());
        } catch(e) {
            await dispatch(
                storeDispatch,
                showNotifier(NotifierBgColor.ERROR, getErrorMsgFromResponse(e))
            );
        }
    }

    function renderVendorMenu() {
        if (!vendorId) return;
        const excludeNavigationPath = "/vendors/employeeProfile";
        let selectedVendorName = ""
        const url = window.location.href;
        if (url.includes(excludeNavigationPath)) return;
        if (vendorId) {
            const selectedVendorDetails = vendorList.filter((vendor) => vendor.vendor._id === vendorId)
            selectedVendorName = selectedVendorDetails[0]?.vendor?.name || ""
        }

        const vendorPages = [
            {
                route: "/summary",
                label: "Dashboard",
                authResource: Resource.EMPLOYER_PORTAL_VENDORS_EMPLOYEES,
                analyticsEvent: AnalyticsEvent.DASHBOARD_SECTION_CLICKED,
            },
            {
                route: "/employees",
                label: "Employees",
                authResource: Resource.EMPLOYER_PORTAL_VENDORS_EMPLOYEES,
                analyticsEvent: AnalyticsEvent.EMPLOYEES_SECTION_CLICKED,
            },
            {
                route: "/attendance",
                label: "Attendance",
                authResource: Resource.EMPLOYER_PORTAL_VENDORS_ATTENDANCE,
                analyticsEvent: AnalyticsEvent.ATTENDANCE_SECTION_CLICKED,
            },
            {
                route: "/transactions",
                label: "Transactions",
                authResource: Resource.EMPLOYER_PORTAL_VENDORS_TRANSACTIONS,
                analyticsEvent: AnalyticsEvent.TRANSACTIONS_SECTION_CLICKED,
            },
            {
                route: "/settings",
                label: "Settings",
                authResource: Resource.EMPLOYER_PORTAL_VENDORS_ADMIN,
                analyticsEvent: AnalyticsEvent.SETTINGS_SECTION_CLICKED,
            }
        ]
        return (
            <div className="d-flex align-items-lg-center align-items-start justify-content-between flex-lg-row flex-column">
                <div className="primary-color w-lg-50  w-100 text-left d-inline-block">
                    <h4 className={"font-weight-bold m-0 text-capitalize"}>{selectedVendorName}</h4>
                </div>
                <div className="mt-lg-0 mt-3 d-flex flex-wrap justify-content-lg-end ustify-content-start w-lg-50 w-100">
                    {
                        vendorPages.map((page, index) => {
                            return (
                                <Authorized
                                    resourceName={page.authResource}
                                    requiredAccessType={AccessType.READ}
                                    key={index}
                                >
                                    <Link
                                        to={`${path}${page.route}?vendorId=${vendorId}`}
                                        className={`mr-3 font-size-small ${url.includes(`/vendors${page.route}`) ? "selected-vendor-route" : "light-gray"}`}
                                        onClick={handleAnalyticsEvent(page.analyticsEvent, { vendorId })}
                                    >
                                        {page.label}
                                    </Link>
                                </Authorized>
                            )
                        })
                    }
                </div>
            </div>
        )
    }

    useEffect(() => {
        if(vendorList?.length === 0) handleFetchVendorList();
        return () => {
            dispatch(storeDispatch, clearVendorData())
        }
    }, [])

    return (
        <div>
            {renderVendorMenu()}
            <div className={"mt-5"}>
                <Switch>
                    <Route exact path={`${path}/attendance`} component={Attendance}/>
                    <Route exact path={`${path}/summary`} component={VendorSummary}/>
                    <Route exact path={`${path}/employees`} component={CurrentEmployees}/>
                    <Route exact path={`${path}/transactions`} component={Transactions}/>
                    <Route exact path={`${path}/settings`} component={RoleSettings}/>
                    <Route exact path={`${path}/employeeProfile`} component={EmployeeProfile}/>
                    <Route exact path={path} component={VendorList}/>
                </Switch>
            </div>
        </div>
    )
}