import { ChangeEvent, createContext, useContext } from "react";
import { CheckboxSize } from ".";

interface CheckboxGroupContextBody {
    value: string[];
    onChange?(event: ChangeEvent<HTMLInputElement>): void;
    size?: CheckboxSize;
}

const CheckboxGroupContext = createContext<CheckboxGroupContextBody | null>(null);
export const CheckboxGroupProvider = CheckboxGroupContext.Provider;
export const useCheckboxGroupContext = () => useContext(CheckboxGroupContext);