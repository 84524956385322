import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import { VendorSummary } from "../../../dto";
import { ReduxState } from "../../../reducers";
import FullScreenLoader from "../../../components/fullscreen-loader";
import SummaryCard from "../../../components/summary-card";
import { navigateTo } from "../../../utils";
import dispatch from "../../../middleware";
import {clearVendorData} from "../../../actions/vendor";
import {AccessType, Resource} from "../../../authorization/authorization.enum";
import Authorized from "../../../authorization/authorized";
import { logEventInFirebase } from "../../../analytics/firebase.analytics";
import AnalyticsEvent from "../../../analytics/events";

function BasicVendorSummary(
    email: string,
    phone: string,
    contractDate: Date,
    totalEmployee: number 
) {
    return (
        <div className="small">
            <div className="secondary-color mt-1">{email}</div>
            <div className="light-gray mt-1">{phone}</div>
            <div className="w-100 text center my-3">
                <div className="w-50 d-inline-block">
                    <span className="light-gray d-block">CONTRACT DATE</span>
                    <span className="primary-color font-weight-bold">
                        {contractDate ? new Date(contractDate)?.toLocaleDateString() : "N/A"}
                    </span>
                </div>
                <div className="w-50 d-inline-block">
                    <span className="light-gray d-block">NO OF EMPLOYEES</span>
                    <span className="primary-color font-weight-bold">
                        {totalEmployee}
                    </span>
                </div>
            </div>
        </div>
    )
}


export default function VendorList() {
    const storeDispatch = useDispatch();
    const vendorLoading: boolean = useSelector((state: ReduxState) => state.vendor.loading);
    const vendorList: VendorSummary[] = useSelector((state: ReduxState) => state.vendor.vendorList);

    useEffect(() => {
        dispatch(storeDispatch, clearVendorData())
    }, [])

    function renderVendorList() {
        return (
            <div className="row small px-2">
                {
                    vendorList?.map((vendorSummary: VendorSummary, index: number) => {
                        return (
                            <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4" key={index}>
                                <SummaryCard 
                                    logoUrl={vendorSummary?.vendor?.vendorInfo?.logoUrl}
                                    label={vendorSummary?.vendor?.name}
                                    onButtonClick={() => {
                                        logEventInFirebase(AnalyticsEvent.VIEW_VENDOR_DETAILS_OPTION_CLICKED, { vendorId: vendorSummary?.vendor?._id });     
                                        navigateTo(`/vendors/summary/?vendorId=${vendorSummary?.vendor?._id}`)
                                    }}
                                    buttonLabel="View Details"
                                    cardSummary={
                                        () => BasicVendorSummary(
                                                vendorSummary?.vendor?.vendorInfo?.email,
                                                vendorSummary?.vendor?.vendorInfo?.mobileNumber,
                                                vendorSummary?.vendor?.vendorInfo?.contractStartDate,
                                                vendorSummary.employeesCount
                                            )
                                    }
                                />

                            </div>
                        )
                    })   
                }
            </div>
        ) 
    }
    return (
        <Authorized
            resourceName={Resource.EMPLOYER_PORTAL_VENDORS_EMPLOYEES}
            requiredAccessType={AccessType.READ}
        >
            <>
                <FullScreenLoader active={vendorLoading}/>
                {renderVendorList()}
            </>
        </Authorized>
    )
}