export enum Env {
  LOCAL = "LOCAL",
  UAT = "UAT",
  DEMO = "DEMO",
  PROD = "PROD",
}
function isValidEnv(value: unknown): value is Env {
  return Object.values(Env).includes(value as Env);
}
enum WebUrl {
  LOCAL = "http://localhost:3000",
  UAT = "https://uat.employer.refyne.co.in/",
  DEMO = "https://demo.employer.refyne.co.in/",
  PROD = "https://employer.refyne.co.in/",
}

enum ApiUrl {
  LOCAL = "http://localhost:8080",
  UAT = "https://api.uat.refyne.co.in",
  DEMO = "https://demo-api.refyne.co.in",
  PROD = "https://api.beta.prod.refyne.co.in",
}

const firebaseConfigUAT = {
  apiKey: "AIzaSyBiwgwf2FKa4W4DTa2zAl2na9Y2I7dkcGg",
  authDomain: "refyne-employer-uat.firebaseapp.com",
  databaseURL: "https://refyne-employer-uat.firebaseio.com",
  projectId: "refyne-employer-uat",
  storageBucket: "refyne-employer-uat.appspot.com",
  messagingSenderId: "80805740904",
  appId: "1:80805740904:web:3995114f8198ef58e996dd",
  measurementId: "G-V9CFC18ERS",
};
const firebaseConfigDemo = {
  apiKey: "AIzaSyAYFXnaeZhgUsKElMzvyJ6-9_MKDSiHai0",
  authDomain: "refyne-employer-demo.firebaseapp.com",
  projectId: "refyne-employer-demo",
  storageBucket: "refyne-employer-demo.appspot.com",
  messagingSenderId: "1031561800380",
  appId: "1:1031561800380:web:1b0b8b96ebbbbd3271beb9",
  measurementId: "G-F20B5RHK5G",
};
const firebaseConfigProd = {
  apiKey: "AIzaSyD9HuvWPS4-W2bDQrkkMEaVYzZJfYkRh_g",
  authDomain: "refyne-employer-prod.firebaseapp.com",
  databaseURL: "https://refyne-employer-prod.firebaseio.com",
  projectId: "refyne-employer-prod",
  storageBucket: "refyne-employer-prod.appspot.com",
  messagingSenderId: "469344664798",
  appId: "1:469344664798:web:4014637ffae7d5f66b964f",
  measurementId: "G-1NHRSNZG1J",
};

const FirebaseConfig: Record<Env, any> = {
  LOCAL: firebaseConfigUAT,
  UAT: firebaseConfigUAT,
  DEMO: firebaseConfigDemo,
  PROD: firebaseConfigProd,
};

enum S3BucketName {
  LOCAL = "uat-public.refyne.co.in",
  UAT = "uat-public.refyne.co.in",
  DEMO = "uat-public.refyne.co.in",
  PROD = "public.refyne.co.in",
}

const VendorRoleIdConfig: Record<Env, string[]> = {
  LOCAL: ["ROLE10000055", "ROLE10000057", "ROLE10000062"],
  UAT: ["ROLE10000055", "ROLE10000057", "ROLE10000062"],
  // TODO: Update when required
  DEMO: [],
  PROD: ["ROLE10000031", "ROLE10000032", "ROLE10000037"],
};

const FlutterFlowEntryPointEnabledEmployersConfig: Record<Env, string[]> = {
  LOCAL: ["EMR10002371"],
  UAT: ["EMR10000005", "EMR10002371"],
  DEMO: [],
  PROD: [
    "EMR10000001",
    "EMR10000332",
    "EMR10001194",
    "EMR10000021",
    "EMR10000036",
    "EMR10000045",
    "EMR10000171",
    "EMR10000183",
    "EMR10000266",
    "EMR10000653",
    "EMR10000741",
    "EMR10000864",
    "EMR10000911",
    "EMR10000160",
    "EMR10001209",
    "EMR10000198",
    "EMR10000741",
  ],
};
const FlutterFlowEntryPointUrlConfig: Record<Env, string> = {
  LOCAL: "https://employer-portal-uat.flutterflow.app/",
  UAT: "https://employer-portal-uat.flutterflow.app/",
  DEMO: "",
  PROD: "https://employer-portal.flutterflow.app/",
};

export const env = isValidEnv(process.env.REACT_APP_API_ENV)
  ? process.env.REACT_APP_API_ENV
  : Env.UAT;
export const baseUrl = ApiUrl[env];
export const webUrl = WebUrl[env];
export const firebaseConfig = FirebaseConfig[env];
export const vendorRoleIds = VendorRoleIdConfig[env];
export const flutterFlowEntryPointEnabledEmployers =
  FlutterFlowEntryPointEnabledEmployersConfig[env];
export const flutterFlowEntryPointUrl = FlutterFlowEntryPointUrlConfig[env];

const s3BucketBaseUrl = `https://s3.ap-south-1.amazonaws.com/${S3BucketName[env]}`;

export const attendanceSampleSheetURL = `${s3BucketBaseUrl}/sample_documents/attendance_upload_sample_full.xlsx`;
export const payrollSampleSheetURL = `${s3BucketBaseUrl}/sample_documents/payroll_upload_sample.xlsx`;
export const streamingAccountPayrollSampleSheetURL = `${s3BucketBaseUrl}/sample_documents/stream_payroll_upload_format.xlsx`;
export const employeeBulkUploadSampleSheetURL = `${s3BucketBaseUrl}/sample_documents/employee_bulk_upload_sample.xlsx`;
export const removeEmployeesUploadSampleSheetURL = `${s3BucketBaseUrl}/sample_documents/remove_employee_upload_sample.xlsx`;
export const leaveBulkUploadSampleSheetURL = `${s3BucketBaseUrl}/sample_documents/leave_bulk_upload_sample.xlsx`;
