import React, { Ref, forwardRef } from "react";
import { BaseInput, BaseInputProps } from "../base-input";


export interface TextInputProps extends BaseInputProps<"input"> {}

function _TextInput(
  props: TextInputProps,
  ref: Ref<HTMLInputElement>
) {

  return (
    <BaseInput component="input" ref={ref} {...props} type="text" />
  );
}

export const TextInput =
  forwardRef<HTMLInputElement, TextInputProps>(_TextInput);
