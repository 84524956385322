import { ChangeEvent, useCallback, useMemo, useRef, useState } from "react";
import {
  ClearFieldError,
  FormErrors,
  FormFieldsRef,
  GetInputProps,
  InputType,
  OnSubmit,
  SetErrors,
  SetFieldError,
  SetFieldValue,
  SetFocus,
  SetValues,
  UseFormProps,
  UseFormReturnType,
  Validate,
  ValidateField,
  LoadingState,
  SetFieldLoading,
  IsFieldLoading,
  _ValidateField,
} from "./types";
import { filterErrors } from "./helpers/filter-errors";
import {
  validateFieldValue,
  validateFieldValueAsync,
  validateValues,
} from "./helpers/validate";

const defaultParserOrFormatter = <T>(value: unknown) => value as T;

export function useForm<Values = Record<string, unknown>>({
  initialValues = {} as Values,
  clearErrorOnInputChange = true,
  validateInputOn = "blur",
  validationRules = {},
  asyncValidationRules = {},
  defaultValueForEmptyFields = undefined,
}: UseFormProps<Values> = {}): UseFormReturnType<Values> {
  const [values, _setValues] = useState<Values>(initialValues);
  const [errors, _setErrors] = useState<FormErrors<Values>>(filterErrors({}));
  const _fieldsRef = useRef<FormFieldsRef<Values>>({});

  const [loadingState, setLoadingState] = useState<LoadingState<Values>>({});

  const clearLoading = useCallback(() => setLoadingState({}), []);
  const setFieldLoading: SetFieldLoading<Values> = useCallback(
    (path, loading) =>
      setLoadingState((current) => ({ ...current, [path]: loading })),
    []
  );
  const isFieldLoading: IsFieldLoading<Values> = useCallback(
    (path) => !!loadingState[path],
    [loadingState]
  );
  const isAnyFieldLoading: boolean = useMemo(
    () =>
      Object.keys(loadingState).some(
        (key) => loadingState[key as keyof Values]
      ),
    [loadingState]
  );

  const setErrors: SetErrors<Values> = useCallback(
    (errs) =>
      _setErrors((current) =>
        filterErrors(typeof errs === "function" ? errs(current) : errs)
      ),
    []
  );

  const clearErrors = useCallback(() => _setErrors({}), []);
  const reset = useCallback(() => {
    _setValues(initialValues);
    clearErrors();
    clearLoading();
  }, []);

  const setFieldError: SetFieldError<Values> = useCallback(
    (path, error) => setErrors((current) => ({ ...current, [path]: error })),
    []
  );

  const clearFieldError: ClearFieldError<Values> = useCallback(
    (path) =>
      setErrors((current) => {
        const clone = { ...current };
        delete clone[path];
        return clone;
      }),
    []
  );

  const setValues: SetValues<Values> = useCallback((payload) => {
    _setValues(payload);
    clearErrorOnInputChange && clearErrors();
  }, []);

  const setFieldValue: SetFieldValue<Values> = useCallback((path, value) => {
    if (isFieldLoading(path)) return;
    const shouldValidateOnStateValueChange = validateInputOn === "change";
    _setValues((currentValues) => {
      const valuesClone = { ...currentValues };
      valuesClone[path] = value;
      if (shouldValidateOnStateValueChange) {
        _validateField(path, valuesClone);
      }
      return valuesClone;
    });
    if (!shouldValidateOnStateValueChange && clearErrorOnInputChange) {
      setFieldError(path, null);
    }
  }, []);

  const validate: Validate<Values> = useCallback(() => {
    const results = validateValues(validationRules, values);
    setErrors(results.errors);
    return results;
  }, [values, validationRules]);

  const _validateField: _ValidateField<Values> = useCallback(
    (path, values) => {
      const results = validateFieldValue(path, validationRules, values);

      if (results.hasError) {
        setFieldError(path, results.error);
      } else {
        clearFieldError(path);
        // TODO: Handle Promise when component unmounts
        if (asyncValidationRules[path]) {
          setFieldLoading(path, true);
          validateFieldValueAsync(path, asyncValidationRules, values)
            .then((validationResult) => {
              if (validationResult.hasError) {
                setFieldError(path, validationResult.error);
              }
            })
            .catch((e) => setFieldError(path, e?.message))
            .finally(() => setFieldLoading(path, false));
        }
      }
      return results;
    },
    [validationRules, asyncValidationRules]
  );

  const validateField: ValidateField<Values> = useCallback(
    (path) => _validateField(path, values),
    [values]
  );

  const isValid: boolean = useMemo(
    () => !validateValues(validationRules, values).hasError,
    [validationRules, values]
  );

  const setFocus: SetFocus<Values> = (path: keyof Values) => {
    const fieldRef = _fieldsRef.current[path];
    if (!fieldRef) return false;
    if (
      fieldRef.disabled ||
      (fieldRef as HTMLInputElement).readOnly ||
      fieldRef.hidden
    )
      return false;
    fieldRef.focus();
    return true;
  };

  const _setFocusOnFirstFocusable = (paths: Array<keyof Values>) => {
    for (const path of paths) if (setFocus(path)) return;
  };

  const _getValueBasedOnInputType = (
    elem: InputType,
    elemTarget: HTMLInputElement,
    config: { defaultValueForEmptyField: string | undefined }
  ) => {
    const { defaultValueForEmptyField } = config;
    if (elem === "checkbox") return elemTarget.checked;
    if (elemTarget.value === "")
      return defaultValueForEmptyField ?? defaultValueForEmptyFields;
    if (elemTarget.type === "number" || elemTarget.inputMode === "numeric") {
      const valueAsNumber = elemTarget.valueAsNumber;
      if (Number.isNaN(valueAsNumber)) {
        // TODO: Does empty string results into zero
        const parsedNumberFromValue = Number(elemTarget.value);
        if (!Number.isNaN(parsedNumberFromValue)) return parsedNumberFromValue;
        return elemTarget.value;
      }
      return valueAsNumber;
    }
    if (elemTarget.type === "date")
      return elemTarget.valueAsDate || elemTarget.value;
    return elemTarget.value;
  };

  const getInputProps: GetInputProps<Values> = (
    path,
    {
      type = "input",
      parser = defaultParserOrFormatter,
      formatter = defaultParserOrFormatter,
      withError = true,
      withRef = true,
      defaultValueForEmptyField,
    } = {}
  ) => {
    const props: ReturnType<GetInputProps<Values>> = {
      name: path as string,
      onBlur: () => {
        if (validateInputOn === "blur" && !isFieldLoading(path)) {
          validateField(path);
        }
      },
      onChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const value = _getValueBasedOnInputType(
          type,
          e.currentTarget as HTMLInputElement,
          { defaultValueForEmptyField }
        );
        setFieldValue(path, parser(value));
      },
      loading: !!loadingState[path],
    };

    const value = formatter(values[path]);
    // TODO: Check with the parser
    if (type === "checkbox") {
      props.checked = Boolean(value);
    } else {
      // TODO: Properly clear undefined null values
      props.value = String(value ?? "");
    }
    if (withError) {
      props.error = errors[path];
    }
    if (withRef) {
      props.ref = (ref: HTMLInputElement | HTMLSelectElement | null) => {
        if (!ref) return;
        _fieldsRef.current[path] = ref;
      };
    }
    return props;
  };

  const onSubmit: OnSubmit<Values> =
    (handleSubmit, handleValidationFailure) => (event) => {
      event.preventDefault();
      if (isAnyFieldLoading) return;
      const validationResult = validate();

      if (!validationResult.hasError) {
        handleSubmit(values, event);
      } else {
        _setFocusOnFirstFocusable(
          Object.keys(validationResult.errors) as Array<keyof Values>
        );
        handleValidationFailure?.(validationResult.errors, values, event);
      }
    };

  return {
    values,
    setValues,
    setFieldValue,
    errors,
    setErrors,
    setFieldError,
    clearErrors,
    clearFieldError,
    validate,
    validateField,
    isValid,
    getInputProps,
    setFocus,
    onSubmit,
    isFieldLoading,
    isAnyFieldLoading,
    reset,
  };
}
