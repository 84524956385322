import { analytics } from "../firebase/firebaseUtils";
import { removeUndefinedProperties } from "../utils";

export function setFirebaseUserId(userId: string) {
    analytics.setUserId(userId);
}

export function setFirebaseUserProperty(properties: Record<string, any>) {
    analytics.setUserProperties(properties);
}

export function logEventInFirebase(eventName: string, eventParams?: Record<string, any>) {
    const clearedEventParams = removeUndefinedProperties(eventParams);
    analytics.logEvent(eventName, clearedEventParams);
}