import {AccessType, Resource} from "./authorization.enum";

export default function isAuthorized(
    resources: {
      [name: string]: string[]
    },
    resourceName: Resource,
    requiredAccessType: AccessType,
) {
    return Boolean((resources[resourceName]?.includes(requiredAccessType)))
}