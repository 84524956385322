import React from "react";
import { AttendanceCycleDto } from "../../../dto";
import { formatDate, isSameMonthInSameYear, navigateTo, showMonthFromDate } from "../../../utils";
import { RoutePaths } from "../../../models";
import BarChart from "../../../components/charts/barChart";

function getTransactionMonthLabelForYAxis(
  startDate?: string,
  endDate?: string
): string {
  if (!startDate || !endDate) return "Days in attendance cycle";
  const start = new Date(startDate);
  const end = new Date(endDate);
  if (isSameMonthInSameYear(start, end)) return showMonthFromDate(start);
  return `${showMonthFromDate(start)} - ${showMonthFromDate(end)}`;
}

function generateDateRangeForXAxisTitle(
  body: Array<{ startDate: string; endDate: string }> = []
): string[] {
  return body.map((elem) => {
    const start = new Date(elem.startDate);
    const end = new Date(elem.endDate);
    return `${start.getDate()}-${end.getDate()}`;
  });
}

interface AttendanceCycleProps {
    attendanceCycle: AttendanceCycleDto | null;
    viewAllRoute?: string;
}

const statStyle = {
    minHeight: 360,
    width: "100%",
    borderRadius: 8,
}

function renderMonthlySummary(attendanceCycle: AttendanceCycleDto | null, viewAllRoute?: string) {
    const viewAllNavigation = viewAllRoute ? viewAllRoute : RoutePaths.EMPLOYERS_TRANSACTIONS
    function handleClickRoute(path: string) {
        navigateTo(path)
    }
   
    if (!attendanceCycle) return
    const startDate = attendanceCycle?.attendanceCycleDates?.startDate;
    const endDate = attendanceCycle?.attendanceCycleDates?.endDate;
    const transactionCountList =  attendanceCycle?.completeCycleTransactionDetails.map((transaction) => {
        return transaction.numberOfTransactions
     });
    
    return (
        <div className="table-title align-items-end bg-white stats-wrapper rounded">
            <div>
                <div className="d-flex justify-content-between mb-2 ">
                    <div>
                        <p className={"table-title fw-600 fs-18 m-0"}>Summary</p>
                    </div>
                    <div
                        className="month-wrapper bg-transparent secondary-color cursor-pointer"
                        onClick={() => handleClickRoute(`${viewAllNavigation}?startDate=${startDate}&endDate=${endDate}`)}>
                        <p className="m-0 fs-12">View</p>
                    </div>
                </div>
            </div>

            <div>
                <BarChart 
                  yLabel={'No. of transactions'} 
                  xLabel={getTransactionMonthLabelForYAxis(startDate, endDate)}
                  xAxisTitleList={generateDateRangeForXAxisTitle(attendanceCycle?.completeCycleTransactionDetails)} 
                  barChartValueList={transactionCountList} 
                  tooltipTitle="Transactions Count" 
                  chartHeight={220}
                  isYAxisTitleVisible={false}
                />
            </div>

            <div className="row no-gutters pt-2 attendance-cycle-data">
                <div className="col-md-4 p-0">
                    <p className="fs-10">Attendance <br />cycle</p>
                    <p className="fs-10 fw-700">
                        {formatDate(new Date(startDate), false, true)} - {formatDate(new Date(endDate), false, true)}
                    </p>
                </div>

                <div className="col-md-4 p-0">
                    <p className="fs-10">Number of <br />transaction</p>
                    <p className="fs-10 fw-700">{attendanceCycle?.totalNumberOfTransactions}</p>
                </div>

                <div className="col-md-4 p-0">
                    <p className="fs-10">Total salary <br />withdrawn</p>
                    <p className="fs-10 fw-700">₹ {attendanceCycle?.totalTransactionalValue}</p>
                </div>
            </div>
        </div>
    )
}


export default function TransactionSummary(props: AttendanceCycleProps) {
    return (
        <div>
            <div style={statStyle}>
                {renderMonthlySummary(props.attendanceCycle, props.viewAllRoute)}
            </div>
        </div>
    )
}