enum AnalyticsEvent {
    DASHBOARD_SECTION_CLICKED = "DASHBOARD_SECTION_CLICKED",
    EMPLOYEES_SECTION_CLICKED = "EMPLOYEES_SECTION_CLICKED",
    ATTENDANCE_SECTION_CLICKED = "ATTENDANCE_SECTION_CLICKED",
    PAYROLL_SECTION_CLICKED = "PAYROLL_SECTION_CLICKED",
    TRANSACTIONS_SECTION_CLICKED = "TRANSACTIONS_SECTION_CLICKED",
    SETTINGS_SECTION_CLICKED = "SETTINGS_SECTION_CLICKED",
    VENDORS_SECTION_CLICKED = "VENDORS_SECTION_CLICKED",
    LOGOUT_OPTION_CLICKED = "LOGOUT_OPTION_CLICKED",
    CHANGE_PASSWORD_OPTION_CLICKED = "CHANGE_PASSWORD_OPTION_CLICKED",
    ADD_SINGLE_EMPLOYEE_OPTION_CLICKED = "ADD_SINGLE_EMPLOYEE_OPTION_CLICKED",
    ADD_BULK_EMPLOYEES_OPTION_CLICKED = "ADD_BULK_EMPLOYEES_OPTION_CLICKED",
    TERMINATE_BULK_EMPLOYEES_OPTION_CLICKED = "TERMINATE_BULK_EMPLOYEES_OPTION_CLICKED",
    TERMINATE_SINGLE_EMPLOYEE_OPTION_CLICKED = "TERMINATE_SINGLE_EMPLOYEE_OPTION_CLICKED",
    EDIT_EMPLOYEE_DETAILS_OPTION_CLICKED = "EDIT_EMPLOYEE_DETAILS_OPTION_CLICKED",
    DOWNLOAD_EMPLOYEE_DATA_OPTION_CLICKED = "DOWNLOAD_EMPLOYEE_DATA_OPTION_CLICKED",
    ADD_BULK_EMPLOYEES_SUCCESS = "ADD_BULK_EMPLOYEES_SUCCESS",
    ADD_BULK_EMPLOYEES_FAILURE = "ADD_BULK_EMPLOYEES_FAILURE",
    TERMINATE_BULK_EMPLOYEES_SUCCESS = "TERMINATE_BULK_EMPLOYEES_SUCCESS",
    TERMINATE_BULK_EMPLOYEES_FAILURE = "TERMINATE_BULK_EMPLOYEES_FAILURE",
    FILE_UPLOAD_SUCCESS = "FILE_UPLOAD_SUCCESS",
    FILE_UPLOAD_FAILURE = "FILE_UPLOAD_FAILURE",
    UPLOAD_BULK_ATTENDANCE_OPTION_CLICKED = "UPLOAD_BULK_ATTENDANCE_OPTION_CLICKED",
    UPLOAD_BULK_ATTENDANCE_SUCCESS = "UPLOAD_BULK_ATTENDANCE_SUCCESS",
    UPLOAD_BULK_ATTENDANCE_FAILURE = "UPLOAD_BULK_ATTENDANCE_FAILURE",
    UPLOAD_PAYROLL_CLICKED = "UPLOAD_PAYROLL_CLICKED",
    VIEW_VENDOR_DETAILS_OPTION_CLICKED = "VIEW_VENDOR_DETAILS_OPTION_CLICKED",
}

export default AnalyticsEvent;