import React from "react";
import { UnstyledButton } from "../../unstyled-button";
import "./styles.css";

export type SortOrder = "none" | "ascending" | "descending";

function toggleState(currentOrder: SortOrder): SortOrder {
  if (currentOrder === "none") return "ascending";
  if (currentOrder === "ascending") return "descending";
  return "none";
}

interface TableSortButtonProps<T> {
  ariaLabel?: string;
  field: keyof T;
  order?: SortOrder;
  onChange?: (state: { by: keyof T; order: SortOrder }) => void;
}

export function TableSortButton<T extends object>({
  ariaLabel,
  order = "none",
  field,
  onChange,
}: TableSortButtonProps<T>) {
  return (
    <UnstyledButton
      onClick={() => onChange?.({ by: field, order: toggleState(order) })}
      aria-label={ariaLabel}
    >
      <span className={`sortable-icon sorted-by-${order}`} aria-hidden={true} />
    </UnstyledButton>
  );
}
