import {Actions} from "../actions/login";
import {UserInfoActions} from "../actions/userInfo";
import {AdminRole, LoggedInUserDetails} from "../models";
import {AdminTodo, AttendanceCycleDto} from "../dto";
import { getErrorMsgFromResponse } from "../utils";

export interface FirebaseResponse {
    type: string;
    response: {
        code: string;
        message: string;
    }
}

export interface LoginState {
    isLoggedIn: boolean;
    loading: boolean;
    firebaseResponse: FirebaseResponse;
    email: string;
    userDetails: LoggedInUserDetails;
    adminTodo: AdminTodo[] | null;
    attendanceCycle: AttendanceCycleDto | null;
    allUserRoles: AdminRole;
}

const defaultFirebaseResponse = {
    type: "",
    response: {
        code: "",
        message: "",
    },
};
const defaultState: LoginState = {
    allUserRoles: {},
    isLoggedIn: false,
    loading: false,
    adminTodo: null,
    userDetails: {
        roleIds: [],
        branchLocation: "",
        email: "",
        employerId: "",
        lastActiveAt: "",
        mobile: "",
        name: "",
        resources: {},
        roles: [],
        _id: "",
    },
    firebaseResponse: defaultFirebaseResponse,
    email: "",
    attendanceCycle: null,
};

export default function LoginStateReducer(state = defaultState, action: any) : LoginState {
    switch (action.type) {
        case UserInfoActions.REQUEST_ADMIN_TODO:
            return {
                ...state,
                adminTodo: null,
            };
        case `${UserInfoActions.REQUEST_ADMIN_TODO}_SUCCESS`:
            return {
                ...state,
                adminTodo: action.payload,
            };
        case `${UserInfoActions.REQUEST_ADMIN_TODO}_FAILURE`:
            return {
                ...state,
                adminTodo: null,
            };
        case UserInfoActions.REQUEST_GET_USER:
            return {
                ...state,
                loading: true,
            };
        case `${UserInfoActions.REQUEST_GET_USER}_SUCCESS`:
            return {
                ...state,
                loading: false,
                userDetails: Object.assign(state.userDetails, action.payload),
            };
        case `${UserInfoActions.REQUEST_GET_USER}_FAILURE`:
            return {
                ...state,
                loading: false,
            };
        case `${Actions.REQUEST_ALL_ROLES}_SUCCESS`:
            return {
                ...state,
                allUserRoles: action.payload,
            };
        case Actions.FIREBASE_LOGIN:
            return {
                ...state,
                loading: true,
                firebaseResponse: defaultFirebaseResponse,
            };
        case `${Actions.FIREBASE_LOGIN}_SUCCESS`:
            return {
                ...state,
                loading: false,
                isLoggedIn: true,
                firebaseResponse: defaultFirebaseResponse,
            };
        case `${Actions.FIREBASE_LOGIN}_FAILURE`: {
            let respObj: Record<"code" | "message" , string>;
            if (action.payload.response?.code) {
                // This is due to firebase exception
                respObj = action.payload.response;
            } else {
                respObj = { code: "", message: getErrorMsgFromResponse(action.payload.response) }
            }
            return {
                ...state,
                loading: false,
                isLoggedIn: false,
                firebaseResponse: {
                    type: "FAILURE",
                    response: respObj,
                },
            };
        }
        case Actions.CLEARE_FIREBASE_RESPONSE:
            return {
                ...state,
                firebaseResponse: defaultFirebaseResponse,
            };
        case Actions.FIREBASE_REGISTER:
            return {
                ...state,
                loading: true,
                firebaseResponse: defaultFirebaseResponse,
            };
        case `${Actions.FIREBASE_REGISTER}_FAILURE`:
            return {
                ...state,
                loading: false,
                firebaseResponse: {
                    type: "FAILURE",
                    response: action.payload.response,
                },
            };
        case `${Actions.FIREBASE_REGISTER}_SUCCESS`:
            return {
                ...state,
                loading: false,
                firebaseResponse: {
                    type: "SUCCESS",
                    response: {
                        code: "",
                        message: "You have successfully signed up!",
                    },
                },
            };
        case Actions.FIREBASE_VERIFICATION_MAIL:
            return {
                ...state,
                loading: true,
                firebaseResponse: defaultFirebaseResponse,
            }
        case `${Actions.FIREBASE_VERIFICATION_MAIL}_SUCCESS`:
            return {
                ...state,
                loading: false,
                firebaseResponse: {
                    type: "SUCCESS",
                    response: {
                        code: "",
                        message: "A verification mail has been sent to your email id. Please click on that link and verify your login",
                    },
                },
            };
        case `${Actions.FIREBASE_VERIFICATION_MAIL}_FAILURE`:
            return {
                ...state,
                loading: false,
                firebaseResponse: {
                    type: "FAILURE",
                    response: {
                        code: "",
                        message: action.payload.response.message,
                    },
                },
            };
        case Actions.STOP_LOADING:
            return {
                ...state,
                loading: false,
            };
        default:
            return state
    }
}