import React from "react";
import { getActiveClassName } from "../../utils";
import "./styles.css";

interface Tab {
  title: string;
  key: string;
  disabled?: boolean;
}

interface TabBarProps {
  tabs: Tab[];
  value?: string;
  onClick?: (key: string) => void;
}

export default function TabBar({ tabs, value, onClick }: TabBarProps) {
  function handleClick(key: string) {
    return () => {
      if (onClick) {
        onClick(key);
      }
    };
  }
  return (
    <ul className="tab-bar">
      {tabs.map(({ title, key, disabled }) => (
        <li
          key={key}
          className={`${getActiveClassName("tab-bar__tab", key === value)}${
            disabled ? " disabled" : ""
          }`}
          onClick={handleClick(key)}
        >
          {title}
        </li>
      ))}
    </ul>
  );
}
