import React, { useState } from "react";
import { getActiveClassName } from "../../../utils";
import "./styles.css";

interface PaginationProps {
  currentPage: number;
  totalPageCount: number;
  neighbourCount?: number;
  terminalNeighbourCount?: number;
  onChange?: (pageNumber: number) => void;
  previousText?: string;
  nextText?: string;
  gapText?: string;
  hasJumpTo?: boolean;
  jumpToLabel?: string;
}

// generateRange(1, 5) => [1, 2, 3, 4, 5]
function generateRange(from: number, to: number, step: number = 1) {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
}

export default function Pagination({
  currentPage,
  totalPageCount,
  neighbourCount = 2,
  terminalNeighbourCount = 1,
  onChange,
  previousText = "<",
  nextText = ">",
  gapText = "...",
  hasJumpTo = true,
  jumpToLabel = "Jump to",
}: PaginationProps) {
  const [jumpTo, setJumpTo] = useState("");

  function handleChange(pageNo: number | string) {
    if (typeof pageNo === "string") return;
    if (onChange) {
      onChange(pageNo);
    }
  }

  function handleForward() {
    handleChange(currentPage + 1);
  }
  function handleBackward() {
    handleChange(currentPage - 1);
  }

  function renderPageNumber(pages: number[]) {
    return pages.map((pageNo) => (
      <li key={pageNo} className="rf-pagination__item">
        <button
          className={getActiveClassName(
            "rf-pagination__btn",
            pageNo === currentPage
          )}
          onClick={() => handleChange(pageNo)}
        >
          {pageNo}
        </button>
      </li>
    ));
  }

  function renderGap() {
    return <li className="rf-pagination__item">{gapText}</li>;
  }

  function generatePageNumbers() {
    // 1 .. 8 [9] 10 .. 20 -> neighbour x 2 + terminalNeighbour * 2 + gap * 2 + activePage (1)
    const totalPageNumbers =
      neighbourCount * 2 + 3 + terminalNeighbourCount * 2;
    if (totalPageNumbers >= totalPageCount)
      return renderPageNumber(generateRange(1, totalPageCount));

    const leftNeighbourIndex = Math.max(
      currentPage - neighbourCount,
      terminalNeighbourCount
    );
    const rightNeighbourIndex = Math.min(
      currentPage + neighbourCount,
      totalPageCount - terminalNeighbourCount
    );

    const hasLeftSpill = leftNeighbourIndex > terminalNeighbourCount + 2;
    const hasRightSpill =
      rightNeighbourIndex < totalPageCount - (terminalNeighbourCount + 1);

    if (hasLeftSpill && !hasRightSpill) {
      const rightItemCount = neighbourCount + 1 + 2 * terminalNeighbourCount;
      return (
        <>
          {renderPageNumber(generateRange(1, terminalNeighbourCount))}
          {renderGap()}
          {renderPageNumber(
            generateRange(totalPageCount - rightItemCount, totalPageCount)
          )}
        </>
      );
    }
    if (!hasLeftSpill && hasRightSpill) {
      const leftItemCount = neighbourCount * 2 + terminalNeighbourCount + 2;
      return (
        <>
          {renderPageNumber(generateRange(1, leftItemCount))}
          {renderGap()}
          {renderPageNumber(
            generateRange(totalPageCount - neighbourCount + 1, totalPageCount)
          )}
        </>
      );
    }
    return (
      <>
        {renderPageNumber(generateRange(1, terminalNeighbourCount))}
        {renderGap()}
        {renderPageNumber(
          generateRange(leftNeighbourIndex, rightNeighbourIndex)
        )}
        {renderGap()}
        {renderPageNumber(
          generateRange(totalPageCount - neighbourCount + 1, totalPageCount)
        )}
      </>
    );
  }

  function handleJumpToSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (!jumpTo) return;
    const jumpToPageNumber = Number(jumpTo);
    handleChange(jumpToPageNumber);
    setJumpTo("");
  }

  if (totalPageCount < 2) return null;

  return (
    <nav aria-labelledby="Table Pagination" className="rf-pagination">
      <ul className="rf-pagination__list">
        <li className="rf-pagination__item">
          <button
            className="rf-pagination__btn--action"
            onClick={handleBackward}
            disabled={currentPage === 1}
            aria-label="Go to previous page"
          >
            {previousText}
          </button>
        </li>
        {generatePageNumbers()}
        <li className="rf-pagination__item">
          <button
            className="rf-pagination__btn--action"
            onClick={handleForward}
            disabled={currentPage === totalPageCount}
            aria-label="Go to next page"
          >
            {nextText}
          </button>
        </li>
      </ul>
      {hasJumpTo && (
        <form className="rf-pagination__jump-to" onSubmit={handleJumpToSubmit}>
          <label
            htmlFor="rf-pagination-jump-to-input"
            className="rf-pagination__jump-to--text"
          >
            {jumpToLabel}
          </label>
          <input
            id="rf-pagination-jump-to-input"
            name="jumpTo"
            type="number"
            className="rf-pagination__jump-to--input"
            min={1}
            max={totalPageCount}
            value={jumpTo}
            onChange={(e) => setJumpTo(e.target.value)}
            required
          />
          <button
            className="rf-pagination__btn--action"
            aria-label="Go to"
            disabled={!jumpTo}
          >
            &gt;
          </button>
        </form>
      )}
    </nav>
  );
}
