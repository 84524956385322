import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import {config} from "../firebase/firebaseUtils";
import dispatch, {clearToken, MiddlewareAction, saveToken} from "../middleware";
import {getUser} from "./userInfo";
import {webUrl} from "../config";
import { LoggedInUserDetails, RoutePaths } from "../models";
import {
  setFirebaseUserId,
  setFirebaseUserProperty,
} from "../analytics/firebase.analytics";
import { navigateTo } from "../utils";
import { AuthUser } from "../auth/auth";
import {
  FIREBASE_AUTH_MFA_REQUIRED_ERROR_CODE,
  MFA_REQUIRED_CORE_SERVER_ERROR_CODE,
} from "../constants";
import { captureException } from "@sentry/react";

export enum Actions {
    FIREBASE_LOGIN = "FIREBASE_LOGIN",
    FIREBASE_REGISTER = "FIREBASE_REGISTER",
    CLEARE_FIREBASE_RESPONSE = "CLEARE_FIREBASE_RESPONSE",
    FIREBASE_VERIFICATION_MAIL = "FIREBASE_VERIFICATION_MAIL",
    STOP_LOADING = "STOP_LOADING",
    REQUEST_ALL_ROLES = "REQUEST_ALL_ROLES",
    REQUEST_MFA_METADATA = "REQUEST_MFA_METADATA"
}
firebase.initializeApp(config, "REFYNE");

export function changePassword(email: string, currentPassword: string, newPassword: string): Promise<string> {
    return new Promise((resolve, reject) => {
        firebase
            .auth()
            .signInWithEmailAndPassword(email, currentPassword)
            .then((user) => {
                firebase
                    .auth()
                    .currentUser
                    ?.updatePassword(newPassword).then(() => {
                    resolve("Password has been updated")
                }).catch(function(err){
                    reject("Couldn't update password")
                });
            }).catch(function(err){
            reject("Current password is wrong!")
        });
    })
}

export async function login(
  email: string,
  password: string,
  storeDispatch: any
) {
  AuthUser.email = email;
  AuthUser.password = password;
  dispatch(storeDispatch, firebaseLogin());
  try {
    const response = await firebase
      .auth()
      .signInWithEmailAndPassword(email, password);
    if (!response.user?.emailVerified) {
      return sendVerificationMail(firebase.auth().currentUser, storeDispatch);
    }
    // TODO: Deprecate it later
    const authToken = await response.user.getIdToken();
    saveToken(authToken);
    (window as any).getToken = () => {
      return authToken;
    };
  } catch (error) {
    if (error.code === FIREBASE_AUTH_MFA_REQUIRED_ERROR_CODE) {
      AuthUser.multiFactorAuthRequired = true;
      AuthUser.resolver = error.resolver;
      await sendOtpForLogin();
      navigateTo(RoutePaths.OTP_VERIFICATION);
    }
    dispatch(storeDispatch, firebaseLogin("FAILURE", error));
  }
}

async function performMfaRegistration(storeDispatch: any) {
  const mfaMetadata = await dispatch(storeDispatch, getMfaMetadata());
  const phoneNumber: string | null =
    mfaMetadata?.employerAdminInfo?.mobile ?? null;
  if (!phoneNumber) {
    navigateTo(RoutePaths.MFA_REGISTER);
    return;
  }
  AuthUser.phone = phoneNumber;
  await registerPhoneForEnablingFMA(
    phoneNumber,
    storeDispatch,
    AuthUser.recaptchaVerifier
  );
}

export async function registerPhoneForEnablingFMA(
  phone: string,
  storeDispatch: any,
  recaptchaVerifier: any
) {
  dispatch(storeDispatch, firebaseLogin());
  try {
    await sendOtpForEnablingMFA(phone, recaptchaVerifier);
    navigateTo(RoutePaths.OTP_VERIFICATION);
  } catch (e) {
    if (
      e.code === "auth/second-factor-already-in-use" ||
      e.code === "auth/requires-recent-login"
    ) {
      logout();
    }
  }
}

export async function confirmOTP(otp: string, storeDispatch: any) {
  try {
    dispatch(storeDispatch, firebaseLogin());
    if (AuthUser.multiFactorAuthRequired) {
      await confirmOtpForMFA(otp);
    } else {
      await confirmOtpToEnableMFA(otp, storeDispatch);
    }
    navigateTo("/");
  } catch (e) {
    dispatch(storeDispatch, firebaseLogin("FAILURE", e));
  }
}

export async function resendOtp() {
    try {
      if (AuthUser.multiFactorAuthRequired) {
        sendOtpForLogin();
      } else {
        sendOtpForEnablingMFA(AuthUser.phone, AuthUser.recaptchaVerifier);
      }
    } catch (e) {
      captureException(e);
    }
}

export async function confirmOtpToEnableMFA(otp:string, storeDispatch: any) {
    const cred = firebase.auth.PhoneAuthProvider.credential(
        AuthUser.phoneOtpVerificationId,
        otp
    );

    const multiFactorAssertion =
    firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
    const currentUser = firebase.auth().currentUser;
    await currentUser?.multiFactor.enroll(multiFactorAssertion, "phone number");
}

export async function confirmOtpForMFA(otp:string) {

    const cred = firebase.auth.PhoneAuthProvider.credential(
        AuthUser.phoneOtpVerificationId,
        otp
    );
    const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
    const credential = await AuthUser.resolver.resolveSignIn(multiFactorAssertion);
}

export async function sendOtpForEnablingMFA(phone: string, recaptchaVerifier:any) {
    const currentUser = firebase.auth().currentUser;
    const session = await currentUser?.multiFactor.getSession();
    const phoneOpts = {
        phoneNumber: `+91${phone}`,
        session,
    };
    const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
    AuthUser.phoneOtpVerificationId = await phoneAuthProvider.verifyPhoneNumber(phoneOpts, recaptchaVerifier);
}

export async function sendOtpForLogin() {
    try {
      AuthUser.phone = AuthUser.resolver.hints[0].phoneNumber;
      const phoneOpts = {
        multiFactorHint: AuthUser.resolver.hints[0],
        session: AuthUser.resolver.session,
      };
      const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
      AuthUser.phoneOtpVerificationId =
        await phoneAuthProvider.verifyPhoneNumber(
          phoneOpts,
          AuthUser.recaptchaVerifier
        );
    } catch (e) {
      captureException(e);
    }
}

export function firebaseLogin(type?: string, response?: object): MiddlewareAction {
    return {actionName: `${Actions.FIREBASE_LOGIN}${type ? `_${type}` : ""}`, body: {response}}
}
export function firebaseRegister(type?: string, response?: object):MiddlewareAction {
    return {actionName: `${Actions.FIREBASE_REGISTER}${type ? `_${type}` : ""}`, body: {response}}
}
export async function register(email: string, password: string, storeDispatch: any) {
  dispatch(storeDispatch, firebaseRegister());
  try {
    firebase
    .auth()
    .createUserWithEmailAndPassword(email, password)
    .then((response: any) => {
        response.user.getIdToken().then((token: string) => {
            saveToken(token);
            dispatch(storeDispatch, firebaseRegister("SUCCESS", response));
            sendVerificationMail(firebase.auth().currentUser, storeDispatch);
        });
    }).catch((error) => {
        dispatch(storeDispatch, firebaseRegister("FAILURE", error));
    });
  } catch (e) {
    dispatch(storeDispatch, firebaseRegister("FAILURE", e));
  }  
}

export function sendVerificationMail(currentUser: any, storeDispatch: any) {
    currentUser
    .sendEmailVerification()
    .then(() => {
        dispatch(storeDispatch, firebaseVerification("SUCCESS"));
    })
    .catch((error: any) => {
        dispatch(storeDispatch, firebaseVerification("FAILURE", error));
    });
}
export function firebaseVerification(type?: string, response?: object):MiddlewareAction {
    return {actionName: `${Actions.FIREBASE_VERIFICATION_MAIL}${type ? `_${type}` : ""}`, body: {response}}
}
export function clearFirebaseResponse() {
    return {actionName: Actions.CLEARE_FIREBASE_RESPONSE}
}
export function sendResetPwEmail(email: string, storeDispatch: any) {
    dispatch(storeDispatch, firebaseVerification());
    firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(() => {
            dispatch(storeDispatch, firebaseVerification("SUCCESS"));
        })
        .catch((error) => {
            dispatch(storeDispatch, firebaseVerification("FAILURE", error));
        });
}
export function checkForAlreadyLogin(storeDispatch: any) {
    dispatch(storeDispatch, firebaseLogin());
    firebase.auth().onAuthStateChanged((user) => {
        if (user && user.emailVerified) {
            user.getIdToken().then(async (token) => {
                saveToken(token);
                (window as any).getToken = () => {
                    return token;
                };
                try {
                  const user: LoggedInUserDetails = await dispatch(
                    storeDispatch,
                    getUser()
                  );
                  setFirebaseUserId(user._id);
                  setFirebaseUserProperty({ employerId: user.employerId });
                  dispatch(storeDispatch, firebaseLogin("SUCCESS"));
                  AuthUser.resetProperties();
                } catch (error) {
                  if (
                    error?.response?.data?.errorCode ===
                    MFA_REQUIRED_CORE_SERVER_ERROR_CODE
                  ) {
                    if (user.email) AuthUser.email = user.email;
                    // TODO: Prevent registration again if already done
                    await performMfaRegistration(storeDispatch);
                  }
                  dispatch(storeDispatch, firebaseLogin("FAILURE", error));
                }
            });
        } else {
            dispatch(storeDispatch, stopLoading());
            // Auto login didn't happen
        }
    });
}
export function logout() {
    firebase
        .auth()
        .signOut()
        .then(() => {
            clearToken();
            window.location.href = webUrl;
        });
}
export function stopLoading() {
    return {actionName: Actions.STOP_LOADING}
}
export async function getIdToken(forceRefresh?: boolean) {
    const currentUser = firebase.auth().currentUser;
    return currentUser?.getIdToken(forceRefresh) || "";
}

export function getAllRoles() {
    return {
        actionName: Actions.REQUEST_ALL_ROLES,
        type: "GET",
        url: "/roles?userType=EMPLOYER_ADMIN",
    }
}

export function getMfaMetadata() {
    return {
      actionName: Actions.REQUEST_MFA_METADATA,
      type: "GET",
      url: "/employer-admin/mfa"
    };
  }
