import React, { useState } from "react";
import { EmployerDetails, IValidation, NotifierBgColor } from "../../../models";
import { useDispatch, useSelector } from "react-redux";
import { ReduxState } from "../../../reducers";
import { TextField } from "../../../components/input-functions";
import Button from "../../../components/button";
import { changePassword } from "../../../actions/login";
import FullScreenLoader from "../../../components/fullscreen-loader";
import { showNotifier } from "../../../actions/ui";
import dispatch from "../../../middleware";
import { getError, isEmptyString } from "../../../utils";
import { logEventInFirebase } from "../../../analytics/firebase.analytics";
import AnalyticsEvent from "../../../analytics/events";

function renderFirstCharacter(employerDetails: EmployerDetails) {
  return employerDetails && employerDetails.name
    ? employerDetails.name.charAt(0).toUpperCase()
    : "";
}
function renderEmployerName(employerDetails: EmployerDetails) {
  return employerDetails && employerDetails.name ? employerDetails.name : "";
}

interface changePasswordUserInput {
  currentPassword: string;
  newPassword: string;
  reEnterNewPassword: string;
}
export default function Profile() {
  const employerDetails: EmployerDetails = useSelector(
    (state: ReduxState) => state.employer.employerDetails
  );
  const employerAdminId = useSelector(
    (state: ReduxState) => state.login.userDetails?._id || ""
  );
  const fullName: string = useSelector(
    (state: ReduxState) => state.login.userDetails?.name || ""
  );
  const mobile: string = useSelector(
    (state: ReduxState) => state.login.userDetails.mobile || ""
  );
  const email: string = useSelector(
    (state: ReduxState) => state.login.userDetails?.email || ""
  );
  const [userInput, updateUserInput] = useState<changePasswordUserInput>({
    currentPassword: "",
    newPassword: "",
    reEnterNewPassword: "",
  });
  const [changePasswordVisible, toggleChangePassword] = useState(false);
  const [loading, updateLoading] = useState(false);
  const [error, updateError] = useState<IValidation>({
    type: "",
    msg: "",
    status: false,
  });

  const storeDispatch = useDispatch();
  function handleInputChange(fieldName: string, value: string | number) {
    updateUserInput((prevState) => {
      return {
        ...prevState,
        [fieldName]: String(value),
      };
    });
    updateError({ type: "", msg: "", status: false });
  }

  function validateForm() {
    let errorStatus = {
      type: "",
      msg: "",
      status: true,
    };
    if (isEmptyString(userInput.currentPassword)) {
      errorStatus = {
        type: "currentPassword",
        msg: "Enter current password",
        status: false,
      };
    } else if (isEmptyString(userInput.newPassword)) {
      errorStatus = {
        type: "newPassword",
        msg: "Enter new password",
        status: false,
      };
    } else if (userInput.newPassword !== userInput.reEnterNewPassword) {
      errorStatus = {
        type: "reEnterNewPassword",
        msg: "Password did not match",
        status: false,
      };
    }
    return errorStatus;
  }

  function handlePasswordChange() {
    let validation = validateForm();
    if (validation.status) {
      updateLoading(true);
      changePassword(email, userInput.currentPassword, userInput.newPassword)
        .then((r) => {
          updateUserInput({
            currentPassword: "",
            newPassword: "",
            reEnterNewPassword: "",
          });
          updateLoading(false);
          toggleChangePassword(false);
          dispatch(storeDispatch, showNotifier(NotifierBgColor.SUCCESS, r));
        })
        .catch((e) => {
          dispatch(storeDispatch, showNotifier(NotifierBgColor.ERROR, e));
          updateLoading(false);
        });
    }
    updateError(validation);
  }
  return (
    <div className={"page-container"}>
      <FullScreenLoader active={loading} />
      <div className="row mb-5">
        <div className="col-3 col-md-1">
          <span className="name-circle-toggler-sidenav fs-22">
            {renderFirstCharacter(employerDetails)}
          </span>
        </div>
        <div className="col-9 col-md-11 align-items-center d-flex">
          <p className="primary-color fw-700 fs-20 m-0">
            {renderEmployerName(employerDetails)}
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-1"></div>
        <div className="col-11">
          <div className="primary-color">
            <h6 className="mb-3 fw-700 primary-color">HR Partner</h6>
            <div>
              <p className="mb-2">
                <span className="fw-600 primary-color">Name:</span>{" "}
                {fullName.toLowerCase()}{" "}
              </p>
              <p className="mb-2">
                <span className="fw-600 primary-color">Mobile: </span> {mobile}{" "}
              </p>
              <p className="mb-2">
                <span className="fw-600 primary-color">Email Id:</span> {email}{" "}
              </p>
            </div>
          </div>
          <br />
          <br />
          <br />
          <div>
            <h5
              className={"fw-700 secondary-color cursor-pointer"}
              onClick={() => {
                toggleChangePassword(!changePasswordVisible);
                logEventInFirebase(AnalyticsEvent.CHANGE_PASSWORD_OPTION_CLICKED);
              }}
            >
              Change Password
            </h5>
            <div
              className={`mt-4 ${!changePasswordVisible && "d-none"}`}
              style={{ maxWidth: 440 }}
            >
              <TextField
                value={userInput.currentPassword}
                fieldName={"currentPassword"}
                onChange={handleInputChange}
                type={"password"}
                valueType={"string"}
                label={"Current Password"}
                error={getError("currentPassword", error)}
              />
              <br />
              <TextField
                value={userInput.newPassword}
                fieldName={"newPassword"}
                onChange={handleInputChange}
                type={"password"}
                valueType={"string"}
                label={"New Password"}
                error={getError("newPassword", error)}
              />
              <br />
              <TextField
                value={userInput.reEnterNewPassword}
                fieldName={"reEnterNewPassword"}
                onChange={handleInputChange}
                type={"password"}
                valueType={"string"}
                label={"Re-enter New Password"}
                error={getError("reEnterNewPassword", error)}
              />
              <Button
                handleBtnClick={handlePasswordChange}
                cls={"profile-btn mt-4"}
                mergeClass={true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
