import React from "react";
import {
  EmployeePayoutTableTab,
  RoutePaths,
  StreamingAccountPayrollDetails,
} from "../../../models";
import { downloadExcelFromBase64, formatInr, navigateTo } from "../../../utils";
import { downloadPayrollFileUsingURI } from "../../document-upload/helpers";
import { PayrollMonthlySummaryProps } from "./monthlySummary";

export function getStreamingPayrollUploadPath(monthText?: string) {
  const searchParams = new URLSearchParams({
    document: "PAYROLL_STREAMING_ACCOUNT",
    returnTo: RoutePaths.PAYROLL_UPLOAD_SUMMARY,
  });
  if (monthText) {
    searchParams.set("payrollMonth", monthText);
  }
  return `${RoutePaths.DOCUMENT_UPLOAD}?${searchParams.toString()}`;
}

type SetQuery<T> = T | ((prevState: T) => T);

export function getMonthlySummaryProps(props: {
  monthText?: string;
  payrollDetails: StreamingAccountPayrollDetails | null;
  hasExecutedAnyPayrollBefore?: boolean;
  DownloadIcon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  storeDispatch: any;
  hasViewSummaryAction?: boolean;
  setQueryParams?: (
    value: SetQuery<Record<"month" | "page" | "tab", string>>
  ) => void;
}): PayrollMonthlySummaryProps {
  const {
    monthText,
    payrollDetails,
    hasExecutedAnyPayrollBefore = true,
    DownloadIcon,
    storeDispatch,
    hasViewSummaryAction = true,
    setQueryParams,
  } = props;

  const DEFAULT_PROPS = {
    month: monthText,
    actionLinks: [
      ...(hasViewSummaryAction ? [{ label: "View Summary" }] : []),
      { label: "Download uploaded sheet", Icon: DownloadIcon },
      { label: "Download Report", Icon: DownloadIcon },
    ],
    chartLabels: ["Amount sent to employees", "Amount sent to Refyne"],
    chartSeries: [8, 2],
    dataFieldRows: [
      [
        { label: "Amount sent to employees", value: 0 },
        { label: "Amount sent to Refyne", value: 0 },
        { label: "Total Employees", value: 0 },
      ],
      [
        { label: "Finalized employees", value: 0 },
        { label: "Paid", value: 0 },
        { label: "Pending", value: 0 },
        { label: "Failed", value: 0 },
        { label: "Skipped", value: 0 },
      ],
    ],
  };

  if (!hasExecutedAnyPayrollBefore) {
    return { ...DEFAULT_PROPS, disabled: true };
  }

  if (!monthText || !payrollDetails) {
    return { ...DEFAULT_PROPS, hasData: !!payrollDetails };
  }

  const {
    totalNumberOfEmployeeEntries,
    finalizedEntries,
    skippedEntries,
    completePayrollPayoutBreakup,
    payrollFile,
    paid,
    pending,
    failed,
  } = payrollDetails;

  const allCountsDataFields = setQueryParams
    ? [
        {
          label: "Paid",
          value: paid?.count || 0,
          onValueClick: () =>
            setQueryParams((prevState) => ({
              ...prevState,
              tab: EmployeePayoutTableTab.TRANSFERRED,
            })),
        },
        {
          label: "Pending",
          value: pending?.count || 0,
          onValueClick: () =>
            setQueryParams((prevState) => ({
              ...prevState,
              tab: EmployeePayoutTableTab.PENDING,
            })),
        },
        {
          label: "Failed",
          value: failed?.count || 0,
          onValueClick: () =>
            setQueryParams((prevState) => ({
              ...prevState,
              tab: EmployeePayoutTableTab.FAILED,
            })),
        },
        {
          label: "Skipped",
          value: skippedEntries,
          onValueClick: () =>
            setQueryParams((prevState) => ({
              ...prevState,
              tab: EmployeePayoutTableTab.SKIPPED,
            })),
        },
      ]
    : [
        { label: "Paid", value: paid?.count || 0 },
        { label: "Pending", value: pending?.count || 0 },
        { label: "Failed", value: failed?.count || 0 },
        { label: "Skipped", value: skippedEntries },
      ];

  return {
    month: monthText,
    actionLinks: [
      ...(hasViewSummaryAction
        ? [
            {
              label: "View Summary",
              onClick: () =>
                navigateTo(`${RoutePaths.PAYROLL_SUMMARY}?month=${monthText}`),
            },
          ]
        : []),
      {
        label: "Download uploaded sheet",
        Icon: DownloadIcon,
        onClick: handleDownloadeportClick(
          storeDispatch,
          payrollFile?.uploadedFileUrl,
          "PAYROLL_UPLOADED_SHEET"
        ),
      },
      {
        label: "Download Report",
        Icon: DownloadIcon,
        disabled: !payrollFile?.finalReport,
        onClick: handleDownloadeportClick(
          storeDispatch,
          payrollFile?.finalReport,
          "PAYROLL_REPORT"
        ),
      },
    ],
    chartLabels: ["Amount sent to employees", "Amount sent to Refyne"],
    chartSeries: [
      paid?.amount || 0,
      completePayrollPayoutBreakup?.amountPayableToRefyne || 0,
    ],
    dataFieldRows: [
      [
        {
          label: "Amount sent to employees",
          value: formatInr(paid?.amount),
        },
        {
          label: "Amount sent to Refyne",
          value: formatInr(completePayrollPayoutBreakup?.amountPayableToRefyne),
        },
        { label: "Total Employees", value: totalNumberOfEmployeeEntries },
      ],
      [
        { label: "Finalized employees", value: finalizedEntries },
        ...allCountsDataFields,
      ],
    ],
  };
}

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

/**
 * Example `Feb 2021` to `{ month: 1, year: 2021 }`
 */
export function dateStringToMonthAndYear(
  dateString: string = "",
  separator: string = " "
) {
  const [monthName, year] = dateString.split(separator);
  const monthIndex = months.findIndex((month) => month === monthName);
  const yearInNumber = Number(year);

  return {
    month: monthIndex < 0 ? undefined : monthIndex,
    year: Number.isNaN(yearInNumber) ? undefined : yearInNumber,
  };
}

export function monthAndYearToDateString(month: number, year: number) {
  if (month > 12 || month < 0) return "";
  return `${months[month]} ${year}`;
}

export function generateMonthYearDescendingSeries(
  startDateString: string | undefined,
  endDateString: string | undefined
) {
  const { month: startMonth, year: startYear } =
    dateStringToMonthAndYear(startDateString);
  const { month: endMonth, year: endYear } =
    dateStringToMonthAndYear(endDateString);
  if (
    startMonth === undefined ||
    startYear === undefined ||
    endMonth === undefined ||
    endYear === undefined
  )
    return [];

  const monthYearSeries: string[] = [];

  for (let year = endYear; startYear <= year; year--) {
    for (
      let month = year === endYear ? endMonth : 11;
      (year === startYear ? startMonth : 0) <= month;
      month--
    ) {
      monthYearSeries.push(monthAndYearToDateString(month, year));
    }
  }
  return monthYearSeries;
}

export function isBalanceSufficient(
  totalPayable?: number,
  balance?: number
): boolean {
  if (!totalPayable || !balance) return false;
  return balance >= totalPayable;
}

export function calculatePageCount(
  total: number | undefined,
  perPageCount?: number
) {
  if (!total || !perPageCount) return 0;
  return Math.ceil(total / perPageCount);
}

export function handleDownloadeportClick(
  storeDispatch: any,
  b64FileUrl: string | undefined,
  fileName: string
) {
  if (!b64FileUrl) return;
  return async () => {
    const b64File = await downloadPayrollFileUsingURI(
      storeDispatch,
      b64FileUrl
    );
    if (!b64File) return;
    downloadExcelFromBase64(b64File, fileName);
  };
}

export function getTabCountValue(
  count?: number,
  { prefix = "(", suffix = ")" }: { prefix?: string; suffix?: string } = {}
) {
  return count !== undefined ? `${prefix}${count}${suffix}` : "";
}
