import { useLayoutEffect, useState } from "react";

const randomId = () => `refyne-${Math.random().toString(36).slice(2, 11)}`;

export function useId(staticId?: string) {
  // TODO: Use React provided hook when upgrade the React version
  const [id, setId] = useState("");

  useLayoutEffect(() => {
    setId(randomId());
  }, []);

  if (staticId) return staticId;

  return id;
}
