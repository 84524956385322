import React from "react";

interface ButtonProps {
    cls?: string;
    label?: string;
    mergeClass?: boolean;
    handleBtnClick?: () => void;
    parentClass?: string;
    buttonType?: string;
    disabled?: boolean;
}

export default function Button(props: ButtonProps) {
    let btnClass = "btn btn2 primary-button w-unset fs-14 px-5"
    if (props.mergeClass && props.cls) {
        btnClass = `${btnClass} ${props.cls} `
    } else if (props.mergeClass === false && props.cls) {
        btnClass = props.cls
    }
    return (
      <div className={props.parentClass}>
        <button
            className={btnClass}
            onClick={props.handleBtnClick}
            disabled={props.disabled}
        >
            {props.label ? props.label : "Submit"}
        </button>
      </div>
    );
}
