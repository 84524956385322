import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  executePayrollStatus,
  getEmployeePayoutDetails,
  getPayrollInfo,
  getStreamingAccountInfo,
  getStreamingAccountPayrollDetails,
  updatePayrollStatus,
} from "../../../../actions/payroll";
import { showNotifier } from "../../../../actions/ui";
import { AccessType, Resource } from "../../../../authorization/authorization.enum";
import isAuthorized from "../../../../authorization/authorizationAccess";
import ConfirmDialog from "../../../../components/confirm-dialog";
import FullScreenLoader from "../../../../components/fullscreen-loader";
import InfoBar from "../../../../components/info-bar";
import NotificationBox from "../../../../components/notification-box";
import Pagination from "../../../../components/library/pagination";
import TabBar from "../../../../components/tab-bar";
import Table from "../../../../components/table";
import { PayoutStatusDto, PayrollStatusDto } from "../../../../dto";
import { useQueryParams } from "../../../../hooks/useQueryParams";
import dispatch from "../../../../middleware";
import {
  EmployeePayoutTableTab,
  NotifierType,
  RoutePaths,
  StreamingAccountPayrollDetails,
} from "../../../../models";
import { ReduxState } from "../../../../reducers";
import {
  formatInr,
  getErrorMsgFromResponse,
  isLoadingActive,
  navigateAndReplaceTo,
  navigateTo,
} from "../../../../utils";
import {
  calculatePageCount,
  getStreamingPayrollUploadPath,
  getTabCountValue,
  isBalanceSufficient,
} from "../helpers";
import PayrollSummaryCard from "./payrollSummaryCard";
import StreamingAccountBalanceCard from "./streamingAccBalanceCard";

const statusesInWhichUploadSummaryShouldRender = [
  PayrollStatusDto.UPLOADED,
  PayrollStatusDto.PROCESSING,
  PayrollStatusDto.PROCESSED,
  PayrollStatusDto.PAYROLL_FROZEN,
];

const statusedTillProcessed = [
  PayrollStatusDto.UPLOADED,
  PayrollStatusDto.PROCESSING,
]

export default function PayrollUploadSummary() {
  const storeDispatch = useDispatch();
  const [queryParams, setQueryParams] = useQueryParams({
    page: "1",
    tab: EmployeePayoutTableTab.TOTAL,
  });
  const { page: pageNo, tab } = queryParams;

  const [searchText, setSearchText] = useState("");
  const [hasSearchQueryActive, setHasSearchQueryActive] = useState(false);
  const [isConfirmDialogActive, setIsConfirmDialogActive] = useState({
    finalize: false,
    cancel: false,
    execute: false,
  });

  const loadingQueue = useSelector(
    (state: ReduxState) => state.payroll.loadingQueue
  );
  const resources = useSelector(
    (state: ReduxState) => state.login.userDetails?.resources || {}
  );
  const payrollInfo = useSelector(
    (state: ReduxState) => state.payroll.payrollInfo
  );
  const streamingAccountInfo = useSelector(
    (state: ReduxState) => state.payroll.streamingAccountInfo
  );
  const streamingAccountPayrollCurrentMonth = useSelector(
    (state: ReduxState) => state.payroll.streamingAccountPayrollCurrentMonth
  );
  const { data: employeePayoutDetails, totalCount } = useSelector(
    (state: ReduxState) => state.payroll.employeePayoutDetails
  );
  const monthYearText = payrollInfo?.monthForWhichPayrollWillBeExecuted;

  const totalPageCount = useMemo(
    () =>
      calculatePageCount(
        totalCount,
        streamingAccountPayrollCurrentMonth?.paginationInfo.perPageLimit
      ),
    [totalCount, streamingAccountPayrollCurrentMonth]
  );

  const hasWriteAccess = useMemo(
    () =>
      isAuthorized(
        resources,
        Resource.EMPLOYER_PORTAL_PAYROLL,
        AccessType.WRITE
      ),
    [resources]
  );

  const isSheetStillProcessing = useMemo(() => {
    if (!streamingAccountPayrollCurrentMonth) return true;
    return statusedTillProcessed.includes(
      streamingAccountPayrollCurrentMonth.status
    );
  }, [streamingAccountPayrollCurrentMonth]);

  useEffect(() => {
    dispatch(storeDispatch, getStreamingAccountInfo(), false);
    dispatch(storeDispatch, getPayrollInfo(), false);
    dispatch(storeDispatch, getStreamingAccountPayrollDetails({ isCurrentMonth: true }), false).then(
      (payroll: StreamingAccountPayrollDetails) => {
        if (
          payroll?.status &&
          !statusesInWhichUploadSummaryShouldRender.includes(payroll?.status)
        ) {
          navigateAndReplaceTo(RoutePaths.PAYROLL);
        }
      }
    );
  }, []);

  useEffect(() => {
    dispatch(
      storeDispatch,
      getEmployeePayoutDetails({
        page: Number(pageNo),
        status: tab,
        searchId: hasSearchQueryActive ? searchText : undefined,
      }),
      false
    );
  }, [pageNo, tab]);

  function handleErrorResponse(e: any) {
    const message = getErrorMsgFromResponse(e);
    dispatch(
      storeDispatch,
      showNotifier("", message, NotifierType.ERROR, {
        autoClose: false,
      })
    );
  }

  function cancelPayroll(payrollId?: string) {
    if (!payrollId) return;
    return async () => {
      try {
        await dispatch(
          storeDispatch,
          updatePayrollStatus(payrollId, PayoutStatusDto.CANCELLED)
        );
        navigateTo(RoutePaths.PAYROLL);
      } catch (error) {
        setIsConfirmDialogActive((prevState) => ({
          ...prevState,
          cancel: false,
        }));
        handleErrorResponse(error);
      }
    };
  }
  function finalizePayroll(payrollId?: string) {
    if (!payrollId) return;
    return async () => {
      try {
        await dispatch(
          storeDispatch,
          updatePayrollStatus(payrollId, PayrollStatusDto.PAYROLL_FROZEN)
        );
        await dispatch(storeDispatch, getPayrollInfo());
        setIsConfirmDialogActive((prevState) => ({
          ...prevState,
          finalize: false,
        }));
      } catch (error) {
        setIsConfirmDialogActive((prevState) => ({
          ...prevState,
          finalize: false,
        }));
        handleErrorResponse(error);
      }
    };
  }
  function executePayroll(payrollId?: string) {
    if (!payrollId) return;
    return async () => {
      try {
        await dispatch(storeDispatch, executePayrollStatus(payrollId));
        navigateTo(RoutePaths.PAYROLL_SUMMARY);
      } catch (error) {
        setIsConfirmDialogActive((prevState) => ({
          ...prevState,
          execute: false,
        }));
        handleErrorResponse(error);
      }
    };
  }

  function handleSearchForm(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setHasSearchQueryActive(true);
    dispatch(
      storeDispatch,
      getEmployeePayoutDetails({
        status: tab,
        searchId: searchText,
      }),
      false
    );
  }
  function clearSearchText() {
    setSearchText("");
    setHasSearchQueryActive(false);
    dispatch(
      storeDispatch,
      getEmployeePayoutDetails({
        page: Number(pageNo),
        status: tab,
      }),
      false
    );
  }

  function getNotificationBoxMessage(
    payrollDetails: StreamingAccountPayrollDetails | null,
    monthText?: string
  ) {
    if (!payrollDetails) return;

    if (statusedTillProcessed.includes(payrollDetails.status)) {
      return "Please wait while we are processing the payroll sheet.";
    }
    if (payrollDetails.status === PayrollStatusDto.PROCESSED) {
      return "Please verify the details and finalize the payroll.";
    }
    if (payrollDetails.status === PayrollStatusDto.PAYROLL_FROZEN) {
      return `Payroll Finalized for ${monthText}`;
    }
  }

  return (
    <>
      <FullScreenLoader active={isLoadingActive(loadingQueue)} />
      <div className="row">
        <div className="col-xl-9">
          <NotificationBox
            message={getNotificationBoxMessage(
              streamingAccountPayrollCurrentMonth,
              monthYearText
            )}
          />
          <h5 className="payroll-upload-summary__title">
            Payroll Summary ({monthYearText})
          </h5>
          <div className="my-4">
            <InfoBar
              data={[
                {
                  label: "Total Employees",
                  value: streamingAccountPayrollCurrentMonth?.totalNumberOfEmployeeEntries,
                },
                {
                  label: "Confirmed Employees",
                  value: streamingAccountPayrollCurrentMonth?.finalizedEntries,
                },
                {
                  label: "Skipped",
                  value: streamingAccountPayrollCurrentMonth?.skippedEntries,
                },
              ]}
            />
          </div>
          <div className="payroll-card table-responsive">
            <div style={{ overflowX: "auto" }}>
              <form className="payroll-search-bar" onSubmit={handleSearchForm}>
                <input
                  className="form-control form-control-lg payroll-search-bar--input"
                  type="text"
                  placeholder="Search Employee by name or ID"
                  name="searchText"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  required
                />
                <button className="btn btn-outline-primary btn-lg payroll-search-bar--button">
                  Search
                </button>
              </form>
              {hasSearchQueryActive && (
                <button className="btn btn-link" onClick={clearSearchText}>
                  <i className="fa fa-times-circle"></i> Clear search query
                </button>
              )}
              <div className="mt-4">
                <TabBar
                  onClick={(tab) =>
                    setQueryParams((prevState) => ({
                      ...prevState,
                      tab,
                      page: "1",
                    }))
                  }
                  tabs={[
                    {
                      title: `Total ${getTabCountValue(
                        streamingAccountPayrollCurrentMonth?.totalNumberOfEmployeeEntries
                      )}`,
                      key: EmployeePayoutTableTab.TOTAL,
                    },
                    {
                      title: `Finalized ${getTabCountValue(
                        streamingAccountPayrollCurrentMonth?.finalizedEntries
                      )}`,
                      key: EmployeePayoutTableTab.FINALIZED,
                    },
                    {
                      title: `Skipped ${getTabCountValue(
                        streamingAccountPayrollCurrentMonth?.skippedEntries
                      )}`,
                      key: EmployeePayoutTableTab.SKIPPED,
                    },
                  ]}
                  value={tab}
                />
                <Table
                  columns={[
                    { label: "Name", field: "name" },
                    { label: "Employee ID", field: "employerEmployeeCode" },
                    { label: "Mobile Number", field: "mobile" },
                    { label: "Salary", field: "salary", formatter: formatInr },
                    {
                      label: "Refyne Deductions",
                      field: "refyneDeductions",
                      formatter: formatInr,
                    },
                    {
                      label: "Net Salary",
                      field: "amountPayable",
                      formatter: formatInr,
                    },
                    { label: "Account Number", field: "accountNumber" },
                    { label: "IFSC", field: "ifsc" },
                  ]}
                  data={employeePayoutDetails}
                />
                <Pagination
                  currentPage={Number(pageNo)}
                  totalPageCount={totalPageCount}
                  onChange={(pageNo) =>
                    setQueryParams((prevState) => ({
                      ...prevState,
                      page: String(pageNo),
                    }))
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col mt-5 mt-xl-0">
          <PayrollSummaryCard
            hasWriteAccess={hasWriteAccess && !isSheetStillProcessing}
            payrollStatus={streamingAccountPayrollCurrentMonth?.status}
            monthText={monthYearText}
            payableToEmployees={
              streamingAccountPayrollCurrentMonth?.completePayrollPayoutBreakup
                ?.totalEmployeePayoutAmount
            }
            payableToRefyne={
              streamingAccountPayrollCurrentMonth?.completePayrollPayoutBreakup
                ?.amountPayableToRefyne
            }
            finalizedEmployeeCount={streamingAccountPayrollCurrentMonth?.finalizedEntries}
            onReuploadClick={() =>
              navigateTo(getStreamingPayrollUploadPath(monthYearText))
            }
            onFinalizeClick={() =>
              setIsConfirmDialogActive((prevState) => ({
                ...prevState,
                finalize: true,
              }))
            }
            onCancelClick={() =>
              setIsConfirmDialogActive((prevState) => ({
                ...prevState,
                cancel: true,
              }))
            }
            isExecutionAllowed={payrollInfo?.isPayrollExecutionAllowed}
            onExecuteClick={() =>
              setIsConfirmDialogActive((prevState) => ({
                ...prevState,
                execute: true,
              }))
            }
          />
          <div className="mt-4">
            {streamingAccountPayrollCurrentMonth?.status ===
              PayrollStatusDto.PAYROLL_FROZEN && (
              <StreamingAccountBalanceCard
                hasSufficientBalance={isBalanceSufficient(
                  streamingAccountPayrollCurrentMonth?.completePayrollPayoutBreakup
                    ?.totalBalanceRequired,
                  streamingAccountInfo?.accountBalance
                )}
                balance={formatInr(streamingAccountInfo?.accountBalance, { roundOff: false })}
                addBalanceUrl={streamingAccountInfo?.rechargeLink}
              />
            )}
          </div>
        </div>
      </div>
      <ConfirmDialog
        active={isConfirmDialogActive.finalize}
        onClose={() =>
          setIsConfirmDialogActive((prevState) => ({
            ...prevState,
            finalize: false,
          }))
        }
        title="Finalize Payroll"
        message={
          "- You cannot make any changes to the payroll sheet once it has been finalised. \n\n- Refyne Tech Pvt. Ltd. is not liable for any errors that may occur post finalising of the payroll sheet (incorrect account number, incorrect IFSC, wrong employee salary)."
        }
        positiveActionButtonLabel="Finalize payroll"
        onPositiveActionButtonClick={finalizePayroll(
          streamingAccountPayrollCurrentMonth?._id
        )}
      />
      <ConfirmDialog
        active={isConfirmDialogActive.cancel}
        onClose={() =>
          setIsConfirmDialogActive((prevState) => ({
            ...prevState,
            cancel: false,
          }))
        }
        title="Cancel payroll?"
        message="Are you sure, you want to cancel the payroll?"
        positiveActionButtonLabel="Do not cancel"
        onPositiveActionButtonClick={() =>
          setIsConfirmDialogActive((prevState) => ({
            ...prevState,
            cancel: false,
          }))
        }
        negativeActionButtonLabel="Yes, Cancel"
        onNegativeActionButtonClick={cancelPayroll(
          streamingAccountPayrollCurrentMonth?._id
        )}
      />
      <ConfirmDialog
        active={isConfirmDialogActive.execute}
        onClose={() =>
          setIsConfirmDialogActive((prevState) => ({
            ...prevState,
            execute: false,
          }))
        }
        title="Execute payroll?"
        message="Are you sure, you want to execute the payroll?"
        positiveActionButtonLabel="Execute Payroll"
        onPositiveActionButtonClick={executePayroll(
          streamingAccountPayrollCurrentMonth?._id
        )}
        negativeActionButtonLabel="Not Now"
        onNegativeActionButtonClick={() =>
          setIsConfirmDialogActive((prevState) => ({
            ...prevState,
            execute: false,
          }))
        }
      />
    </>
  );
}
