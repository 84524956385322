import { history } from "../store";
import { FileType, ONE_MB_IN_BYTES } from "../constants";
import XLSX from "xlsx";
import { IValidation } from "../models";

export function isIphoneDevice() {
  return navigator.userAgent.indexOf("iPhone") !== -1;
}
export function isEmail(email: string) {
  return /\S+@\S+\.\S+/.test(email);
}
export function isName(name: string) {
  return /^[a-zA-Z\.\'\- ]{2,30}$/.test(name);
}
export function isMobile(number: string) {
  return /^[5-9]\d{9}$/.test(number);
}
export function isNumber(str: string) {
  return /\d/.test(str);
}
export function isUpi(upi: string) {
  return /^\w+@\w+$/.test(upi);
}

export function addToLoadingQueue(
  prevLoadingQueue: string[],
  actionName?: string
) {
  if (!actionName) return prevLoadingQueue;
  const tempQueue = [...prevLoadingQueue];
  tempQueue.push(actionName);
  return tempQueue;
}
export function removeFromLoadingQueue(
  prevLoadingQueue: string[],
  actionName?: string
) {
  if (!actionName) return prevLoadingQueue;
  if (actionName.endsWith("SUCCESS") || actionName.endsWith("FAILURE")) {
    const tempActionNameArr = actionName.split("_");
    tempActionNameArr.pop();
    actionName = tempActionNameArr.join("_");
  }
  const index = prevLoadingQueue.findIndex((val) => val === actionName);
  if (index < 0) return prevLoadingQueue;
  const tempQueue = [...prevLoadingQueue];
  tempQueue.splice(index, 1);
  return tempQueue;
}
function hasCommonElement(master: string[], sub: string[]) {
  let obj: Record<string, number> = {};
  master.forEach((el, index) => {
    obj[el] = index;
  });
  const check = sub.some((el) => obj[el] !== undefined);
  return check;
}
export function isLoadingActive(loadingQueue: string[], actionList?: string[]) {
  if (Array.isArray(actionList))
    return hasCommonElement(loadingQueue, actionList);
  return loadingQueue.length > 0;
}

export async function getMimeTypeOfUploadedFile(file: any, isIphone: boolean) {
  try {
    if (isIphone) return file.type;
    const blob = file.slice(0, 4);
    let arrayBuffer = await blob.arrayBuffer();
    const uint = new Uint8Array(arrayBuffer);
    let bytes: any = [];
    uint.forEach((byte) => {
      bytes.push(byte.toString(16));
    });
    let hex = bytes.join("").toUpperCase();
    return getMimeType(hex);
  } catch (e) {
    return getMimeType("Unknown");
  }
}

export function getMimeType(signature: string): string {
  switch (signature) {
    case "504B34":
    case "D0CF11E0":
      return FileType.XLSX;
    case "89504E47":
      return FileType.IMG_PNG;
    case "47494638":
      return "image/gif";
    case "25504446":
      return "application/pdf";
    case "FFD8FFDB":
    case "FFD8FFE0":
    case "FFD8FFE1":
      return FileType.IMG_JPEG;
    case "504B0304":
      return "application/zip";
    case "Unknown":
      return "Unknown";
    default:
      return "Unknown";
  }
}

export function navigateTo(path: string) {
  history.push(path);
}
export function navigateAndReplaceTo(path: string) {
  history.replace(path);
}

// Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
function convertArrayOfObjectsToCSV(array: Array<any>): string {
  if (array.length === 0) return "";

  let result: string;

  const columnDelimiter = ",";
  const lineDelimiter = "\n";
  const keys = Object.keys(array[0]);

  result = "";
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  array.forEach((item) => {
    let ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;

      result += item[key];

      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
}

// Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
export function downloadCSV(array: Array<any>, fileName?: string) {
  const link = document.createElement("a");
  let csv = convertArrayOfObjectsToCSV(array);
  if (csv == null) return;

  const filename = fileName ? fileName : "export.csv";

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute("href", encodeURI(csv));
  link.setAttribute("download", filename);
  link.click();
}
function base64MimeType(encoded: string) {
  var result = null;

  if (typeof encoded !== "string") {
    return result;
  }

  var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);

  if (mime && mime.length) {
    result = mime[1];
  }

  return result;
}

export function downloadExcelFromArrayOfObjects(
  data: any[],
  fileName: string,
  sheetName?: string
) {
  /* make the worksheet */
  var ws = XLSX.utils.json_to_sheet(data);

  /* add to workbook */
  var wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, sheetName);

  /* generate an XLSX file */
  XLSX.writeFile(wb, fileName);
}
export function downloadExcelFromBase64(b64: string, fileName: string) {
  const file =
    "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
    b64;
  let aTag = document.createElement("a");
  aTag.href = file;
  aTag.download = `${fileName}.xlsx`;
  aTag.click();
  aTag.remove();
}

export function isEmptyString(s: string) {
  return s.trim() === "";
}
export const getRandomId = () => Math.random().toString(36).slice(2, 11);

export function getErrorMsgFromResponse(
  e: any,
  defaultMsg = "Something Went Wrong!"
) {
  return (
    (e &&
      e.response &&
      e.response.data &&
      e.response.data.message &&
      e.response.data.message.description) ||
    defaultMsg
  );
}

export function getError(fieldName: string, error: IValidation) {
  if (error.type === fieldName) return error.msg;
  return "";
}

export function isPastDate(date: string) {
  const today = new Date();
  const givenDate = new Date(date);
  return givenDate > today;
}
export function convertDateToNumber(val: string): number {
  return Number(val.replace(/-/g, ""));
}
export function calculateAge(d: string) {
  const date = new Date(d);
  const otherDate = new Date();

  var years = otherDate.getFullYear() - date.getFullYear();

  if (
    otherDate.getMonth() < date.getMonth() ||
    (otherDate.getMonth() == date.getMonth() &&
      otherDate.getDate() < date.getDate())
  ) {
    years--;
  }

  return years;
}

export function isValidJoiningDate(d: string) {
  const age = calculateAge(d);
  return age >= 0 && age <= 70;
}

export function getParamFromUrl(param: string) {
  const urlParams = new URLSearchParams(
    `?${window.location.href.split("?")[1]}`
  );
  return urlParams.get(param);
}
export function isDateOlderThan(age: number, date: Date) {
  if (date) {
    const year = date?.getFullYear();
    const month = date?.getMonth();
    const day = date?.getDate();
    return new Date(year + age, month, day) <= new Date();
  }
  return false;
}

export function formatDate(
  date?: Date,
  showTime?: boolean,
  showShortDate?: boolean
) {
  if (!date) return "";
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const year = date?.getFullYear();
  const month = date?.getMonth();
  const day = date?.getDate();
  let dateString = `${day} ${months[month]} ${year}`;
  let shortDate = ` ${months[month]} ${day}`;
  if (showTime) dateString = dateString + ` | ${date.toLocaleTimeString()}`;
  return showShortDate ? shortDate : dateString;
}
export function formatDateInDDMMYYYY(
  dateString?: string,
  separator: string = "/"
) {
  if (!dateString) return "";
  const date = new Date(dateString);
  const year = date?.getFullYear();
  const month = date?.getMonth() + 1;
  const day = date?.getDate();

  return (
    (day < 10 ? "0" + day : day) +
    separator +
    (month < 10 ? "0" + month : month) +
    separator +
    year
  );
}
export function isoDateToInputElemDate(dateString: string): string {
  return dateString.split("T")[0];
}
export function dateToTimeString(dateString?: string) {
  if (!dateString) return;
  const date = new Date(dateString);
  return date.toLocaleTimeString();
}

export function dateToInputElemDate(date: Date) {
  const separator = "-";
  const dd = date.getDate();
  const mm = date.getMonth() + 1;
  const yyyy = date.getFullYear();

  return (
    yyyy +
    separator +
    (mm < 10 ? "0" + mm : mm) +
    separator +
    (dd < 10 ? "0" + dd : dd)
  );
}

export function showMonthFromDate(date: Date) {
  if (!date) return "";
  const months = [
    "January",
    "Febreuary",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const month = date?.getMonth();
  let monthString = `${months[month]}`;
  return monthString;
}
export function getMonthAndYearFromDate(date: Date): [string, number] {
  return [showMonthFromDate(date), date.getFullYear()];
}
export function getPreviousMonthDate(): Date {
  const date = new Date();
  date.setMonth(date.getMonth() - 1);
  return date;
}
export function formatDateFromNumber(val: number | undefined): string {
  if (!val) return "";
  const year = String(val).slice(0, 4);
  const month = String(val).slice(4, 6);
  const day = String(val).slice(6, 8);
  return formatDate(new Date(`${month}-${day}-${year}`));
}
export function getTodayDateForInputField() {
  const today = new Date();
  return dateToInputElemDate(today);
}
export function isSameMonthInSameYear(startDate: Date, endDate: Date): boolean {
  return (
    startDate.getFullYear() === endDate.getFullYear() &&
    startDate.getMonth() === endDate.getMonth()
  );
}
export function isNullOrUndefined(value: any) {
  return value === undefined || value === null;
}
export function stringToNumberOrNull(value?: string | number | null) {
  if (typeof value === "number") return value;
  if (!value || isEmptyString(value)) return null;
  return Number(value);
}
export function nullableNumberToString(value?: number | null): string {
  if (value === null || value === undefined) return "";
  if (Number.isNaN(Number(value))) return "";
  return value.toString();
}
export function formatRoleName(roles: string[]) {
  if (!roles) return;
  return roles.toString().replace(/_/g, " ");
}
export function removeUndefinedProperties(obj?: Record<string, any>) {
  if (!obj) return;
  const newObject = { ...obj };
  Object.keys(newObject).forEach(
    (key) =>
      (newObject[key] === undefined || newObject[key] === null) &&
      delete newObject[key]
  );
  return newObject;
}
export function formatInr(
  value?: number | string,
  config: { maxFractionDigits?: number; roundOff?: boolean } = {}
) {
  const { maxFractionDigits = 0, roundOff = true } = config;
  let amount = Number(value);
  if (isNaN(amount)) return "";
  if (maxFractionDigits === 0) {
    amount = roundOff ? Math.round(amount) : Math.floor(amount);
  }
  return amount.toLocaleString("en-IN", {
    maximumFractionDigits: maxFractionDigits,
    style: "currency",
    currency: "INR",
  });
}
export function getActiveClassName(className: string, isActive: boolean) {
  if (isActive) return `${className} active`;
  return className;
}
export function clearFormPayload<T extends Record<string, any>>(value: T): T {
  const obj = { ...value };
  for (const propName in obj) {
    if (typeof obj[propName] === "object" && obj[propName] !== null) {
      if (!Array.isArray(obj[propName])) {
        obj[propName] = clearFormPayload(obj[propName]);
      }
      if (Object.keys(obj[propName]).length === 0) {
        delete obj[propName];
        continue;
      }
    } else if (
      obj[propName] === undefined ||
      obj[propName] === "" ||
      Number.isNaN(obj[propName])
    ) {
      delete obj[propName];
    }
  }
  return obj;
}
export function paginate<T extends object>(
  items: T[],
  pageSize: number,
  pageNumber: number
): T[] {
  return items.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
}

export function generateSortComparator<T extends object>(
  property: keyof T,
  sortBy: "ascending" | "descending",
  type: "number" | "string" | "date" = "number"
) {
  const sortOrder = sortBy === "ascending" ? 1 : -1;
  return function (a: T, b: T) {
    const valA = a[property];
    const valB = b[property];

    let result = 0;
    if (type === "number") {
      result = valA < valB ? -1 : valA > valB ? 1 : 0;
    } else if (type === "string") {
      if (Intl.Collator) {
        result = new Intl.Collator().compare(String(valA), String(valB));
      } else {
        result = String(valA).localeCompare(String(valB));
      }
    } else if (type === "date") {
      const dateTimeA = new Date(String(valA)).getTime();
      const dateTimwB = new Date(String(valB)).getTime();

      result = dateTimeA < dateTimwB ? -1 : dateTimeA > dateTimwB ? 1 : 0;
    }
    return result * sortOrder;
  };
}

export function chainFilterPredicateByAND<T>(
  ...predicates: Array<(value: T) => boolean>
): (value: T) => boolean {
  return (value) => predicates.every((predicate) => predicate(value));
}

export function getFilterPredicateForSearchText<T>(
  searchText: string,
  searchInFields: Array<keyof T>,
  config: { separator?: string } = {}
): (value: T) => boolean {
  const { separator } = config;
  return (value: T) => {
    if (searchText.trim() === "") return true;
    const searchValues = separator ? searchText.split(separator) : [searchText];

    return searchInFields.some((searchField) => {
      const _valueToBeSearched = value[searchField];
      if (!_valueToBeSearched) return false;
      return searchValues.some((_searchValue) => {
        const searchValue = _searchValue.trim();
        const valueToBeSearched = String(_valueToBeSearched).trim();

        if (!searchValue || !valueToBeSearched) return false;
        return valueToBeSearched
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      });
    });
  };
}

export function searchInEntriesByText<T extends object = {}>(
  entries: T[],
  searchValue: string,
  searchProperties: Array<keyof T>
) {
  if (searchValue.trim() === "") return entries;
  return entries.filter((entry) => {
    return searchProperties.some((searchProperty) => {
      const valueToBeSearched = entry[searchProperty];
      if (!valueToBeSearched) return false;
      return String(valueToBeSearched)
        .toLowerCase()
        .includes(searchValue.toLowerCase());
    });
  });
}

export function maskString(context: {
  inputString: string;
  numCharactersToShowStart: number;
  numCharactersToShowEnd: number;
}): string {
  const { inputString, numCharactersToShowEnd, numCharactersToShowStart } =
    context;
  if (inputString.length <= numCharactersToShowStart + numCharactersToShowEnd) {
    return inputString;
  }

  const start = inputString.slice(0, numCharactersToShowStart);
  const end = inputString.slice(-numCharactersToShowEnd);

  const maskedLength =
    inputString.length - numCharactersToShowStart - numCharactersToShowEnd;
  const maskedString = "*".repeat(maskedLength);

  return start + maskedString + end;
}

export function getMbFromBytes(sizeInBytes: number): number {
  const sizeInMb = sizeInBytes / ONE_MB_IN_BYTES;
  if (sizeInMb < 1) return sizeInMb;
  return Math.floor(sizeInMb);
}

export function convertToSentenceCase(inputString: string) {
  const words = inputString.split("_");
  const result = words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
  return result;
}
