import React, { useCallback, useState } from "react";
import ImageViewer from "react-simple-image-viewer";
import PdfImage from "../../images/okyc-verified.png";

interface DocPreviewProps {
    dataSrc: string;
    docName: string;
    verificationStatus: string;
    className: string;
}

export default function DocumentPreview(props: DocPreviewProps) {
    const [isViewerOpen, setIsViewerOpen] = useState(false);
  
    const openImageViewer = useCallback(() => {
      setIsViewerOpen(true);
    }, []);
  
    const closeImageViewer = () => {
      setIsViewerOpen(false);
    };
    const fileType = props.dataSrc?.split(",")[0];
    let imgSrc = props.dataSrc;
    let allowDownload = true;
    if (fileType === "data:application/pdf;base64") {
      imgSrc = PdfImage;
    }
    if (props.dataSrc?.slice(0, 5) !== "data:") {
      allowDownload = false;
    }
    return (
      <div className={`${props.className} p-2`}>
        <div className="doc-card">
          {props.dataSrc && (
            <>
              <img
                src={imgSrc}
                key={0}
                alt={"document"}
                onClick={openImageViewer}
              />
              {isViewerOpen && (
                <ImageViewer
                  src={[imgSrc]}
                  onClose={closeImageViewer}
                  backgroundStyle={{
                    backgroundColor: "rgba(0,0,0,0.9)",
                  }}
                />
              )}
              <div className="green-color fw-500 pl-2 absolute-top-left-btn">
                {props.verificationStatus}
              </div>
              {allowDownload && (
                <a href={props.dataSrc} download target={"_blank"}>
                  <i
                    className="green-color fw-500 pl-2 absolute-top-right-btn fa fa-download"
                    aria-hidden="true"
                  />
                </a>
              )}
            </>
          )}
        </div>
      </div>
    );
  }