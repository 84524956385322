import React from "react";
import { PayrollStatusDto } from "../../../../dto";
import { formatInr } from "../../../../utils";

interface PayrollSummaryCardProps {
  monthText?: string;
  hasWriteAccess?: boolean;
  payrollStatus?: PayrollStatusDto;
  payableToEmployees?: number;
  payableToRefyne?: number;
  finalizedEmployeeCount?: number;
  onReuploadClick?: () => void;
  onFinalizeClick?: () => void;
  onCancelClick?: () => void;
  isExecutionAllowed?: boolean;
  onExecuteClick?: () => void;
}

function renderHorizontalText(label: string, value: string | number) {
  return (
    <p className="d-flex align-items-center justify-content-between payroll-summary-card__text my-2">
      <span>{label}</span>
      <span className="payroll-summary-card__amount-text">{value}</span>
    </p>
  );
}

export default function PayrollSummaryCard({
  monthText,
  hasWriteAccess = false,
  payrollStatus,
  payableToEmployees = 0,
  payableToRefyne = 0,
  finalizedEmployeeCount = 0,
  onReuploadClick,
  onFinalizeClick,
  onCancelClick,
  isExecutionAllowed = false,
  onExecuteClick,
}: PayrollSummaryCardProps) {
  if (payrollStatus === PayrollStatusDto.PAYROLL_FROZEN) {
    return (
      <div className="payroll-card">
        <p className="payroll-summary-card__status_text--success">Finalised</p>
        <div className="payroll-summary-card__alert-box">
          <p className="d-flex align-items-center justify-content-between payroll-summary-card__text my-2">
            <span>Payroll Month</span>
            <span>
              <b>{monthText}</b>
            </span>
          </p>
          <p className="d-flex align-items-center justify-content-between payroll-summary-card__text my-2">
            <span>Finalized Employees</span>
            <span>
              <b>{finalizedEmployeeCount}</b>
            </span>
          </p>
        </div>
        {renderHorizontalText(
          "Payable to Employees",
          formatInr(payableToEmployees)
        )}
        {renderHorizontalText("Payable to Refyne", formatInr(payableToRefyne))}
        <hr />
        {renderHorizontalText(
          "Total Payable",
          formatInr(payableToEmployees + payableToRefyne)
        )}
        <div className="payroll-summary-card__buttons d-flex align-items-center justify-content-between mt-5">
          <button
            className="btn payroll-summary-card__text--danger"
            disabled={!hasWriteAccess}
            onClick={onCancelClick}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary"
            onClick={onExecuteClick}
            disabled={!hasWriteAccess || !isExecutionAllowed}
          >
            Execute Payroll
          </button>
        </div>
      </div>
    );
  }
  return (
    <div className="payroll-card">
      <div className="d-flex align-items-center justify-content-between">
        <h5 className="payroll-summary-card__month--text">Payroll Month</h5>
        <h5 className="payroll-summary-card__month--grey-outline-text">
          {monthText}
        </h5>
      </div>
      <div className="payroll-summary-card__alert-box">
        Finalize your payroll to view the required amount.
      </div>
      <div className="payroll-summary-card__buttons">
        <button
          className="btn btn-outline-primary btn-block btn-lg"
          disabled={!hasWriteAccess}
          onClick={onReuploadClick}
        >
          Reupload Payroll sheet
        </button>
        <button
          className="btn btn-primary btn-block btn-lg"
          disabled={!hasWriteAccess}
          onClick={onFinalizeClick}
        >
          Finalize payroll
        </button>
      </div>
    </div>
  );
}
