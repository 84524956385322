import {isEmail, isEmptyString, isMobile, isName} from "../../../utils";
import {FieldName} from "../../../models";
import {EmployerAdminDto} from "../../../dto";

export function validateCreateOrEditAdminForm(hrDetails: EmployerAdminDto) {
    const status = {
        status: false,
        msg: "",
        type: "",
    }
    if (!isName(hrDetails.name)) {
        status.msg = "Invalid name";
        status.type = FieldName.NAME;
    } else if (!isMobile(hrDetails.mobile)) {
        status.msg = "Invalid mobile";
        status.type = FieldName.MOBILE;
    } else if (!isEmail(hrDetails.email)) {
        status.msg = "Invalid email";
        status.type = FieldName.EMAIL;
    } else if (isEmptyString(hrDetails.branchLocation)) {
        status.msg = "Invalid branch location";
        status.type = FieldName.BRANCH_LOCATION;
    } else status.status = true
    return status
}