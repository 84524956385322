import React, { useEffect } from "react";

import './dashboardStyles.css';

import {getRoutes} from "./dashboardRoutes";
import {Menu} from "./menu";
import Notifier from "../../components/notifier";
import Toggler from "./toggler";
import FullScreenLoader from "../../components/fullscreen-loader";
import { useDispatch, useSelector } from "react-redux";
import { ReduxState } from "../../reducers";
import dispatch from "../../middleware";
import {getEmployerConfig, getUserEmployerInfo, getUserEmployerMetadata} from "../../actions/employer";
import isAuthorized from "../../authorization/authorizationAccess";
import {AccessType, Resource} from "../../authorization/authorization.enum";
import {navigateTo} from "../../utils";
import {RoutePaths} from "../../models";
import { Toaster } from "react-hot-toast";

function hasMinimumOneVendorAccess(
    resources: {
        [name: string]: string[]
    }
) {
    return (
        isAuthorized(resources, Resource.EMPLOYER_PORTAL_VENDORS_ADMIN, AccessType.READ) ||
        isAuthorized(resources, Resource.EMPLOYER_PORTAL_VENDORS_EMPLOYEES, AccessType.READ) ||
        isAuthorized(resources, Resource.EMPLOYER_PORTAL_VENDORS_PAYROLL, AccessType.READ) ||
        isAuthorized(resources, Resource.EMPLOYER_PORTAL_VENDORS_TRANSACTIONS, AccessType.READ) ||
        isAuthorized(resources, Resource.EMPLOYER_PORTAL_VENDORS_ATTENDANCE, AccessType.READ)
    )
}
function hasMinimumOneEmployerAccess(
    resources: {
        [name: string]: string[]
    }
) {
    return (
        isAuthorized(resources, Resource.EMPLOYER_PORTAL_ADMIN, AccessType.READ) ||
        isAuthorized(resources, Resource.EMPLOYER_PORTAL_EMPLOYEES, AccessType.READ) ||
        isAuthorized(resources, Resource.EMPLOYER_PORTAL_ATTENDANCE, AccessType.READ) ||
        isAuthorized(resources, Resource.EMPLOYER_PORTAL_PAYROLL, AccessType.READ) ||
        isAuthorized(resources, Resource.EMPLOYER_PORTAL_TRANSACTIONS, AccessType.READ)
    )
}

export function Dashboard(){
    const storeDispatch = useDispatch();
    const loading: boolean = useSelector((state: ReduxState) => state.login.loading);
    const resources = useSelector((state: ReduxState) => state.login?.userDetails?.resources || {})
    const employerConfig = useSelector((state: ReduxState) => state.employer.employerConfig);

    function autoRouteNavigate() {
        if (!hasMinimumOneEmployerAccess(resources) && hasMinimumOneVendorAccess(resources)) {
            navigateTo(RoutePaths.VENDORS)
        }
    }

    useEffect(() => {
        dispatch(storeDispatch, getUserEmployerInfo())
        dispatch(storeDispatch, getEmployerConfig(), false);
        dispatch(storeDispatch, getUserEmployerMetadata())
        autoRouteNavigate()
    }, [])
    return <div className="dashboard">
        <div className="menu-wrapper">
         <Menu />
        </div>
        <div className="content">
            <FullScreenLoader active={loading}/>
            <div className="header">
             <Toggler/>
            </div>
            <div className="route-area">
             {getRoutes(employerConfig)}
            </div>
        </div>
        <Toaster
            position="bottom-right"
            reverseOrder={false}
        />
        <Notifier/>
    </div>
}