export interface PayrollUploadDTO {
  startDate: number;
  file: string;
  endDate: number;
}

export interface PayrollDTO {
  endDate: number;
  freezedDate: string;
  originalPayrollId: string;
  processedPayrollId: string;
  startDate: number;
  uploadedAt: number;
  uploadedBy: string;
  employerAdminId: string;
}

export interface CycleInfoDto {
  startDate: string;
  endDate: string;
}
export interface PayrollInfoDto {
  nextPayroll: string;
  isCurrentPayrollActive: boolean;
  monthForWhichPayrollWillBeExecuted: string;
  isPayrollExecutionAllowed: boolean;
}

export interface PayrollMonthlySummaryDto {
  latestExecutedPayrollMonthYear: string;
  hasExecutedAnyPayrollBefore: boolean;
  firstExecutedPayrollMonthYear: string;
}

export interface StreamingAccountInfoDto {
  accountBalance: number;
  rechargeLink: string;
}

export enum PayrollStatusDto {
  UPLOADED = "UPLOADED",
  PROCESSING = "PROCESSING",
  PROCESSED = "PROCESSED",
  PAYROLL_FROZEN = "PAYROLL_FROZEN",
  PAYROLL_PROCESSING_FAILED = "PAYROLL_PROCESSING_FAILED",
  DISBURSAL_INITIATED = "DISBURSAL_INITIATED",
  DISBURSAL_IN_PROGRESS = "DISBURSAL_IN_PROGRESS",
  DISBURSAL_FAILED = "DISBURSAL_FAILED",
  DISBURSAL_COMPLETED = "DISBURSAL_COMPLETED",
  EXPIRED = "EXPIRED",
}
export interface StreamingAccountPayrollDetailsDto {
  _id: string;
  employerId: string;
  employerAdminId: string;
  salaryCreditCycle: CycleInfoDto;
  attendanceCycle: CycleInfoDto;
  workingDays?: number;
  payrollProcessingCycle: CycleInfoDto;
  payrollFile: {
    uploadedFileUrl: string;
    processedFileUrl?: string;
    finalReport?: string;
  };
  totalNumberOfEmployeeEntries: number;
  finalizedEntries: number;
  skippedEntries: number;
  paginationInfo: {
    perPageLimit: number;
  };
  uploadDate: string;
  uploadedByEmail: string;
  approvalDate: string;
  approvedByEmail: string;
  status: PayrollStatusDto;
  isReexecutionAllowed: boolean;
  completePayrollPayoutBreakup?: {
    totalEmployeePayoutAmount: number;
    amountPayableToRefyne: number;
    totalBalanceRequired: number;
    balanceAvailable?: number;
  };
  noOfBatches: number;
  completeBatchSize: number;
  payrollProcessingFailedReason?: string;
  paid?: {
    count: number;
    amount: number;
  };
  pending?: {
    count: number;
    amount: number;
  };
  failed?: {
    count: number;
    amount: number;
  };
  createdAt: string;
  updatedAt: string;
}

export enum PayoutStatusDto {
  FINALIZED = "FINALIZED",
  SKIPPED = "SKIPPED",
  INITIATED = "INITIATED",
  PENDING = "PENDING",
  FAILED = "FAILED",
  TRANSFERRED = "TRANSFERRED",
  CANCELLED = "CANCELLED",
}

export interface EmployeePayoutDto {
  name: string;
  employerEmployeeCode: string;
  salary: number;
  refyneDeduction: number;
  amountPayable: number;
  accountNumber: string;
  ifsc: string;
  requestId: string;
  email: string;
  mobile: string;
  createdDate: string;
  updatedDate: string;
  utr?: string;
  status: PayoutStatusDto;
}

export interface EmployeeDetailsDto {
  _id: any;
  employerEmployeeId: string;
  email: string;
  mobile: string;
  fullName: string;
  currentMonthlySalary: number;
  verified: boolean;
  maxWithdrawalSalaryPercent: number;
  designation?: string;
  terminatedAt?: string;
  joinedAt?: string;
  userId: string;
  maxWithdrawalPerTransaction?: number | null;
  maxWithdrawalPerDay?: number | null;
  salaryAccountNumber?: string;
  isTransactionAllowed: boolean;
  gradeId?: string;
  probationPeriodInDays?: number;
  departmentId?: string;
  branchId?: string;
  principalEmployerId?: string;
  employmentStatus?: string;
}

export interface CreateEmployeeDto {
  fullName: string;
  email?: string;
  mobile?: string;
  employerEmployeeId: string;
  currentMonthlySalary: number;
  salaryAccountNumber?: string;
  maxWithdrawalSalaryPercent?: number | null;
  maxWithdrawalPerTransaction?: number | null;
  maxWithdrawalPerDay?: number | null;
  maxWithdrawalAmountPerCycle?: number;
  maxTransactionsPerCycle?: number;
  joinedAt?: number;
  vendorId?: string;
  principalEmployerId?: string;
  probationPeriodInDays?: number;
  branchId?: string;
  isTransactionAllowed?: boolean;
  departmentId?: string;
  gradeId?: string;
  designation?: string;
}

export interface UpdateEmployeeDto extends Partial<CreateEmployeeDto> {
  id: string;
}
export interface LeaveDetailsDto {
  documentId: string;
  uploadedDate: string;
}
export interface AttendanceDetailDto {
  documentId: string;
  processedDocumentId: string;
  uploadedDate: string;
  latestAttendanceDate: number;
  uploadedBy: string;
  status: string;
  employerAdminId: string;
}

export enum TransactionStatusDto {
  USER_REQUESTED = "USER_REQUESTED",
  LENDER_APPROVED = "LENDER_APPROVED",
  LENDER_REJECTED = "LENDER_REJECTED",
  AGREEMENT_SIGNED = "AGREEMENT_SIGNED",
  TRANSFER_INITIATED = "TRANSFER_INITIATED",
  TRANSFERRED = "TRANSFERRED",
  TRANSFER_FAILED = "TRANSFER_FAILED",
  REPAID = "REPAID",
  DEDUCTED_FROM_SALARY = "DEDUCTED_FROM_SALARY",
  CANCELLED = "CANCELLED",
  EXPIRED = "EXPIRED",
}

export interface TransactionDto {
  _id: string;
  amount: number;
  employeeId: string;
  status: TransactionStatusDto;
  createdAt: string;
  employeeDataSnapshot: {
    employerEmployeeId: string;
    fullName: string;
  };
  contractorDataSnapshot: {
    employersContractorId: string;
    fullName: string;
  };
  userDataSnapshot?: {
    name: {
      data: string;
    };
  };
  repaidAmount?: number;
  pendingRepaymentCommitmentsAmount?: number;
  pendingRepaymentAmount?: number;
  latestRepaymentDate?: string;
}
export interface AdvanceSalaryDto {
  _id: string;
  employeeId: string;
  name: string;
  approvalDate?: string;
  disbursalDate?: string;
  status: string;
  userId: string;
  currentApplicationTerm?: {
    amount: number;
    tenureInMonths: number;
    irpa: number;
    pf: number;
    pfPostGst: number;
  };
  emiStartDate?: string;
  emiAmount?: number;
}
export enum AdvanceSalaryApplicationStatus {
  INITIATED = "INITIATED",
  USER_DOC_UPLOAD_PENDING = "USER_DOC_UPLOAD_PENDING",
  VERIFICATION_PENDING = "VERIFICATION_PENDING",
  AGREEMENT_PENDING = "AGREEMENT_PENDING",
  ENACH_PENDING = "ENACH_PENDING",
  APPLICATION_APPROVED = "APPLICATION_APPROVED",
  REJECTED = "REJECTED",
  DISBURSAL_INITIATED = "DISBURSAL_INITIATED",
  DISBURSAL_FAILED = "DISBURSAL_FAILED",
  DISBURSED = "DISBURSED",
  REPAID = "REPAID",
}
export enum VerificationDetailsKeys {
  address = "address",
  bank_details = "bank_details",
  pan = "pan",
  selfie_doc = "selfie_doc",
  pan_doc = "pan_doc",
  address_proof_doc = "address_proof_doc",
}

export interface VerificationDetailsDto {
  [VerificationDetailsKeys.address]: {
    status: string;
  };
  [VerificationDetailsKeys.bank_details]: {
    status: string;
  };
  [VerificationDetailsKeys.pan]: {
    status: string;
  };
  [VerificationDetailsKeys.selfie_doc]: {
    status: string;
  };
  [VerificationDetailsKeys.pan_doc]: {
    status: string;
  };
  [VerificationDetailsKeys.address_proof_doc]: {
    status: string;
  };
}

export type DocUploadProcessingStatus = "PROCESSING" | "PROCESSED" | "FAILED";
export interface BulkEmployeeDocUploadHistoryDto {
  originalDocumentId: string;
  originalDocumentURL: string;
  employerId: string;
  employerAdminId: string;
  processedDocumentId?: string;
  processedDocumentURL?: string;
  status: DocUploadProcessingStatus;
  uploadedDate: string;
  uploadedBy: string;
}
export interface EmployerUploadedFileInfoDto {
  fileName: string;
  originalFileName?: string;
  employerAdminId?: string;
  employerAdminEmail?: string;
  updatedAt: string;
}
export interface DocumentsDto {
  selfie_url: string | null;
  payslip_url: string | null;
  id_url: string | null;
  pan_url: string | null;
  emp_id_url: string | null;
  voter_id_front_url: string | null;
  voter_id_back_url: string | null;
  aadhaar_front_url: string | null;
  aadhaar_back_url: string | null;
  hr_letter_url: string | null;
  rental_agreement_url: string | null;
}
export interface AddressDto {
  pincode: string;
  house_info: string;
  locality_info: string;
  city: string;
  state: string;
}
export interface ReviewMetaData {
  status: string;
  statusLogs: any;
  statusReason: any;
}

export interface UserPropertyString {
  data: string;
  reviewMetadata: ReviewMetaData | null;
}
export interface UserPropertyNumber {
  data: number;
  reviewMetadata: ReviewMetaData | null;
}
export interface UserPropertyPhoneNumber {
  data: {
    countryCode: string;
    number: string;
  };
  reviewMetadata: ReviewMetaData | null;
}
export interface UserPropertyPanNumber {
  data: {
    pan: string;
  };
  documents: {
    front: {
      url: string | null;
    };
  };
  reviewMetadata: ReviewMetaData | null;
}
export interface DocumentProperties {
  document: {
    url: string | null;
  };
  reviewMetadata: ReviewMetaData | null;
}
export interface BankAccount {
  data: {
    accountNumber: string;
    ifsc: string;
    primary: boolean;
  };
  reviewMetadata: ReviewMetaData | null;
}
export interface TwoSideDocument {
  front: {
    url: string;
  };
  back: {
    url: string;
  };
}
export interface DLProperty {
  data: {
    drivingLicenseNumber: string;
  };
  reviewMetadata: ReviewMetaData | null;
  documents: TwoSideDocument;
}
export interface VoterIDProperty {
  data: {
    voterIdNumber: string;
  };
  reviewMetadata: ReviewMetaData | null;
  documents: TwoSideDocument;
}
export interface AddressData {
  houseInfo: string;
  localityInfo: string;
  pinCode: string;
  city: string;
  state: string;
}
export interface AddressProperty {
  data: AddressData;
  reviewMetadata: ReviewMetaData | null;
}
export interface ProfileDetailsDto {
  preferredLocale: string;
  createdAt: number;
  email: string;
  phone: UserPropertyPhoneNumber;
  name: string;
  gender: string;
  pan: UserPropertyPanNumber;
  dob: UserPropertyNumber;
  employerId: string;
  parentOrSpouseName: UserPropertyString;
  selfie: DocumentProperties;
  bankAccountInfo: BankAccount[] | null;
  status: string;
  hrLetter: DocumentProperties;
  rentalAgreement: DocumentProperties;
  drivingLicense: DLProperty;
  voterId: VoterIDProperty;
  address: AddressProperty;
}

export interface EmployeeProfileDetailsDto {
  preferredLocale: string;
  createdAt: number;
  email: UserPropertyString;
  phone: UserPropertyPhoneNumber;
  name: UserPropertyString;
  gender: string;
  pan: UserPropertyPanNumber;
  dob: UserPropertyNumber;
  employerId: string;
  parentOrSpouseName: UserPropertyString;
  selfie: DocumentProperties;
  bankAccountInfo: BankAccount[] | null;
  status: string;
  hrLetter: DocumentProperties;
  rentalAgreement: DocumentProperties;
  drivingLicense: DLProperty;
  voterId: VoterIDProperty;
  address: AddressProperty;
}

export interface EmployerDetailsDto {
  name: string;
  logoURL: string;
}

export interface EmployerMetadataDto {
  gradeIds: string[];
  branchIds: string[];
  departmentIds: string[];
}

export interface StatsDto {
  totalEmployees: number;
  salaryWithdrawalCompleted: number;
  salaryWithdrawalPending: number;
  salaryWithdrawalRejected: number;
  salaryWithdrawalRequests: number;
  transactions: number;
  withdrawalValue: number;
}

export interface StuStatsDto {
  totalEmployees: number;
  salaryTopupApplications: number;
  salaryTopupDisbursedValue: number;
}

export interface AdminTodo {
  title: string;
}

export interface AdminActivity {
  activities: {
    _id: string;
    employerAdminId: string;
    employerId: string;
    actionType: string;
    metadata: any;
    createdAt: string;
    updatedAt: string;
  }[];
  employerAdminsDetails: {
    _id: string;
    employerId: string;
    email: string;
    name: string;
  }[];
}

interface CycleTransactionDetails {
  startDate: string;
  endDate: string;
  numberOfTransactions: number;
  valueOfTransactions: number;
}

export interface AttendanceCycleDto {
  attendanceCycleDates: CycleInfoDto;
  totalNumberOfTransactions: number;
  totalTransactionalValue: number;
  completeCycleTransactionDetails: Array<CycleTransactionDetails>;
}
export interface StuStatsByAttendanceCycleDto {
  attendanceCycleDates: CycleInfoDto;
  totalSalaryTopupApplications: number;
  totalSalaryTopupDisbursedValue: number;
  completeCycleSalaryTopupApplicationDetails: Array<CycleTransactionDetails>;
}
export interface EmployerAdminDto {
  branchLocation: string;
  departmentId?: string;
  email: string;
  employerId: string;
  lastActiveAt: string;
  mobile: string;
  name: string;
  roles: string[];
  roleIds: string[];
  _id: string;
  resources?: {
    [name: string]: string[];
  };
}

// Vendor DTO

export interface VendorSummary {
  employeesCount: number;
  vendor: {
    _id: string;
    name: string;
    employerId: string;
    isTransactionAllowed: boolean;
    vendorInfo: {
      logoUrl: string;
      contractEndDate: Date;
      contractStartDate: Date;
      mobileNumber: string;
      email: string;
    };
  };
}

export interface EmployerConfigDto {
  isEmployeeApprovalEnabled: boolean;
  isTransactionApprovalEnabled: boolean;
  isSalaryTopupViewEnabled: boolean;
}

export interface WorkerPendingApprovalDto {
  fullName: string;
  employersWorkerId: string;
  employeeId: string;
  currentMonthlySalary: number;
  dateRequested: string;
  joinedAt: string;
}

export interface TransactionPendingApprovalDto {
  transactionId: string;
  amount: number;
  employersWorkerId: string;
  fullName: string;
  dateRequested: string;
}

export interface AgreementType {
  agreementId: string;
  agreementType: string;
  signedAt: string;
}

export interface UserAgreementMetaDataDto {
  agreements: AgreementType[];
}
