import {Actions} from "../actions/ui";
import {NotifierType} from "../models";

export interface UIState {
    screenName: string;
    notifierDetails: {
        autoClose?: boolean;
        bgColor: string;
        title?: string;
        msg: string;
        type: NotifierType | "";
        actionButtonLabel?: string;
        onActionButtonClick?: () => void;
    }
}

const defaultState: UIState = {
    screenName: "",
    notifierDetails: {
        bgColor: "",
        msg: "",
        type: "",
    },
};

export default function UIStateReducer(state = defaultState, action: any) : UIState {
    switch (action.type) {
        case Actions.SET_SCREEN_NAME:
            return {
                ...state,
                screenName: action.payload.screenName,
            };
        case Actions.SHOW_NOTIFIER:
            return {
                ...state,
                notifierDetails: action.payload,
            };
        case Actions.HIDE_NOTIFIER:
            return {
                ...state,
                notifierDetails: {
                    bgColor: "",
                    msg: "",
                    type: ""
                },
            };
        default:
            return state
    }
}