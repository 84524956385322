import {Actions} from "../actions/payroll";
import {EmployeePayout, PayrollDetails, PayrollInfo, PayrollMonthlySummary, StreamingAccountInfo, StreamingAccountPayrollDetails} from "../models";
import { addToLoadingQueue, removeFromLoadingQueue } from "../utils";

export interface PayrollState {
    loadingQueue: string[];
    payroll: {
        hasEmployerOptedForStreamingAccount?: boolean
        payrolls: PayrollDetails[]
    }
    payrollInfo: PayrollInfo | null;
    payrollMonthlySummary: PayrollMonthlySummary | null;
    streamingAccountInfo: StreamingAccountInfo | null;
    streamingAccountPayroll: StreamingAccountPayrollDetails | null;
    streamingAccountPayrollCurrentMonth: StreamingAccountPayrollDetails | null;
    employeePayoutDetails: {
        data: EmployeePayout[],
        totalCount: number,
    };
}

const defaultState: PayrollState = {
    loadingQueue: [],
    payroll: {
        payrolls: []
    },
    payrollInfo: null,
    payrollMonthlySummary: null,
    streamingAccountInfo: null,
    streamingAccountPayroll: null,
    streamingAccountPayrollCurrentMonth: null,
    employeePayoutDetails: {
        data: [],
        totalCount: 0,
    },
};

export default function PayrollReducer(state = defaultState, action: any) : PayrollState {
    switch (action.type) {
        case Actions.REQUEST_UPLOAD_PAYROLL:
            return {
                ...state,
                loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
            };
        case `${Actions.REQUEST_UPLOAD_PAYROLL}_SUCCESS`:
            return {
                ...state,
                loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
            };
        case `${Actions.REQUEST_UPLOAD_PAYROLL}_FAILURE`:
            return {
                ...state,
                loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
            };
        case Actions.REQUEST_PAYROLL_LIST:
            return {
                ...state,
                loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
            };
        case `${Actions.REQUEST_PAYROLL_LIST}_FAILURE`:
            return {
                ...state,
                loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
                payroll: {
                    payrolls: []
                }
            };
        case `${Actions.REQUEST_PAYROLL_LIST}_SUCCESS`:
            return {
                ...state,
                loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
                payroll: action.payload,
            };
        case Actions.REQUEST_DOWNLOAD_PAYROLL:
            return {
                ...state,
                loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
            };
        case `${Actions.REQUEST_DOWNLOAD_PAYROLL}_FAILURE`:
            return {
                ...state,
                loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
            };
        case `${Actions.REQUEST_DOWNLOAD_PAYROLL}_SUCCESS`:
            return {
                ...state,
                loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
            };
        case Actions.REQUEST_FREEZE_PAYROLL:
            return {
                ...state,
                loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
            };
        case `${Actions.REQUEST_FREEZE_PAYROLL}_FAILURE`:
            return {
                ...state,
                loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
            };
        case `${Actions.REQUEST_FREEZE_PAYROLL}_SUCCESS`:
            return {
                ...state,
                loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
            };
        case Actions.REQUEST_REINITIATE_PAYROLL_FAILED_TRANSACTIONS:
            return {
                ...state,
                loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
            };
        case `${Actions.REQUEST_REINITIATE_PAYROLL_FAILED_TRANSACTIONS}_FAILURE`:
            return {
                ...state,
                loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
            };
        case `${Actions.REQUEST_REINITIATE_PAYROLL_FAILED_TRANSACTIONS}_SUCCESS`:
            return {
                ...state,
                loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
            };
        case Actions.REQUEST_DOWNLOAD_PROCESSED_PAYROLL:
            return {
                ...state,
                loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
            };
        case `${Actions.REQUEST_DOWNLOAD_PROCESSED_PAYROLL}_FAILURE`:
            return {
                ...state,
                loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
            };
        case `${Actions.REQUEST_DOWNLOAD_PROCESSED_PAYROLL}_SUCCESS`:
            return {
                ...state,
                loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
            };
        case Actions.REQUEST_PAYROLL_INFO:
            return {
                ...state,
                loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
            };
        case `${Actions.REQUEST_PAYROLL_INFO}_FAILURE`:
            return {
                ...state,
                loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
                payrollInfo: null,
            };
        case `${Actions.REQUEST_PAYROLL_INFO}_SUCCESS`:
            return {
                ...state,
                loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
                payrollInfo: action.payload,
            };
        case Actions.REQUEST_STREAMING_ACCOUNT_INFO:
            return {
                ...state,
                loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
            };
        case `${Actions.REQUEST_STREAMING_ACCOUNT_INFO}_FAILURE`:
            return {
                ...state,
                loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
                streamingAccountInfo: null,
            };
        case `${Actions.REQUEST_STREAMING_ACCOUNT_INFO}_SUCCESS`:
            return {
                ...state,
                loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
                streamingAccountInfo: action.payload,
            };
        case Actions.REQUEST_STREAMING_PAYROLL_DETAILS:
            return {
                ...state,
                loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
            };
        case `${Actions.REQUEST_STREAMING_PAYROLL_DETAILS}_FAILURE`:
            return {
                ...state,
                loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
                streamingAccountPayroll: null,
            };
        case `${Actions.REQUEST_STREAMING_PAYROLL_DETAILS}_SUCCESS`:
            return {
                ...state,
                loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
                streamingAccountPayroll: action.payload,
            };
        case Actions.REQUEST_STREAMING_PAYROLL_DETAILS_CURRENT_MONTH:
            return {
                ...state,
                loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
            };
        case `${Actions.REQUEST_STREAMING_PAYROLL_DETAILS_CURRENT_MONTH}_FAILURE`:
            return {
                ...state,
                loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
                streamingAccountPayrollCurrentMonth: null,
            };
        case `${Actions.REQUEST_STREAMING_PAYROLL_DETAILS_CURRENT_MONTH}_SUCCESS`:
            return {
                ...state,
                loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
                streamingAccountPayrollCurrentMonth: action.payload,
            };
        case Actions.REQUEST_EMPLOYEE_PAYOUT_DETAILS:
            return {
                ...state,
                loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
            };
        case `${Actions.REQUEST_EMPLOYEE_PAYOUT_DETAILS}_FAILURE`:
            return {
                ...state,
                loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
                employeePayoutDetails: {
                    data: [],
                    totalCount: 0,
                },
            };
        case `${Actions.REQUEST_EMPLOYEE_PAYOUT_DETAILS}_SUCCESS`:
            return {
                ...state,
                loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
                employeePayoutDetails: action.payload,
            };
        case Actions.REQUEST_UPDATE_PAYROLL_STATUS:
            return {
                ...state,
                loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
            };
        case `${Actions.REQUEST_UPDATE_PAYROLL_STATUS}_FAILURE`:
            return {
                ...state,
                loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
            };
        case `${Actions.REQUEST_UPDATE_PAYROLL_STATUS}_SUCCESS`:
            return {
                ...state,
                loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
                streamingAccountPayrollCurrentMonth: action.payload
            };
        case Actions.REQUEST_EXECUTE_PAYROLL:
            return {
                ...state,
                loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
            };
        case `${Actions.REQUEST_EXECUTE_PAYROLL}_FAILURE`:
            return {
                ...state,
                loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
            };
        case `${Actions.REQUEST_EXECUTE_PAYROLL}_SUCCESS`:
            return {
                ...state,
                loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
            };
        case Actions.REQUEST_PAYROLL_MONTHLY_SUMMARY:
            return {
                ...state,
                loadingQueue: addToLoadingQueue(state.loadingQueue, action.type),
            };
        case `${Actions.REQUEST_PAYROLL_MONTHLY_SUMMARY}_FAILURE`:
            return {
                ...state,
                loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
            };
        case `${Actions.REQUEST_PAYROLL_MONTHLY_SUMMARY}_SUCCESS`:
            return {
                ...state,
                loadingQueue: removeFromLoadingQueue(state.loadingQueue, action.type),
                payrollMonthlySummary: action.payload
            };
        default:
            return state
    }
}