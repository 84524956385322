import React, { useEffect, useMemo, useState } from "react";
import { Button } from "../button";
import { CrossIcon, PlusIcon } from "../icon";
import { IconButton } from "../icon-button";
import { NativeSelect, SelectItem } from "../native-select";
import "./styles.css";

const getRandomId = () => `${Math.random().toString(36).slice(2, 11)}`;

type FilterJoinOperator = "AND" | "OR";
type FilterOperator = "==" | "!=";

const JOIN_OPERATOR_SELECT_OPTIONS: Array<SelectItem> = [
  {
    value: "AND",
  },
  {
    value: "OR",
  },
];
const OPERATOR_SELECT_OPTIONS: Array<SelectItem> = [
  {
    label: "Is",
    value: "==",
  },
  {
    label: "Is not",
    value: "!=",
  },
];

export interface FilterMetadata {
  entity: {
    name: string;
    label?: string;
  };
  values: Array<{
    name: string;
    label?: string;
  }>;
}

function getLeftOperandFilterOptionsFromMetadata(
  metadata: FilterMetadata[]
): SelectItem[] {
  return metadata.map((eachVal) => ({
    label: eachVal.entity.label ?? eachVal.entity.name,
    value: eachVal.entity.name,
  }));
}
function getLeftOperandToRightOperandFilterOptionsMapFromMetadata(
  metadata: FilterMetadata[]
): Record<string, SelectItem[]> {
  return metadata.reduce((optionsMap, currentVal) => {
    const keyName = currentVal.entity.name;
    const values = currentVal.values;
    optionsMap[keyName] = values.map((val) => ({ ...val, value: val.name }));
    return optionsMap;
  }, {} as Record<string, SelectItem[]>);
}

function getInitialItem(): Array<FilterState & { id: string }> {
  return [{ id: getRandomId(), joinOperator: "OR" }];
}

function formatInitialState(
  state: FilterState[]
): Array<FilterState & { id: string }> {
  if (state.length === 0) return getInitialItem();
  return state.map((val) => ({ ...val, id: getRandomId() }));
}

export interface FilterState {
  joinOperator?: FilterJoinOperator;
  leftOperand?: string;
  operator?: FilterOperator;
  rightOperand?: string;
}

export interface FilterProps {
  metadata: Array<FilterMetadata>;
  label?: string;
  currentState?: Array<FilterState>;
  onChange?: (filterState: FilterState[]) => void;
  onClose?: () => void;
}

// TODO: Migrate to Custom Select from Native Select
export default function Filter({
  metadata,
  label = "Filters",
  currentState = [],
  onClose,
  onChange,
}: FilterProps) {
  const [filterState, setFilterState] = useState<
    Array<FilterState & { id: string }>
  >(() => formatInitialState(currentState));

  const LEFT_OPERAND_OPTIONS = useMemo(
    () => getLeftOperandFilterOptionsFromMetadata(metadata),
    [metadata]
  );
  const LEFT_OPERAND_TO_RIGHT_OPERAND_OPTIONS = useMemo(
    () => getLeftOperandToRightOperandFilterOptionsMapFromMetadata(metadata),
    [metadata]
  );

  function handleChange(index: number) {
    return (e: React.ChangeEvent<HTMLSelectElement>) => {
      const { name, value } = e.currentTarget;
      setFilterState((current) => {
        const clone = [...current];
        clone[index] = { ...current[index], [name]: value };
        if (name === "leftOperand") {
          if (!clone[index]["operator"]) clone[index]["operator"] = "==";
          clone[index]["rightOperand"] =
            LEFT_OPERAND_TO_RIGHT_OPERAND_OPTIONS[
              clone[index].leftOperand ?? ""
            ][0].value;
        }
        return clone;
      });
    };
  }
  function handleClear(index: number) {
    return () => {
      setFilterState((current) => {
        const clone = [...current];
        clone.splice(index, 1);
        return clone;
      });
    };
  }
  function handleAdd() {
    setFilterState((current) => {
      const clone = [...current];
      clone.push({ id: getRandomId(), joinOperator: "AND" });
      return clone;
    });
  }
  function handleClearAll() {
    setFilterState(getInitialItem());
  }
  function handleSubmit() {
    const modified = filterState.map(({ id, ...rest }) => rest);
    onChange?.(modified);
    onClose?.();
  }

  return (
    <div className="rf-filter__wrapper">
      <h4 className="rf-filter__title">{label}</h4>
      {filterState.map((eachFilterRow, index) => (
        <div className="rf-filter__row-container" key={eachFilterRow.id}>
          {index === 0 ? (
            <span>Where</span>
          ) : (
            <NativeSelect
              name="joinOperator"
              options={JOIN_OPERATOR_SELECT_OPTIONS}
              value={eachFilterRow.joinOperator}
              onChange={handleChange(index)}
            />
          )}
          <NativeSelect
            placeholder="Select filter"
            name="leftOperand"
            options={LEFT_OPERAND_OPTIONS}
            value={eachFilterRow.leftOperand}
            onChange={handleChange(index)}
          />
          {!!eachFilterRow.leftOperand && (
            <>
              <NativeSelect
                name="operator"
                options={OPERATOR_SELECT_OPTIONS}
                value={eachFilterRow.operator}
                onChange={handleChange(index)}
              />
              <NativeSelect
                name="rightOperand"
                options={
                  LEFT_OPERAND_TO_RIGHT_OPERAND_OPTIONS[
                    eachFilterRow.leftOperand
                  ]
                }
                value={eachFilterRow.rightOperand}
                onChange={handleChange(index)}
              />
            </>
          )}
          <IconButton
            title="Remove"
            spacing="no-padding"
            onClick={handleClear(index)}
            className="rf-filter__row-item__close"
          >
            <CrossIcon size="md" />
          </IconButton>
        </div>
      ))}

      <div>
        <Button
          variant="text"
          size="sm"
          spacing="compact"
          style={{ minWidth: "unset" }}
          onClick={handleAdd}
        >
          <PlusIcon size="sm" />
          &nbsp;Add filter
        </Button>
        {filterState.length > 1 && (
          <Button
            variant="text"
            size="sm"
            spacing="compact"
            style={{ minWidth: "unset" }}
            onClick={handleClearAll}
          >
            <CrossIcon size="sm" />
            &nbsp;Clear all
          </Button>
        )}
      </div>
      <div style={{ textAlign: "right" }}>
        <Button variant="text" color="danger" size="sm" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="primary"
          color="brand"
          size="sm"
          onClick={handleSubmit}
        >
          Apply
        </Button>
      </div>
    </div>
  );
}
