import React from "react";
import DataTable, { IDataTableColumn } from "react-data-table-component";
import Badge, { BadgeType } from "../../components/badge";
import Button from "../../components/button";
import { formatDate } from "../../utils";

const STATUS_TO_BADGE_TYPE_MAP: Record<string, BadgeType> = {
  PROCESSING: BadgeType.PENDING,
  PROCESSED: BadgeType.SUCCESS,
  FAILED: BadgeType.REJECT,
};
export interface DocumentMetadata {
  id?: string;
  uri?: string;
  canDownload: boolean;
}

function renderActionButton(context: {
  docMetadata: DocumentMetadata;
  onClick: (docMetadata: DocumentMetadata) => void;
}) {
  const { docMetadata, onClick } = context;
  if (!docMetadata.canDownload) return null;
  return (
    <button
      type="button"
      className="btn btn-link p-0"
      onClick={() => onClick(docMetadata)}
    >
      <i className="fa fa-cloud-download" />
    </button>
  );
}

function getStatusBadge(status?: string) {
  if (!status) return null;
  return (
    <div>
      <Badge type={STATUS_TO_BADGE_TYPE_MAP[status] || BadgeType.INFO}>
        {status}
      </Badge>
    </div>
  );
}

export interface DocUploadHistoryRecord {
  originalDocument?: DocumentMetadata;
  processedDocument?: DocumentMetadata;
  fileName?: string;
  employerAdminId?: string;
  uploadedAt: string;
  uploadedBy: string;
  status?: string;
}

interface DownloadColumn {
  title?: string;
  show: boolean;
}

interface DocUploadHistoryTableProps {
  className?: string;
  showFileName?: boolean;
  title: string;
  records: DocUploadHistoryRecord[];
  originalDocDownloadCol: DownloadColumn;
  processedDocDownloadCol: DownloadColumn;
  onDownloadClick?: (
    docMetadata: DocumentMetadata,
    employerAdminId: string
  ) => void;
  onRefreshClick?: () => void;
}

export default function DocUploadHistoryTable({
  className,
  showFileName = false,
  title,
  records,
  originalDocDownloadCol,
  processedDocDownloadCol,
  onDownloadClick,
  onRefreshClick,
}: DocUploadHistoryTableProps) {
  const columns: IDataTableColumn<DocUploadHistoryRecord>[] = [
    {
      minWidth: "20%",
      name: "Upload date",
      selector: "uploadedAt",
      sortable: true,
      cell: (row) => (
        <span className="primary-color fs-12">
          {formatDate(new Date(row.uploadedAt))}
        </span>
      ),
    },
    ...(showFileName
      ? [
          {
            minWidth: "25%",
            name: "File Name",
            selector: "fileName",
            cell: (row: any) => (
              <span className="primary-color fs-12">{row.fileName}</span>
            ),
          },
        ]
      : []),
    {
      minWidth: "30%",
      name: "Uploaded by",
      selector: "uploadedBy",
      cell: (row) => (
        <span className="primary-color fs-12">{row.uploadedBy}</span>
      ),
    },
    {
      minWidth: "20%",
      name: "Status",
      selector: "status",
      center: true,
      cell: (row) => getStatusBadge(row.status),
    },
    {
      minWidth: "15%",
      omit: !originalDocDownloadCol.show,
      name: originalDocDownloadCol.title || "Uploaded Doc Download",
      cell: ({ originalDocument, employerAdminId }) =>
        originalDocument &&
        employerAdminId &&
        renderActionButton({
          docMetadata: originalDocument,
          onClick: (metadata) => onDownloadClick?.(metadata, employerAdminId),
        }),
      ignoreOnRowClick: true,
      button: true,
    },
    {
      minWidth: "15%",
      omit: !processedDocDownloadCol.show,
      name: processedDocDownloadCol.title || "Processed Doc Download",
      cell: ({ processedDocument, employerAdminId }) =>
        processedDocument &&
        employerAdminId &&
        renderActionButton({
          docMetadata: processedDocument,
          onClick: (metadata) => onDownloadClick?.(metadata, employerAdminId),
        }),
      ignoreOnRowClick: true,
      button: true,
    },
  ];

  return (
    <DataTable
      className={"table-wrapper " + (className || "")}
      columns={columns}
      data={records}
      pagination
      paginationPerPage={5}
      subHeaderAlign="left"
      title={
        <div className="d-flex align-items-center justify-content-between mb-4">
          <p className="table-title fw-600 fs-18 m-0">{title}</p>
          {!!onRefreshClick && (
            <Button
              cls="btn primary-button m-0 fs-12 w-unset h-unset px-3 cursor-pointer"
              label="Refresh"
              handleBtnClick={onRefreshClick}
              mergeClass={false}
            />
          )}
        </div>
      }
    />
  );
}
