import dispatch from "../../middleware";
import {setScreenName} from "../../actions/ui";
import {Dispatch} from "redux";

export default function getEffect(screenName: string, storeDispatch: Dispatch){
    return () => {
        dispatch(storeDispatch, setScreenName(screenName));
        return () => {
            dispatch(storeDispatch, setScreenName(""));
        }
    };
}