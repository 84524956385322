import React, { useEffect, useState } from "react";
import {getParamFromUrl, isEmail} from "../../utils";
import { sendResetPwEmail } from "../../actions/login";
import { useDispatch, useSelector } from "react-redux";
import { ReduxState } from "../../reducers";
import { Link } from "react-router-dom";
import Logo from "../../images/logo-dark.svg";
import ResendEmailVector from "../../images/resendEmail.svg";
import LeftArrow from "../../images/left-arrow.svg";

async function handleResendEmail(email: string, storeDispatch: any) {
  if (isEmail(email)) {
    await sendResetPwEmail(email, storeDispatch);
  }
}

export default function ResendEmail(props: any) {
  const [email, updateEmail] = useState("");
  const loading: boolean = useSelector(
    (state: ReduxState) => state.login.loading
  );
  const storeDispatch = useDispatch();

  useEffect(() => {
    updateEmail(
        getParamFromUrl('email') || ""
    );
  }, []);

  return (
    <div className="auth-page-wrapper">
      <div className="auth-card">
        <div className="illustartion-box">
          <img src={ResendEmailVector} alt="Logo" className="auth-logo" />
        </div>
        <div className="auth-main-content">
          <div className="auth-form-wrapper text-center">
          <div className="text-center mb-5">
                <img src={Logo} alt="Logo" className="auth-logo" />
              </div>
            <p className="subheading mb-3">Confirm your email</p>
            <p className="primary-color fs-14">
              To continue, click on the confirmation link sent to your email
            </p>
            <br/>
            <p className="subheading mb-5 fs-18">{email}</p>
            <p
              className="cursor-pointer secondary-color"
              onClick={() => handleResendEmail(email, storeDispatch)}
            >
              {loading ? "Please wait..." : "Resend email"}
            </p>
          </div>
          <Link to={"/?signIn=true"}>
            <p className="primary-color fs-14">
              <img src={LeftArrow} alt="Arrow" className="mr-2" width="15px" />{" "}
              Back to <span className="secondary-color cursor-pointer">Sign In</span>
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
}
