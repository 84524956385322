import React from "react";

interface UnauthorizedViewProps {
  message?: string;
}

export default function UnauthorizedView({
  message = "You aren't authorized to access this page",
}: UnauthorizedViewProps) {
  return (
    <div className={"d-flex justify-content-center align-items-center"}>
      {message}
    </div>
  );
}
