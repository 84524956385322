import React from "react";
import {DropDown, Text} from "../../models"

export function TextField(props: Text) {
    return (
        <div className="form-label-group">
            <label htmlFor={props.fieldName} className="fs-10 m-0 primary-color">{props.label}</label>
            <input type={props.type} id={props.fieldName}
                   className={`pl-0 pt-0 single-line-focus border-left-0 border-right-0 border-top-0 form-control primary-color ${props.error && props.error.trim() !== "" && "error-border"}`}
                   placeholder={props.placeholder ? props.placeholder : ""} onChange={(e) => props.onChange(props.fieldName, e.target.value, props.valueType)}
                   value={props.value} disabled={props.disabled ? props.disabled : false}
            />
            {props.error && props.error.trim() !== "" && <div className={"error-msg mt-1"}>
                {props.error}
            </div>}
        </div>
    )
}

export function Select(props: DropDown) {
    return (
        <div className="form-label-group">
            {props.label && <label htmlFor={props.fieldName} className="fs-10 m-0 primary-color">{props.label}</label>}
            <select
                value={props.value !== null ? props.value : ''}
                className={`pl-0 pt-0 single-line-focus border-left-0 border-right-0 border-top-0 form-control primary-color ${props.error && props.error.trim() !== "" && "error-border"}`}
                onChange={(e) => props.onChange(props.fieldName, e.target.value)}
                disabled={props.disabled ? props.disabled : false}
            >
                {props.placeholder && <option>{props.placeholder}</option>}
                {props.options.map((option, index: number) => {
                    return (
                        <option key={index} value={option.value}>
                            {option.label}
                        </option>
                    )
                })}
            </select>
            {props.error && props.error.trim() !== "" && <div className={"error-msg mt-1"}>
                {props.error}
            </div>}
        </div>
    )
}
