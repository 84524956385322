import React from "react";
import ApexChart from "react-apexcharts";

interface DonutChartProps {
  labels: string[];
  values: (string | number)[];
  hasDataLabels?: boolean;
  hasLegend?: boolean;
  showTotal?: boolean;
  totalText?: string;
  totalFormatter?: (total: number) => any;
}

export default function DonutChart({
  labels,
  values,
  hasDataLabels = false,
  hasLegend = false,
  showTotal = true,
  totalText,
  totalFormatter,
}: DonutChartProps) {
  const options: ApexCharts.ApexOptions = {
    labels,
    dataLabels: {
      enabled: hasDataLabels,
    },
    legend: {
      show: hasLegend,
    },
    plotOptions: {
      pie: {
        donut: {
          size: "80%",
          labels: {
            show: true,
            total: {
              show: showTotal,
              label: totalText,
              fontSize: "12px",
              fontFamily: "Inter, sans-serif",
              fontWeight: 600,
              color: "#A7A7A7",
              formatter: function (w) {
                const total = w.globals.seriesTotals.reduce((a: number, b: number) => {
                  return a + b
                }, 0)
                if (totalFormatter) {
                  return totalFormatter(total);
                }
                return total;
              }
            },
          },
        },
      },
    },
  };

  return <ApexChart type="donut" options={options} series={values} />;
}
