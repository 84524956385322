import {Actions} from "../actions/leave";
import { LeaveDetails } from "../models";


export interface LeaveState {
    loading: boolean;
    leaveDetails: LeaveDetails[];
}

const defaultState: LeaveState = {
    loading: false,
    leaveDetails: [],
};

export default function leaveReducer(state = defaultState, action: any) : LeaveState {
    switch (action.type) {
        case Actions.REQUEST_LEAVE_SHEET_DOWNLOAD:
            return {
                ...state,
                loading: true
            };
        case `${Actions.REQUEST_LEAVE_SHEET_DOWNLOAD}_SUCCESS`:
            return {
                ...state,
                loading: false,
            };
        case `${Actions.REQUEST_LEAVE_SHEET_DOWNLOAD}_FAILURE`:
            return {
                ...state,
                loading: false,
            };
        case Actions.REQUEST_UPLOAD_LEAVE:
            return {
                ...state,
                loading: true
            };
        case `${Actions.REQUEST_UPLOAD_LEAVE}_SUCCESS`:
            return {
                ...state,
                loading: false,
            };
        case `${Actions.REQUEST_UPLOAD_LEAVE}_FAILURE`:
            return {
                ...state,
                loading: false,
            };
        case Actions.REQUEST_LEAVES_SHEET_LIST:
            return {
                ...state,
                loading: true,
                leaveDetails: [],
            };
        case `${Actions.REQUEST_LEAVES_SHEET_LIST}_SUCCESS`:
            return {
                ...state,
                loading: false,
                leaveDetails: action.payload.leaves_data,
            };
        case `${Actions.REQUEST_LEAVES_SHEET_LIST}_FAILURE`:
            return {
                ...state,
                loading: false,
                leaveDetails: [],
            };
        default:
            return state
    }
}
