import {MiddlewareAction} from "../middleware";

export enum UserInfoActions {
    REQUEST_GET_USER = "REQUEST_GET_USER",
    REQUEST_ADMIN_TODO = "REQUEST_ADMIN_TODO",
}
export function getUser(): MiddlewareAction {
    return {
        actionName: UserInfoActions.REQUEST_GET_USER,
        type: "GET",
        url: "/employer-admin",
    }
}

export function getAdminTodo(): MiddlewareAction {
    return {
        actionName: UserInfoActions.REQUEST_ADMIN_TODO,
        type: "GET",
        url: "/employer-admin/to-do",
    }
}