import React, {useEffect, useState} from "react";
import FullScreenLoader from "../../../components/fullscreen-loader";
import DataTable from "react-data-table-component";
import dispatch from "../../../middleware";
import {getEmployerAdmins} from "../../../actions/employee";
import {useDispatch, useSelector} from "react-redux";
import {ReduxState} from "../../../reducers";
import {EmployerAdminDto} from "../../../dto";
import Modal from "../../../components/modal";
import AddOrEditAdmin from "./addOrEditAdmin";
import {AdminRole} from "../../../models";
import {getParamFromUrl} from "../../../utils";
import {AccessType, Resource} from "../../../authorization/authorization.enum";
import Authorized from "../../../authorization/authorized";
import isAuthorized from "../../../authorization/authorizationAccess";
import {getAllRoles} from "../../../actions/login";

interface Roles {name: string; value: string; description: string; roleType: string;}

export default function RoleSettings() {
    const storeDispatch = useDispatch();
    const vendorId = getParamFromUrl("vendorId");
    const authResource = vendorId ? Resource.EMPLOYER_PORTAL_VENDORS_ADMIN : Resource.EMPLOYER_PORTAL_ADMIN;
    const loading: boolean = useSelector((state: ReduxState) => state.employee.loading)
    const employerId: string = useSelector((state: ReduxState) => state.login.userDetails?.employerId)
    const hrId: string = useSelector((state: ReduxState) => state.login.userDetails?._id)
    const admins: EmployerAdminDto[] = useSelector((state: ReduxState) =>
        vendorId ? state.employee.vendorAdmins : state.employee.employerAdmins
    )
    const allUserRoles: AdminRole = useSelector((state: ReduxState) => state.login.allUserRoles)
    const resources = useSelector(
        (state: ReduxState) => state.login.userDetails?.resources || {}
    );
    const [isModalActive, toggleModal] = useState(false);
    const [userInputToBeEdited, updateUserInputToBeEdited] = useState<EmployerAdminDto | null>(null);
    const [roles, updateRoles] = useState<Roles[]>([])
    const SettingsColumn = [
        {
            minWidth: "20%",
            left: true,
            name: "Name",
            selector: "name",
            cell: (row: any) => <span className="primary-color fs-12">{row.name}</span>,
        },
        {
            minWidth: "20%",
            center: true,
            name: "Role",
            selector: "roles",
            cell: (row: any) => <span className="primary-color fs-12">
                {getRoleAssignedRoles(roles, row.roleIds)}
            </span>
        },
        {
            minWidth: "20%",
            center: true,
            name: "Email Id",
            selector: "email",
            cell: (row: any) => <span className="primary-color fs-12">{row.email}</span>,
        },
        {
            minWidth: "20%",
            center: true,
            name: "Mobile",
            selector: "mobile",
            cell: (row: any) => <span className="primary-color fs-12">{row.mobile}</span>,
        },
        {
            minWidth: "20%",
            center: true,
            name: "Action",
            selector: "action",
            cell: (row: any) => <Authorized
                resourceName={authResource}
                requiredAccessType={AccessType.WRITE}
            >
                <span
                className={`cursor-pointer text-primary ${(row._id === hrId) && "d-none"}`}
                onClick={() => {
                            updateUserInputToBeEdited(row)
                            toggleModal(true)
                        }}
                    >
                    Manage
                </span>
            </Authorized>
        },
    ];

    function getRoleAssignedRoles(roles: Roles[], roleIds: string[]) {
        const formattedRoles = roleIds.map((eachRoleId) => {
         const index = roles.findIndex((eachRole) => eachRole.value === eachRoleId)
         if (index > -1) {
             return roles[index].name;
         } 
         return '';
        })
        return formattedRoles.join(', ')
    }

    function formattedAdminListData() {
        return admins.map(admin => {
            return {
                name: admin.name,
                email: admin.email,
                mobile: admin.mobile,
                roles: admin.roles,
                roleIds: admin.roleIds,
                branchLocation: admin.branchLocation,
                _id: admin._id,
            }
        })
    }
    async function getRoles() {
        try {
            let roles: AdminRole = allUserRoles;
            if (!roles || !Object.keys(roles).length) {
                roles = await dispatch(storeDispatch, getAllRoles())
            }
            let formattedRoles: Roles[] = []
            if (roles) {
                Object.keys(roles).forEach((key) => {
                    formattedRoles.push({
                        name: roles[key]?.role?.name || "",
                        value: roles[key]?.role?._id || "",
                        roleType: roles[key]?.role?.roleType || "",
                        description: roles[key]?.role?.description || ""
                    })
                })

            }
            updateRoles(formattedRoles);
        } catch (e) {}
    }
    function renderNotAccessible() {
        return (
            <div className={"d-flex justify-content-center align-items-center"}>
                You aren't authorized to access this page
            </div>
        )
    }
    useEffect(() => {
        getRoles()
        if (
            !admins?.length &&
            isAuthorized(resources, authResource, AccessType.READ)
        ) {
            dispatch(storeDispatch, getEmployerAdmins(vendorId))
        }
    }, [])

    return (
        <>
            <FullScreenLoader active={loading}/>
            <Authorized resourceName={authResource} requiredAccessType={AccessType.WRITE}>
                <div className="d-flex justify-content-end">
                    <div className="d-flex mt-3 mb-3 mt-md-0">
                        <button
                            className="btn primary-button fs-12 w-unset"
                            onClick={() => {
                                updateUserInputToBeEdited(null)
                                toggleModal(true)
                            }}
                        >
                            <i className="fa fa-plus mr-1 fs-10" aria-hidden="true" />
                            Add new admin
                        </button>
                    </div>
                </div>
            </Authorized>
            <Authorized
                resourceName={authResource}
                requiredAccessType={AccessType.READ}
                unAuthorizedView={renderNotAccessible()}
            >
                <div className="row">
                    <div className="col-sm-12 mt-3">
                        <DataTable
                            title={
                                <div className="d-flex  align-items-center justify-content-between">
                                    <p className="table-title fw-600 fs-18 m-0">
                                        Settings
                                    </p>
                                </div>
                            }
                            pagination={true}
                            columns={SettingsColumn}
                            data={formattedAdminListData()}
                            className="table-wrapper"
                            highlightOnHover
                        />
                    </div>
                </div>
            </Authorized>

            <Modal
                active={isModalActive}
                cancellable={true}
                onClose={() => toggleModal(false)}
            >
                {
                    isModalActive &&
                    <AddOrEditAdmin
                        vendorId={vendorId}
                        editValues={userInputToBeEdited}
                        roles={roles}
                        employerId={employerId}
                        onFormSubmit={() => {
                            toggleModal(false)
                            updateUserInputToBeEdited(null)
                        }}
                    />
                }
            </Modal>
        </>
    )
}