import {MiddlewareAction} from "../middleware";
import { DocumentType, EmployeePayoutTableTab } from "../models";

export enum Actions {
    REQUEST_UPLOAD_PAYROLL = "REQUEST_UPLOAD_PAYROLL",
    REQUEST_PAYROLL_LIST = "REQUEST_PAYROLL_LIST",
    REQUEST_DOWNLOAD_PAYROLL = "REQUEST_DOWNLOAD_PAYROLL",
    REQUEST_FREEZE_PAYROLL = "REQUEST_FREEZE_PAYROLL",
    REQUEST_DOWNLOAD_PROCESSED_PAYROLL = "REQUEST_DOWNLOAD_PROCESSED_PAYROLL",
    REQUEST_PAYROLL_INFO = "REQUEST_PAYROLL_INFO",
    REQUEST_PAYROLL_MONTHLY_SUMMARY = "REQUEST_PAYROLL_MONTHLY_SUMMARY",
    REQUEST_STREAMING_ACCOUNT_INFO = "REQUEST_STREAMING_ACCOUNT_INFO",
    REQUEST_STREAMING_PAYROLL_DETAILS = "REQUEST_STREAMING_PAYROLL_DETAILS",
    REQUEST_STREAMING_PAYROLL_DETAILS_CURRENT_MONTH = "REQUEST_STREAMING_PAYROLL_DETAILS_CURRENT_MONTH",
    REQUEST_EMPLOYEE_PAYOUT_DETAILS = "REQUEST_EMPLOYEE_PAYOUT_DETAILS",
    REQUEST_UPDATE_PAYROLL_STATUS = "REQUEST_UPDATE_PAYROLL_STATUS",
    REQUEST_EXECUTE_PAYROLL = "REQUEST_EXECUTE_PAYROLL",
    REQUEST_REINITIATE_PAYROLL_FAILED_TRANSACTIONS = "REQUEST_REINITIATE_PAYROLL_FAILED_TRANSACTIONS",
}
export function uploadPayroll(file: string): MiddlewareAction {
    return {
        actionName: Actions.REQUEST_UPLOAD_PAYROLL,
        type: "POST",
        url: "/employer-admin/payroll/upload-payroll-doc",
        body: {
            file
        }
    }
}

export function listPayrolls(): MiddlewareAction {
    return {
        actionName: Actions.REQUEST_PAYROLL_LIST,
        type: "GET",
        url: "/employer-admin/payroll"
 
    }
}

export function downloadPayroll(payrollId: string, employerAdminId: string, startDate: string, endDate: string): MiddlewareAction {
    return {
        actionName: Actions.REQUEST_DOWNLOAD_PAYROLL,
        type: "GET",
        url: `/employer-admin/doc?documentType=PAYROLL&documentId=${payrollId}&employerAdminId=${employerAdminId}`,

    }
}

export function freezePayroll(payrollId: string, startDate: string, endDate: string) {
    return {
        actionName: Actions.REQUEST_FREEZE_PAYROLL,
        type: "POST",
        url: "/hrAction",
        body: {
            type: "FREEZE_PAYROLL",
            data: {
                payrollId,
                startDate,
                endDate,
            }
        }

    }
}

export function downloadProcessedPayroll(
  payrollId: string,
  employerAdminId: string,
  documentType: DocumentType = DocumentType.PROCESSED_PAYROLL
): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_DOWNLOAD_PROCESSED_PAYROLL,
    type: "GET",
    url: "/employer-admin/doc",
    params: { documentType, documentId: payrollId, employerAdminId },
  };
}

export function getPayrollInfo(): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_PAYROLL_INFO,
    type: "GET",
    url: "/employer-admin/payroll/payroll-info",
  };
}

export function getPayrollMonthlySummary(): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_PAYROLL_MONTHLY_SUMMARY,
    type: "GET",
    url: "/employer-admin/payroll-monthly-summary",
  };
}

export function getStreamingAccountInfo(): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_STREAMING_ACCOUNT_INFO,
    type: "GET",
    url: "/employer-admin/payroll/stream-account-info",
  };
}

export function getStreamingAccountPayrollDetails(
  payload: { month?: number; year?: number; isCurrentMonth?: boolean } = {}
): MiddlewareAction {
  const { month, year, isCurrentMonth } = payload;
  const actionName = isCurrentMonth
    ? Actions.REQUEST_STREAMING_PAYROLL_DETAILS_CURRENT_MONTH
    : Actions.REQUEST_STREAMING_PAYROLL_DETAILS;
  return {
    actionName,
    type: "GET",
    url: "/employer-admin/payroll/payroll-details",
    params: { month, year },
  };
}

export function getEmployeePayoutDetails(payload?: {
  payrollId?: string;
  page?: number;
  status?: string;
  searchId?: string;
  month?: number;
  year?: number;
}): MiddlewareAction {
  const { page, status, searchId } = payload || {};
  return {
    actionName: Actions.REQUEST_EMPLOYEE_PAYOUT_DETAILS,
    type: "POST",
    url: "/employer-admin/payroll/employee-payout-details",
    body: {
      ...payload,
      page: page ? page - 1 : undefined,
      status: status === EmployeePayoutTableTab.TOTAL ? undefined : status,
      searchId: searchId === "" ? undefined : searchId,
    },
  };
}

export function updatePayrollStatus(payrollId: string, status: string): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_UPDATE_PAYROLL_STATUS,
    type: "PUT",
    url: "/employer-admin/payroll/update-payroll-status",
    body: { payrollId, status },
  }
}

export function executePayrollStatus(payrollId: string): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_EXECUTE_PAYROLL,
    type: "PUT",
    url: "/employer-admin/payroll/execute-payroll",
    params: { payrollId },
  }
}

export function reinitiatePayrollFailedTransactions(payrollId: string): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_REINITIATE_PAYROLL_FAILED_TRANSACTIONS,
    type: "PUT",
    url: "/employer-admin/payroll/reinitiate-failed-transactions",
    params: { payrollId },
  }
}
