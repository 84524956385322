import React, { useEffect, useMemo, useState } from "react";
import DataTable, { IDataTableColumn } from "react-data-table-component";
import dispatch from "../../../middleware";
import {
  employeeInfoApproval,
  getEmployeerPendingWorkerApprovals,
} from "../../../actions/employee";
import { useDispatch, useSelector } from "react-redux";
import {
  PendingApprovalEntityType,
  PendingApprovalStatus,
  WorkerPendingApproval,
} from "../../../models";
import { ReduxState } from "../../../reducers";
import FullScreenLoader from "../../../components/fullscreen-loader";
import "./styles.css";
import {
  formatDate,
  formatInr,
  getErrorMsgFromResponse,
  searchInEntriesByText,
} from "../../../utils";
import getEffect from "../effect";
import { Button } from "../../../components/library/button";
import PromptModal from "../../../components/pending-approval-prompt-modal";

const customStyles = {
  subHeader: {
    style: {
      padding: "13px 0px",
    },
  },
};


export default function UserVerification() {
  const storeDispatch = useDispatch();
  useEffect(getEffect("User Verification", storeDispatch), []);
  const workerPendingApprovals = useSelector(
    (state: ReduxState) => state.employee.workerPendingApprovals
  );

  const loading: boolean = useSelector(
    (state: ReduxState) => state.employee.loading
  );
  const [searchValue, editSearchValue] = useState<string>("");
  const [promptModal, setPromptModal] = useState<{
    context: WorkerPendingApproval;
    isEditable: boolean;
  } | null>(null);

  const formattedPendingUserApprovals = useMemo(
    () =>
      searchInEntriesByText(workerPendingApprovals, searchValue, [
        "fullName",
        "employersWorkerId",
      ]),
    [workerPendingApprovals, searchValue]
  );

  const columns: IDataTableColumn<WorkerPendingApproval>[] = [
    {
      name: "Name",
      selector: "fullName",
      sortable: true,
      minWidth: "15%",
      cell: (row) => (
        <span className="primary-color fs-12">{row.fullName}</span>
      ),
    },
    {
      minWidth: "10%",
      name: "Employee ID",
      selector: "employersWorkerId",
      cell: (row) => (
        <span className="primary-color fs-12">{row.employersWorkerId}</span>
      ),
    },
    {
      minWidth: "10%",
      name: "Monthly Salary",
      selector: "currentMonthlySalary",
      cell: (row) => (
        <span className="primary-color fs-12 ellipsis">
          {formatInr(row.currentMonthlySalary)}
        </span>
      ),
      center: true
    },
    {
      minWidth: "20%",
      name: "Time of Request",
      selector: "dateRequested",
      cell: (row) => (
        <span className="primary-color fs-12">
          {formatDate(new Date(row.dateRequested), true)}
        </span>
      ),
      sortable: true
    },
    {
      minWidth: "20%",
      name: "Date of Joining",
      selector: "joinedAt",
      cell: (row) => (
        <span className="primary-color fs-12">
          {formatDate(new Date(row.joinedAt))}
        </span>
      ),
      sortable: true
    },
    {
      name: "Actions",
      minWidth: "20%",
      cell: (row) => (
        <>
          <Button
            variant="primary"
            color="success"
            size="sm"
            style={{ minWidth: "unset", marginRight: "16px"}}
            onClick={() => {
              setPromptModal({ context: row, isEditable: false })
            }}
          >
            Accept
          </Button>
          <Button
            variant="secondary"
            color="brand"
            size="sm"
            style={{ minWidth: "unset" }}
            onClick={() => {
              setPromptModal({ context: row, isEditable: true })
            }}
          >
            Modify
          </Button>
        </>
      ),
      ignoreOnRowClick: true,
      center: true
    },
  ];
  useEffect(() => {
    dispatch(
      storeDispatch,
      getEmployeerPendingWorkerApprovals(PendingApprovalEntityType.WORKER)
    );
  }, []);

  async function updatePendingWorker(
    toStatus: PendingApprovalStatus,
    workerRequest: WorkerPendingApproval,
    modifiedFields?: Record<string, string | number>
  ): Promise<string> {
    try {
      await dispatch(
        storeDispatch,
        employeeInfoApproval({
          entityId: workerRequest.employeeId,
          entityType: PendingApprovalEntityType.WORKER,
          status: toStatus,
          modifiedFields,
        })
      );
      dispatch(
        storeDispatch,
        getEmployeerPendingWorkerApprovals(PendingApprovalEntityType.WORKER),
        false
      );
      return `${workerRequest.fullName} ${
        toStatus === PendingApprovalStatus.APPROVED ? "approved" : "rejected"
      }!`;
    } catch (e) {
      throw new Error(getErrorMsgFromResponse(e));
    }
  }
  function renderTableHeader() {
    return (
      <div className="d-flex flex-column flex-md-row align-items-center justify-content-between mb-4">
        <p className="table-title fw-600 fs-18 m-0">User Verification</p>
      </div>
    );
  }
  return (
    <div>
      <FullScreenLoader active={loading} />
        <div className="search-wrapper py-3">
          <div className="row no-gutters">
            <div className="col-md-10 col-6">
              <input
                value={searchValue}
                placeholder="Search by name or employee id"
                onChange={(e) => editSearchValue(e.target.value)}
                className="search-input"
              />
            </div>
            <div className="col-md-2 col-6 pl-3">
              <button className="btn primary-button" type="submit">
                Search
              </button>
            </div>
          </div>
        </div>
        <DataTable
          columns={columns}
          data={formattedPendingUserApprovals}
          pagination={true}
          customStyles={customStyles}
          className="table-wrapper"
          highlightOnHover
          title={renderTableHeader()}
        />
      {promptModal && (
        <PromptModal
          active={!!promptModal}
          type="approve"
          title={`Are you sure want to approve ${promptModal.context.fullName}?`}
          handleConfirm={(body) =>
            updatePendingWorker(
              PendingApprovalStatus.APPROVED,
              promptModal.context,
              body
            )
          }
          isEditable={promptModal.isEditable}
          dataFields={[
            {
              label: "Monthly Salary:",
              key: "currentMonthlySalary",
              value: promptModal.context.currentMonthlySalary,
              formatter: formatInr,
            }
          ]}
          onClose={() => {
            setPromptModal(null);
          }}
          hasExplicitRejectButton={promptModal.isEditable}
          handleReject={() =>
            updatePendingWorker(
              PendingApprovalStatus.REJECTED,
              promptModal.context
            )
          }
        />
      )}
    </div>
  );
}
