import { useHistory, useLocation } from "react-router";

export function useQueryParam(
  key: string,
  defaultValue: string,
  config?: { historyAction?: "push" | "replace" },
): [string, (newVal: string) => void] {
  const { historyAction = "push" } = config || {};
  const location = useLocation();
  const history = useHistory();

  const queryParams = new URLSearchParams(location.search);
  const value = queryParams.get(key) || defaultValue;

  function setValue(newValue: string) {
    queryParams.set(key, newValue);
    history[historyAction]({
      ...location,
      search: `?${queryParams.toString()}`,
    });
  }

  return [value, setValue];
}

interface QueryParamMap {
  [paramName: string]: string;
}
type SetQuery<T> = T | ((prevState: T) => T);
type Dispatch<T> = (value: T) => void;

function paramsToObject<QPMap extends QueryParamMap>(
  entries: IterableIterator<[keyof QPMap, string]>
) {
  const result: Partial<Record<keyof QPMap, string>> = {};
  for (const [key, value] of Array.from(entries)) {
    result[key] = value;
  }
  return result as Record<keyof QPMap, string>;
}

export function useQueryParams<QPMap extends QueryParamMap>(
  defaultState: QPMap,
  config?: { historyAction: "push" | "replace" },
): [
  Record<keyof QPMap, string>,
  Dispatch<SetQuery<Record<keyof QPMap, string>>>
] {
  const { historyAction = "push" } = config || {};
  const location = useLocation();
  const history = useHistory();

  const queryParams = new URLSearchParams(location.search);
  const paramsObj = paramsToObject<QPMap>(queryParams.entries());
  const value = { ...defaultState, ...paramsObj };

  function setValue(newValue: SetQuery<Record<keyof QPMap, string>>) {
    const newValueToBeUsed =
      newValue instanceof Function ? newValue(value) : newValue;
    Object.keys(newValueToBeUsed).forEach((key) => {
      queryParams.set(key, newValueToBeUsed[key]);
    });
    history[historyAction]({
      ...location,
      search: `?${queryParams.toString()}`,
    });
  }

  return [value, setValue];
}
