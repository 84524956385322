import React, {
  ComponentPropsWithoutRef,
  Ref,
  forwardRef,
  ReactNode,
} from "react";
import { mergeClassNames } from "../helpers/merge-classnames";
import "./styles.css";

export interface UnstyledButtonProps extends ComponentPropsWithoutRef<"button"> {
  children?: ReactNode;
}

function _UnstyledButton(
  { className, ...restProps }: UnstyledButtonProps,
  ref: Ref<HTMLButtonElement>
) {
  return (
    <button
      ref={ref}
      {...restProps}
      className={mergeClassNames("rf-unstyled-button-root", className)}
    />
  );
}

export const UnstyledButton =
  forwardRef<HTMLButtonElement, UnstyledButtonProps>(_UnstyledButton);
