type ArgsAsStringOrObject = [string | Record<string, boolean>];
type ArgsAsStringObjectTuple = [string, Record<string, boolean> | undefined];

export function constructClassName(
  ...args: ArgsAsStringOrObject | ArgsAsStringObjectTuple
): string {
  const [arg1, arg2] = args;

  if (arg2) {
    if (typeof arg1 !== "string") {
      throw new Error("First argument is not a String");
    }
    return [
      arg1,
      ...Object.keys(arg2).filter((className) => arg2[className]),
    ].join(" ");
  }
  if (typeof arg1 === "string") return arg1;

  return Object.keys(arg1)
    .filter((className) => arg1[className])
    .join(" ");
}
