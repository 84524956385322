import React from "react";
import { navigateTo } from "../../utils";
import Redirect from "./../../images/redirect.svg";

interface StatBoxProps {
    label: string;
    value: string | number | null;
    redirectRoute?: string;
}

const statBoxStyle = {
    borderRadius: 8,
}

export default function StatBox(props: StatBoxProps) {
    return (
        <div className={"d-inline-block bg-white p-3 w-100 mb-3 mb-xl-0 position-relative"} style={statBoxStyle}>
            <small className={"primary-color fw-300"}>
                {props.label}
                {
                    props.redirectRoute &&
                    <img
                        src={Redirect}
                        width="20px"
                        onClick={() => navigateTo(props.redirectRoute || "/")}
                        className={"redirect-arrow cursor-pointer"}
                    />
                }
            </small>
            <br />
            {props.value && <b className={"table-title fs-28 fw-800"}>{props.value}</b>}
        </div>
    )
}