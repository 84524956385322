import React, {
  ComponentPropsWithoutRef,
  Ref,
  forwardRef,
  ReactNode,
} from "react";
import { useId } from "../../../hooks/use-id";
import { constructClassName } from "../helpers/construct-classname";
import { useRadioGroupContext } from "./radio-group.context";
import "./styles.css";

type RadioColor = "brand";

export interface RadioProps extends ComponentPropsWithoutRef<"input"> {
  label?: ReactNode;

  value: string;

  color?: RadioColor;
}

function _Radio(
  { label, value, color = "brand", disabled = false, ...restProps }: RadioProps,
  ref: Ref<HTMLInputElement>
) {
  const _id = useId();
  const context = useRadioGroupContext();
  const contextProps: Partial<
    Pick<ComponentPropsWithoutRef<"input">, "checked" | "onChange">
  > = context
    ? { checked: context.value === value, onChange: context.onChange }
    : {};

  return (
    <div className="rf-radio__wrapper">
      <div className="rf-radio__inner">
        <input
          ref={ref}
          type="radio"
          id={_id}
          className="rf-radio__element"
          disabled={disabled}
          value={value}
          {...restProps}
          {...contextProps}
        />
      </div>
      {label && (
        <label
          htmlFor={_id}
          className={constructClassName("rf-radio__label", {
            disabled: disabled,
          })}
        >
          {label}
        </label>
      )}
    </div>
  );
}

export const Radio = forwardRef<HTMLInputElement, RadioProps>(_Radio);
