import axios from "axios";
import { getIdToken } from "../actions/login";
import { baseUrl } from "../config";
import { DEFAULT_HTTP_REQUEST_TIMEOUT } from "../constants";

export const idTokenName = "refyneHrPortalToken";
export let token: string = "";

export interface MiddlewareAction {
  actionName: string;
  contentType?: string;
  body?: object;
  type?: string;
  url?: string;
  authType?: string;
  token?: string;
  params?: { [x: string]: any };
}
export function clearToken() {
  token = "";
  window.localStorage.removeItem(idTokenName);
}
export function saveToken(idToken: string) {
  token = idToken;
  window.localStorage.setItem(idTokenName, idToken);
}
export function getToken() {
  return window.localStorage.getItem(idTokenName);
}

function getHeader(
  base64Str: string,
  contentType: string = "application/json",
  authType?: string
) {
  return {
    "Content-Type": contentType,
    Authorization: `${authType ? authType : "Bearer"} ${base64Str}`,
  };
}
export function turnOnDnd(localStorageName: string, value: string) {
  window.localStorage.setItem(localStorageName, value);
}
export function isDndOn(localStorageName: string, value: string) {
  return window.localStorage.getItem(localStorageName) === value;
}
export default async function dispatch(
  dispatch: any,
  actionDetails: MiddlewareAction,
  thowException: boolean = true
) {
  let splitActionName = actionDetails.actionName.split("_");
  dispatch({ type: actionDetails.actionName, payload: actionDetails.body });
  if (splitActionName[0] === "REQUEST") {
    try {
      const idToken = actionDetails.token
        ? actionDetails.token
        : await getIdToken();
      const axiosConfig = {
        headers: getHeader(idToken, actionDetails.contentType, actionDetails.authType),
        timeout: DEFAULT_HTTP_REQUEST_TIMEOUT,
        params: actionDetails.params,
      };
      let response: any = undefined;
      if (actionDetails.type === "GET") {
        response = await axios.get(
          `${baseUrl}${actionDetails.url}`,
          axiosConfig
        );
      } else if (actionDetails.type === "POST") {
        response = await axios.post(
          `${baseUrl}${actionDetails.url}`,
          actionDetails.body,
          axiosConfig
        );
      } else if (actionDetails.type === "PUT") {
        response = await axios.put(
          `${baseUrl}${actionDetails.url}`,
          actionDetails.body,
          axiosConfig
        );
      } else if (actionDetails.type === "PATCH") {
        response = await axios.patch(
          `${baseUrl}${actionDetails.url}`,
          actionDetails.body,
          axiosConfig
        );
      } else if (actionDetails.type === "DELETE") {
        response = await axios.delete(
          `${baseUrl}${actionDetails.url}`,
          axiosConfig
        );
      }
      if (
        response &&
        (response.status === 200 ||
          response.status === 201 ||
          response.status === 204)
      ) {
        dispatch({
          type: `${actionDetails.actionName}_SUCCESS`,
          payload: response.data,
        });
        return Promise.resolve(response.data);
      } else {
        if (thowException) return Promise.reject(response);
      }
    } catch (error) {
      dispatch({
        type: `${actionDetails.actionName}_FAILURE`,
        payload: error.data,
      });
      if (thowException) return Promise.reject(error);
    }
  }
}
export async function getImage(url: string) {
  const idToken = await getIdToken();
  const response = await axios.get(baseUrl + url, {
    headers: {
      Authorization: "Bearer " + idToken,
    },
    responseType: "blob",
  });
  return Promise.resolve(response);
}
