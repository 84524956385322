import React from "react";
import { ReactComponent as ExclamationIcon } from "../../../../images/exclamation.svg";

interface StreamingAccountBalanceCardProps {
  hasSufficientBalance?: boolean;
  balance?: number | string;
  addBalanceUrl?: string;
}

export default function StreamingAccountBalanceCard({
  hasSufficientBalance,
  balance,
  addBalanceUrl,
}: StreamingAccountBalanceCardProps) {
  return (
    <div className="payroll-card">
      {hasSufficientBalance === false ? (
        <div className="d-flex">
          <div className="rf-notifier__icon--container">
            <figure className="rf-notifier__icon--wrapper">
              <ExclamationIcon className="rf-notifier__icon" />
            </figure>
          </div>
          <div>
            <h4 className="rf-notifier__title">Insufficient Balance!</h4>
            <p className="rf-notifier__message">
              Your current balance is in sufficient to run the payroll. Please
              add the balance and execute the payroll again.
            </p>
            <a
              className="btn btn-primary"
              rel="noopener noreferer"
              href={addBalanceUrl}
              target="_blank"
              style={{ color: "#fff" }}
            >
              Add Balance
            </a>
          </div>
        </div>
      ) : (
        <p className="d-flex align-items-center justify-content-between payroll-summary-card__text my-2">
          <span>Account Balance</span>
          <span className="payroll-summary-card__amount-text">{balance}</span>
        </p>
      )}
    </div>
  );
}
