import React from "react";
import Modal from "../modal";

interface ConfirmDialogProps {
  active: boolean;
  title: string;
  message: string;
  onClose?: () => void;
  positiveActionButtonLabel?: string;
  onPositiveActionButtonClick?: () => void;
  negativeActionButtonLabel?: string;
  onNegativeActionButtonClick?: () => void;
}

export default function ConfirmDialog({
  active,
  title,
  message,
  onClose,
  positiveActionButtonLabel,
  onPositiveActionButtonClick,
  negativeActionButtonLabel,
  onNegativeActionButtonClick,
}: ConfirmDialogProps) {
  return (
    <Modal active={active} cancellable onClose={onClose}>
      <div className="p-4">
        <h4 className="rf-notifier__title">{title}</h4>
        <p className="rf-notifier__message">{message}</p>
        <div className="mt-4">
          {positiveActionButtonLabel && (
            <button
              className="btn btn-primary"
              onClick={onPositiveActionButtonClick}
            >
              {positiveActionButtonLabel}
            </button>
          )}
          {negativeActionButtonLabel && (
            <button
              className="btn rf-notifier__btn"
              onClick={onNegativeActionButtonClick}
            >
              {negativeActionButtonLabel}
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
}
